import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import SymantecEDRRoutes from './Routes';

const SymantecEDRInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = SymantecEDRRoutes.INTEGRATIONS.SymantecEDR.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default SymantecEDRInputConfiguration;
