import React from 'react';

import { Icon } from 'components/common';
import type { DataTieringConfig } from 'components/indices/data-tiering';
import { durationToRoundedDays } from 'components/indices/data-tiering';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { DATA_TIERING_LICENSE_TITLE, DATA_TIERING_LICENSE_TEXT } from 'data-tiering/constants';

type Props = {
  config: DataTieringConfig
}

const TierSummary = ({ config } : Props) => (
  <div>
    <dl>
      <dt>Warm Tier:</dt>
      <dd>{config.warm_tier_enabled ? 'Enabled' : 'Disabled'}</dd>
      {config.warm_tier_enabled && (
        <>
          <dt>Repository:</dt>
          <dd>{config.warm_tier_repository_name}</dd>
          <dt>Min. in Hot Tier:</dt>
          <dd>{durationToRoundedDays(config.index_hot_lifetime_min)} days</dd>
        </>
      )}
      <dt>Archive before deletion:</dt>
      <dd>
        <Icon name={config.archive_before_deletion ? 'check_circle' : 'cancel'} />
      </dd>
    </dl>
    {config.warm_tier_enabled && (
    <LicenseCheck text={DATA_TIERING_LICENSE_TEXT}
                  title={DATA_TIERING_LICENSE_TITLE}
                  licenseSubject={LICENSE_SUBJECTS.enterprise}
                  bsStyle="danger" />
    )}
  </div>
);

export default TierSummary;
