import React, { useCallback, useReducer } from 'react';
import styled from 'styled-components';

import { HoverForHelp } from 'components/common';
import { ButtonGroup } from 'components/bootstrap';
import SelectedFiltersOverview from 'search-filter/components/search-filter-bar/SelectedFiltersOverview';
import SearchFiltersOverview from 'search-filter/components/search-filter-bar/SearchFiltersOverview';
import SearchFilterCreate from 'search-filter/components/search-filter-bar/SearchFilterCreate';
import { SearchFilterExplanation } from 'views/components/searchbar/SearchFilterBanner';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const Controls = styled.div<{ $displaySpacer: boolean }>(({ $displaySpacer }) => `
  display: flex;
  align-items: center;
  margin-right: ${$displaySpacer ? '5x' : 0};
`);

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: 5px;
`;

const SearchFilterHelp = styled((props: any) => <HoverForHelp {...props} />)`
  margin-left: 5px;
`;

type State = {
  showCreatePopover: boolean,
  showLoadPopover: boolean,
}
type Action = {
  type: 'TOGGLE_CREATE_POPOVER' | 'TOGGLE_LOAD_POPOVER',
  newState?: boolean,
}

const isBoolean = (value: any): value is boolean => (value === true || value === false);

const showPopoverReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_CREATE_POPOVER':
      if (isBoolean(action.newState) && state.showCreatePopover === action.newState) {
        return state;
      }

      return ({ showCreatePopover: isBoolean(action.newState) ? action.newState : !state.showCreatePopover, showLoadPopover: false });
    case 'TOGGLE_LOAD_POPOVER':
      if (isBoolean(action.newState) && state.showLoadPopover === action.newState) {
        return state;
      }

      return ({ showCreatePopover: false, showLoadPopover: isBoolean(action.newState) ? action.newState : !state.showLoadPopover });
    default:
      return state;
  }
};

const SearchFilterBar = () => {
  const selectedFilters = useSearchFiltersFormState();
  const sendTelemetry = useSendTelemetry();
  const [{ showCreatePopover, showLoadPopover }, dispatchShowPopover] = useReducer(showPopoverReducer, {
    showCreatePopover: false,
    showLoadPopover: false,
  });

  const toggleShowCreatePopover = useCallback((newState: boolean) => {
    dispatchShowPopover({ type: 'TOGGLE_CREATE_POPOVER', newState });

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_CREATE_CLICKED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });
  }, [sendTelemetry]);
  const toggleShowLoadPopover = useCallback((newState: boolean) => {
    dispatchShowPopover({ type: 'TOGGLE_LOAD_POPOVER', newState });

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_LOAD_CLICKED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });
  }, [sendTelemetry]);

  return (
    <Container>
      <Controls $displaySpacer={!!selectedFilters?.size}>
        Filters
        <SearchFilterHelp title="Search Filters" trigger={['click']}>
          <SearchFilterExplanation />
        </SearchFilterHelp>
        <StyledButtonGroup>
          <SearchFilterCreate showPopover={showCreatePopover}
                              onChange={toggleShowCreatePopover} />
          <SearchFiltersOverview showPopover={showLoadPopover}
                                 onChange={toggleShowLoadPopover} />
        </StyledButtonGroup>
      </Controls>
      <SelectedFiltersOverview />
    </Container>
  );
};

export default SearchFilterBar;
