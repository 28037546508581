import * as React from 'react';

import OverlayTrigger from 'components/common/OverlayTrigger';
import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import ReportRoutes from 'report/ReportRoutes';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';

const ReportsSubNav = () => {
  const { data: { valid: isValidLicense }, isInitialLoading: isLoadingLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.report);

  return (
    <ButtonToolbar>
      {isLoadingLicense || isValidLicense ? (
        <LinkContainer to={ReportRoutes.NEW}>
          <Button bsStyle="success">Create report</Button>
        </LinkContainer>
      ) : (
        <OverlayTrigger overlay="Creating new report definitions is disabled because there is no valid license."
                        placement="top">
          <Button bsStyle="success" className="disabled">Create report</Button>
        </OverlayTrigger>
      )}
    </ButtonToolbar>
  );
};

export default ReportsSubNav;
