import React from 'react';

import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { ExternalLink } from 'components/common';
import { UnlicensedText } from 'components/loggers/ClusterSupportBundleInfo';
import { LICENSE_SUBJECTS } from 'license/constants';

const EnterpriseSupportBundleInfo = () => {
  const { data: { valid }, isInitialLoading: isLoading } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);

  if (isLoading) {
    return null;
  }

  if (!valid) {
    return <UnlicensedText />;
  }

  return (
    <>
      <strong>Please examine the bundle content before sending it to Graylog.
        It might contain sensitive data like IP addresses, hostnames or even passwords!
      </strong><br />
      After examination, you may attach it to your support case via &nbsp;
      <ExternalLink href="https://support.graylog.org">
        https://support.graylog.org
      </ExternalLink>
    </>
  );
};

export default EnterpriseSupportBundleInfo;
