import * as React from 'react';
import PropTypes from 'prop-types';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import ReportRoutes from 'report/ReportRoutes';

const ReportSubNav = ({ reportId }: { reportId: string }) => (
  <ButtonToolbar>
    <LinkContainer to={ReportRoutes.contents(reportId)}>
      <Button bsStyle="success">Modify content</Button>
    </LinkContainer>
    <LinkContainer to={ReportRoutes.configuration(reportId)}>
      <Button bsStyle="success">Edit configuration</Button>
    </LinkContainer>
    <LinkContainer to={ReportRoutes.archive(reportId)}>
      <Button bsStyle="success">History</Button>
    </LinkContainer>
  </ButtonToolbar>
);

ReportSubNav.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default ReportSubNav;
