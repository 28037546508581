import { useFormikContext } from 'formik';

import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import type { BackendReport } from 'report/types';

type ReportFormType = ReturnType<typeof useFormikContext<ReportFormValues >> & {
  submitForm: () => Promise<BackendReport | undefined>
};

const useReportForm = (): ReportFormType => useFormikContext<ReportFormValues>();

export default useReportForm;
