import styled from 'styled-components';
import * as React from 'react';

import { Row } from 'security-app/components/common/FormBaseStyles';
import type { EventDefinitionFormControlsProps } from 'components/event-definitions/event-definitions-types';
import { Button } from 'components/bootstrap';

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DefinitionFormControls = ({
  action,
  activeStepIndex,
  onCancel,
  onOpenNextPage,
  onOpenPrevPage,
  onSubmit,
  steps,
}: EventDefinitionFormControlsProps) => (
  <ButtonsRow>
    <Row $align="center" $gap="0.5rem">
      {activeStepIndex > 0 && (
      <Button onClick={onOpenPrevPage}
              disabled={activeStepIndex === 0}>
        Previous
      </Button>
      )}
      <Button onClick={onCancel}>Cancel</Button>
    </Row>
    <Row $align="center" $gap="0.5rem">
      {activeStepIndex < steps.length - 1 ? (
        <>
          <Button onClick={onSubmit}>Save changes</Button>
          <Button onClick={onOpenNextPage}>Next</Button>
        </>
      ) : (
        <Button bsStyle="primary" onClick={onSubmit}>{action === 'edit' ? 'Update' : 'Create'} event definition</Button>
      )}
    </Row>
  </ButtonsRow>
);

export default DefinitionFormControls;
