import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ModalSubmit, Spinner } from 'components/common';
import { Alert, Button, FormGroup, Modal, Input } from 'components/bootstrap';
import { adjustFormat } from 'util/DateTime';
import type { VerifiedLicense } from 'license/types';

const formatDatetime = (datetime) => adjustFormat(datetime, 'date');

type Props = {
  verifiedLicense?: VerifiedLicense,
  errorMessage?: string,
  verifyLicense: (token: string) => void,
  saveLicense: (token: string, callback: () => void) => void,
}

const StyledDl = styled.dl`
  margin-bottom: 0;
`;

const ImportLicenseModal = ({ verifiedLicense, errorMessage, verifyLicense, saveLicense } : Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [showImportLoading, setShowImportLoading] = useState<boolean>(false);

  const openModal = () => { setShowModal(true); };

  const resetModal = () => {
    setShowImportLoading(false);
    setToken(undefined);
    setShowModal(false);
  };

  const save = () => {
    if (errorMessage) return;

    setShowImportLoading(true);

    saveLicense(token, resetModal);
  };

  const updateLicenseInformation = (event: React.BaseSyntheticEvent) => {
    const newToken = event.target.value;
    setToken(newToken);

    if (newToken) {
      verifyLicense(newToken);
    }
  };

  const licenseAudience = (license: VerifiedLicense) => {
    if (license.audience.name === '' && license.audience.email === '') {
      return license.audience.company;
    }

    return (
      `${license.audience.company}:${
        license.audience.name && (` ${license.audience.name}`)
      }${
        license.audience.email && (` <${license.audience.email}>`)
      }`
    );
  };

  const disableSubmit = (token === undefined || token === '') || !verifiedLicense || !!errorMessage;

  return (
    <span>
      <Button onClick={openModal}
              bsStyle="success">
        Import new license
      </Button>
      {showModal && (
      <Modal show={showModal} onHide={resetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import new Graylog license</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <FormGroup id="licenseTextarea">
              <Input id="license-token"
                     name="description"
                     label="License"
                     type="textarea"
                     value={token}
                     onChange={updateLicenseInformation}
                     rows={5}
                     disabled={showImportLoading}
                     autoFocus
                     required />
            </FormGroup>
            {!token ? (
              <Alert bsStyle="info">
                Please add the license key for your cluster in the text field above.
              </Alert>
            ) : (
              <Alert bsStyle={errorMessage ? 'danger' : 'success'} title={`${errorMessage ? 'Invalid' : 'Valid'} license`}>
                {errorMessage && (<p><b>Reason</b>: {errorMessage}</p>)}
                {verifiedLicense && (
                  <>
                    {!disableSubmit && (<p>Graylog will reload after importing the license to make sure the changes are applied correctly.</p>)}
                    <StyledDl>
                      <dt>Licensed to</dt>
                      <dd>{licenseAudience(verifiedLicense)}</dd>
                      <dt>Valid from</dt>
                      <dd>{formatDatetime(verifiedLicense.not_before_date)}</dd>
                      <dt>Valid until</dt>
                      <dd>{formatDatetime(verifiedLicense.expiration_date)}</dd>
                    </StyledDl>
                  </>
                )}
              </Alert>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          {showImportLoading ? (<Spinner text="Importing license" />) : (
            <ModalSubmit onCancel={resetModal}
                         onSubmit={save}
                         submitButtonType="button"
                         disabledSubmit={disableSubmit}
                         submitButtonText="Import License" />
          )}
        </Modal.Footer>
      </Modal>
      )}

    </span>
  );
};

ImportLicenseModal.propTypes = {
  verifiedLicense: PropTypes.shape({
    id: PropTypes.string,
    issuer: PropTypes.string,
    subject: PropTypes.string,
    audience: PropTypes.shape({
      company: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    issue_date: PropTypes.string,
    expiration_date: PropTypes.string,
    not_before_date: PropTypes.string,
  }),
  errorMessage: PropTypes.string,
  verifyLicense: PropTypes.func.isRequired,
  saveLicense: PropTypes.func.isRequired,
};

ImportLicenseModal.defaultProps = {
  verifiedLicense: undefined,
  errorMessage: undefined,
};

export default ImportLicenseModal;
