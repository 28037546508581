import * as React from 'react';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { Icon } from 'components/common';
import { useGetAssetsByIds } from 'security-app/hooks/useAssetsAPI';

type Props = {
  identifiers: string[],
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const AssetNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AssetName = styled.div(({ theme }: { theme: DefaultTheme }) => css`
  color: ${theme.colors.global.link};
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: ${theme.colors.global.linkHover};
    text-decoration: underline;
  }
`);

const IconContainer = styled.div`
  width: 16px;
  display: inline-block;
  margin-left: 3px;
`;

const AssetInfoRowWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const AssetCustomFieldWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const AssetCustomFieldsRow = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const AssetInfoField = styled.div`
  margin-right: 1rem;
  flex: 1;
`;

const AssetCustomInfoField = styled.div`
  flex: 3;
`;

function AssetInformation({ identifiers }: Props) {
  const { assets, fetchingAssets } = useGetAssetsByIds(identifiers);
  const [selectedAsset, setSelectedAsset] = React.useState('');

  const handleAssetClick = (id: string) => {
    if (selectedAsset === id) {
      setSelectedAsset('');
    } else {
      setSelectedAsset(id);
    }
  };

  const renderField = (key, fieldValue) => {
    if (key === 'geo_info') {
      return (
        <AssetCustomFieldWrapper key={`asset-${key}`}>
          <AssetInfoField>{key}:</AssetInfoField>
          {Object.keys(fieldValue).map((valueKey) => {
            const subFieldValue = Array.isArray(fieldValue[valueKey].values) ? fieldValue[valueKey].values.join(', ') : fieldValue[valueKey];

            return (
              <AssetCustomFieldsRow key={`asset-custom-${valueKey}`}>
                {subFieldValue ? (
                  <>
                    <AssetCustomInfoField>{valueKey}:</AssetCustomInfoField>
                    <div>{subFieldValue}</div>
                  </>
                ) : null}
              </AssetCustomFieldsRow>

            );
          })}
        </AssetCustomFieldWrapper>
      );
    }

    if (key === 'custom_fields') {
      return (
        <AssetCustomFieldWrapper key={`asset-${key}`}>
          <AssetInfoField>{key}:</AssetInfoField>
          {Object.keys(fieldValue).map((valueKey) => {
            const subFieldValue = Array.isArray(fieldValue[valueKey].values) ? fieldValue[valueKey].values.join(', ') : fieldValue[valueKey].values;

            return (
              <AssetCustomFieldsRow key={`asset-custom-${valueKey}`}>
                <AssetInfoField>{valueKey}:</AssetInfoField>
                <div>{subFieldValue}</div>
              </AssetCustomFieldsRow>
            );
          })}
        </AssetCustomFieldWrapper>
      );
    }

    return (
      <AssetInfoRowWrapper key={`asset-${key}`}>
        <AssetInfoField>{key}:</AssetInfoField>
        <div>{fieldValue}</div>
      </AssetInfoRowWrapper>
    );
  };

  const renderAsset = (asset) => (
    Object.keys(asset.details).map((key) => {
      const fieldValue = Array.isArray(asset.details[key]) ? asset.details[key].join(', ') : asset.details[key];

      if (selectedAsset !== asset.id) return null;

      return (
        <div key={asset.id}>
          {fieldValue ? (
            renderField(key, fieldValue)
          ) : null}
        </div>
      );
    }));

  const renderAssets = (assetsList) => identifiers.map((assetId) => {
    if (!assetsList.find((asset) => asset.id === assetId)) {
      return (
        <div key={`${assetId}-wrapper`}>
          <AssetNameWrapper>
            <div>{`${assetId} (deleted)`}</div>
          </AssetNameWrapper>
        </div>
      );
    }

    const asset = assetsList.find((assetObj) => assetObj.id === assetId);

    return (
      <div key={`${asset.id}-wrapper`}>
        <AssetNameWrapper>
          <AssetName onClick={() => handleAssetClick(asset.id)}>{asset.name}</AssetName>
          <IconContainer>
            {selectedAsset === asset.id ? (<Icon name="arrow_drop_up" size="xs" />) : (<Icon name="arrow_drop_down" size="xs" />)}
          </IconContainer>
        </AssetNameWrapper>
        {selectedAsset === asset.id && (
        <>
          <div>{renderField('category', asset.category.join(', '))}</div>
          {renderAsset(asset)}
        </>
        )}
      </div>
    );
  });

  return (
    <div>
      {!fetchingAssets && assets !== undefined ? (
        <Row>
          {renderAssets(assets)}
        </Row>
      ) : <div>No information found in database for this asset.</div>}
    </div>
  );
}

export default AssetInformation;
