import * as React from 'react';
import styled from 'styled-components';

import MetricsSearchView from 'security-app/components/common/MetricsSearchView';
import investigationsDashboardJson from 'security-app/components/Investigations/dashboards/investigations-dashboard.json';
import type { ViewJson } from 'views/logic/views/View';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import { Spinner } from 'components/common';
import { Alert } from 'components/bootstrap';

const StyledSearchContainer = styled.div`
  footer,
  .query-tab-create,
  .query-config-btn,
  .fa-star,
  .react-resizable-handle,
  button:has(.fa-copy),
  button:has(.fa-chevron-down),
  .widget-drag-handle,
  .widget-actions-menu {
    display: none;
  }
  
  .react-grid-layout,
  .container-fluid > .row:first-of-type {
    pointer-events: none;
  }
`;

const StyledVersionAlert = styled(Alert)`
  margin-left: 10px;
`;

const DashboardContext = React.createContext(null);

export function DashboardProvider({ children }: { children: React.ReactNode }) {
  const [refreshDasboard, setRefreshDasboard] = React.useState(false);

  const forceRefreshDashboard = () => {
    setRefreshDasboard(true);
    setTimeout(() => setRefreshDasboard(false), 800);
  };

  const value = React.useMemo(() => ({
    refreshDasboard,
    forceRefreshDashboard,
  }), [refreshDasboard]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);

  return context || { forceRefreshDashboard: () => {} };
};

function Dashboard() {
  const { refreshDasboard } = useDashboardContext();

  const {
    data: usingMetricsCompatibleOpenSearch,
    isLoading: openSearchVersionLoading,
    error: openSearchVersionError,
  } = useSearchVersionCheck('opensearch', '^2.0.0');

  const {
    data: usingMetricsCompatibleDataNode,
    isLoading: dataNodeSearchVersionLoading,
    error: dataNodeSearchVersionError,
  } = useSearchVersionCheck('datanode', '^5.0.0');

  if (openSearchVersionLoading || dataNodeSearchVersionLoading || refreshDasboard) return <Spinner />;
  if (openSearchVersionError) return <div>Error: {openSearchVersionError.message}</div>;
  if (dataNodeSearchVersionError) return <div>Error: {dataNodeSearchVersionError.message}</div>;

  return (usingMetricsCompatibleOpenSearch.satisfied || usingMetricsCompatibleDataNode.satisfied)
    ? (
      <StyledSearchContainer>
        <MetricsSearchView viewJson={investigationsDashboardJson as unknown as ViewJson} />
      </StyledSearchContainer>
    ) : (
      <StyledVersionAlert bsStyle="warning" className="tm" title="Investigations metrics disabled">
        <p>Investigation metrics require OpenSearch 2.0.0 or Graylog Data Node 5.0.0 or greater. Please upgrade to collect and view the metrics.</p>
      </StyledVersionAlert>
    );
}

export default Dashboard;
