import * as React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/common/Spinner';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';

import LicenseCheckContext from './LicenseCheckContext';

const useLicenseCheckForSubjects = () => {
  const { data: isValidSecurityLicense, isInitialLoading: isLoadingSecurityLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.security);
  const { data: isValidIlluminateLicense, isInitialLoading: isLoadingIlluminateLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.illuminate);

  return {
    subjects: {
      security: { isValid: isValidSecurityLicense.valid },
      illuminate: { isValid: isValidIlluminateLicense.valid },
    },
    isLoading: isLoadingSecurityLicense || isLoadingIlluminateLicense,
  };
};

type Props = {
  children: React.ReactNode,
}

const LicenseCheckProvider = ({ children }: Props) => {
  const { subjects, isLoading } = useLicenseCheckForSubjects();

  return (
    <LicenseCheckContext.Provider value={subjects}>
      {isLoading ? <Spinner /> : children}
    </LicenseCheckContext.Provider>
  );
};

LicenseCheckProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LicenseCheckProvider;
