import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { Button, ButtonToolbar, Col, Row, Input } from 'components/bootstrap';
import type { InputProfile } from 'forwarder/Types';

import InputProfileHelpPanel from './InputProfileHelpPanel';

import { StyledTitle } from '../StyledWizardComponents';
import { ForwarderWizardContext } from '../ForwarderWizardProvider';
import { InputProfilesActions } from '../../stores/InputProfilesStore';

const StyledHelpComponent = styled.div`
  margin-top: 30px;
`;

type Props = {
  onCancel: () => void,
  onInputProfileCreated: (inputProfile: InputProfile) => void,
};

const InputProfileForm = ({ onCancel, onInputProfileCreated }: Props) => {
  const { selectedForwarder } = useContext(ForwarderWizardContext);

  if (!selectedForwarder) {
    return <div>Please select a Forwarder before continuing to this step.</div>;
  }

  const submitInputProfile = (values: Partial<InputProfile>, { setSubmitting }) => {
    InputProfilesActions.create(values as InputProfile)
      .then((inputProfile) => {
        setSubmitting(false);
        onInputProfileCreated(inputProfile);
      });
  };

  return (
    <Row>
      <Col md={6}>
        <StyledTitle>Add Input Profile to <em>{selectedForwarder.title}</em></StyledTitle>
        <Formik initialValues={{ title: '', description: '' }}
                onSubmit={submitInputProfile}>
          {({
            values,
            handleChange,
            isSubmitting,
          }) => (
            <Form>
              <Input id="title"
                     name="title"
                     label="Title"
                     type="text"
                     value={values.title}
                     onChange={handleChange}
                     help="Meaningful name used to identify this Input Profile."
                     required />
              <Input id="description"
                     name="description"
                     label={<span>Description <small>(Optional)</small></span>}
                     type="text"
                     value={values.description}
                     onChange={handleChange}
                     help="Longer description for this Input Profile." />
              <ButtonToolbar>
                <Button onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving your changes...' : 'Add Inputs'}
                </Button>
              </ButtonToolbar>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={4} mdOffset={1}>
        <StyledHelpComponent><InputProfileHelpPanel /></StyledHelpComponent>
      </Col>
    </Row>
  );
};

InputProfileForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onInputProfileCreated: PropTypes.func.isRequired,
};

InputProfileForm.defaultProps = {};

export default InputProfileForm;
