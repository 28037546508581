import AIReportConfiguration from 'security-app/components/Investigations/AIReport/AIReportConfiguration';

import investigationsSearchWidgetsBindings from './SearchWidgets/bindings';
import CoreEventWidgetActions, { EventsInvestigationsModal } from './CoreEventWidgetActions';
import InvestigationsMessageAction from './CoreMessageActions';
import InvestigationsSearchActions, { SearchInvestigationsModal, AddSearchConfirmationDialog } from './CoreSearchActions';
import InvestigationsEventAction from './CoreEventActions';
import InvestigationsDashboardAction, { InvestigationsModal } from './CoreDashboardActions';
import useActionCondition from './useActionCondition';

export default {
  'views.components.dashboardActions': [
    {
      component: InvestigationsDashboardAction,
      key: 'investigations',
      modal: InvestigationsModal,
      useCondition: useActionCondition,
    },
  ],
  'views.components.eventActions': [
    {
      component: InvestigationsEventAction,
      key: 'investigations',
      useCondition: useActionCondition,
    },
  ],
  'views.components.widgets.events.actions': [
    {
      component: CoreEventWidgetActions,
      modal: EventsInvestigationsModal,
      key: 'investigations',
      useCondition: useActionCondition,
    },
  ],
  'views.components.widgets.messageTable.messageActions': [
    {
      component: InvestigationsMessageAction,
      key: 'investigations',
      useCondition: useActionCondition,
    },
  ],
  'views.components.searchActions': [
    {
      component: InvestigationsSearchActions,
      key: 'investigations',
      modals: [
        {
          key: 'searchInvestigationsModal',
          component: SearchInvestigationsModal,
        },
        {
          key: 'addToEvidenceDialog',
          component: AddSearchConfirmationDialog,
        },
      ],
      useCondition: useActionCondition,
    },
  ],
  systemConfigurations: [{
    component: AIReportConfiguration,
    displayName: 'Investigation AI Report',
    skipClusterConfigRequest: true,
    configType: 'org.graylog.plugins.securityapp.investigations.ai.config',
  }],
  ...investigationsSearchWidgetsBindings,
};
