import { useCallback } from 'react';

import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { useInstallHubBundle } from 'illuminate/hooks/useBundlesAPI';

const useDownloadAndInstall = (selectedBundleVersion: string, onSuccess?: () => void) => {
  const sendTelemetry = useSendTelemetry();
  const { installHubBundle, installingHubBundle } = useInstallHubBundle();

  const onDownloadAndInstall = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.BUNDLE_DOWNLOADED_AND_INSTALLED, {
      app_pathname: 'illuminate',
      app_section: 'hub',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: selectedBundleVersion,
      },
    });

    installHubBundle({ bundleId: selectedBundleVersion }, {
      onSuccess: () => onSuccess?.(),
    });
  }, [installHubBundle, onSuccess, selectedBundleVersion, sendTelemetry]);

  return {
    onDownloadAndInstall,
    isInstalling: installingHubBundle,
  };
};

export default useDownloadAndInstall;
