import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon, Tooltip } from 'components/common';
import useStreamDataWarehouseStatus from 'data-warehouse/hooks/useStreamDataWarehouseStatus';

const Wrapper = styled.div(({ theme }) => css`
  color: ${theme.colors.variant.warning};
`);

type Props = {
  streamId: string,
  isArchivingEnabled: boolean,
}

const StreamIndexSetDataWarehouseWarning = ({ streamId, isArchivingEnabled }: Props) => {
  const { data, isFetching, isError } = useStreamDataWarehouseStatus(streamId);

  if (!isArchivingEnabled || isFetching || isError || !data.enabled) {
    return null;
  }

  return (
    <Tooltip withArrow position="top" label="Using Archiving and Data Warehouse together increases storage requirement.">
      <Wrapper>
        <Icon name="report" />
      </Wrapper>
    </Tooltip>
  );
};

export default StreamIndexSetDataWarehouseWarning;
