import React from 'react';

import type { License, LicenseDetails, LicenseViolations, LicenseState } from 'license/types';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import DateTimeCell from 'license/LicenseList/cells/DateTimeCell';
import DetailsCell from 'license/LicenseList/cells/DetailsCell';
import StatusCell from 'license/LicenseList/cells/StatusCell';
import ProductsCell from 'license/LicenseList/cells/ProductsCell';
import StateCell from 'license/LicenseList/cells/StateCell';

const customColumnRenderers: ColumnRenderers<License> = {
  attributes: {
    status: {
      renderCell: (status: LicenseState) => (
        <StatusCell status={status} />
      ),
    },
    products: {
      renderCell: (products: string, license: License) => (
        <ProductsCell products={products} licenseType={license.license_type} />
      ),
    },
    start_date: {
      renderCell: (start_date: string) => (
        <DateTimeCell dateTime={start_date} />
      ),
    },
    end_date: {
      renderCell: (end_date: string) => (
        <DateTimeCell dateTime={end_date} />
      ),
    },
    details: {
      renderCell: (details: LicenseDetails) => (
        <DetailsCell details={details} />
      ),
    },
    violations: {
      renderCell: (violations: LicenseViolations, license: License) => (
        <StateCell violations={violations} version={license.details?.version} />
      ),
    },
  },
};

export default customColumnRenderers;
