import trim from 'lodash/trim';

import { toBackendWidgetRef } from 'report/report-creation/content/types';
import fetchWidgetDetails from 'report/report-creation/content/fetchWidgetDetails';
import type Parameter from 'views/logic/parameters/Parameter';
import type { ParameterValues, BackendReportWidget, WidgetRef } from 'report/types';
import debounceWithPromise from 'views/logic/debounceWithPromise';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import LookupTableParameter from 'views/logic/parameters/LookupTableParameter';

const ifNotEmpty = (key: string, errors: { [key: string]: string }) => (Object.keys(errors).length > 0 ? { [key]: errors } : {});
const isEmptyString = (s: any) => !s || trim(s) === '';
const shouldNotBeEmpty = <T, >(values: T, keys: Array<keyof T>) => Object.fromEntries(keys.flatMap((key) => (isEmptyString(values?.[key]) ? [[key, 'Must be specified.']] : [])));
export const parameterCanHaveValue = (parameter: { type: string }) => parameter.type !== LookupTableParameter.type;

const validateParameters = (parameters: { [parameterName: string]: Array<Parameter>}, values: ParameterValues) => {
  const parameterWhichRequireValue = Object.values(parameters)
    .flat()
    .filter(parameterCanHaveValue)
    .map(({ name }) => name);

  return shouldNotBeEmpty(values, parameterWhichRequireValue);
};

const validateContent = (widgets: { [p: string]: BackendReportWidget }, values: Array<WidgetRef> = []) => {
  const missingWidgets = values.map((value) => {
    if (!widgets[value.widgetId]) {
      return 'Widget is no longer accessible';
    }

    return undefined;
  });

  if (!missingWidgets.filter((error) => error).length) {
    return {};
  }

  return { widgets: missingWidgets };
};

type ValidationErrors = {
  title?: string,
  layout?: {
    pageSize?: string,
    orientation?: string,
  },
  parameterValues?: { [name: string]: string },
  widgets?: Array<string>
}

const validateReportForm = async (values: ReportFormValues): Promise<ValidationErrors> => {
  const widgetRefs = values.widgets.map(toBackendWidgetRef);
  const widgetDetails = await fetchWidgetDetails(widgetRefs);

  const generalErrors = shouldNotBeEmpty(values, ['title']);
  const deliveryErrors = values.delivery.active ? shouldNotBeEmpty(values?.delivery, ['email_body', 'email_subject']) : {};
  const parameterErrors = validateParameters(widgetDetails.parameters, values.parameterValues);
  const contentErrors = validateContent(widgetDetails.widgets, values.widgets);

  return ({
    ...contentErrors,
    ...generalErrors,
    ...ifNotEmpty('delivery', deliveryErrors),
    ...ifNotEmpty('parameterValues', parameterErrors),
  });
};

const debouncedValidateReportForm = debounceWithPromise(validateReportForm, 350);

export default debouncedValidateReportForm;
