import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Stream } from 'stores/streams/StreamsStore';
import { Icon, Tooltip } from 'components/common';

import useStreamDataWarehouseStatus from '../hooks/useStreamDataWarehouseStatus';

const Wrapper = styled.div<{ $enabled: boolean }>(({ theme, $enabled }) => css`
  color: ${$enabled ? theme.colors.variant.success : theme.colors.variant.default};
  width: fit-content;
`);

type Props = {
  stream: Stream
}

const DataWareHouseCell = ({ stream }: Props) => {
  const { data: config, isFetching } = useStreamDataWarehouseStatus(stream.id);

  if (!stream.is_editable || isFetching) {
    return null;
  }

  const isDataWareHouseEnabled = config?.enabled || false;

  return (
    <Tooltip withArrow position="right" label={`Data warehouse is ${isDataWareHouseEnabled ? 'enabled' : 'disabled'}`}>
      <Wrapper $enabled={isDataWareHouseEnabled}>
        <Icon name={isDataWareHouseEnabled ? 'check_circle' : 'cancel'} />
      </Wrapper>
    </Tooltip>
  );
};

export default DataWareHouseCell;
