import React, { useContext, useEffect, useState } from 'react';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from './types';
import useFetch from './common/hooks/useFetch';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = (
  {
    onSubmit,
    onChange,
  }: StepAuthorizeProps,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { clientSecret } = formData;

  const [DefenderValidationStatus, setDefenderValidation] = useFetch(
    null,
    onSubmit,
    'POST',
    {
      defender_tenant_id: formData?.tenantId?.value || '',
      defender_client_id: formData?.clientId?.value || '',
      defender_client_secret: formData?.clientSecret?.value || '',
    },
  );

  useEffect(() => {
    setDefenderValidation(null);

    if (DefenderValidationStatus.error) {
      setFormError({
        full_message: DefenderValidationStatus.error,
        nice_message: 'Unable to connect to Microsoft Defender using provided configuration.',
      });
    }

    return () => {
      setFormError(null);
    };
  }, [DefenderValidationStatus.error, setDefenderValidation]);

  const handleSubmit = () => {
    setDefenderValidation(ApiRoutes.INTEGRATIONS.Defender.CHECK_CREDENTIALS);
  };

  const isFormValid = formValidation.isFormValid(
    ['defenderName', 'tenantId', 'clientId', 'clientSecret'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              disabled={isFormValid}
              loading={DefenderValidationStatus.loading}
              title=""
              error={formError}
              description="">
      <ValidatedInput className=""
                      id="defenderName"
                      type="text"
                      fieldData={formData.defenderName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={clientSecret?.value}
                      required />

      <ValidatedInput className=""
                      id="tenantId"
                      type="text"
                      fieldData={formData.tenantId}
                      onChange={onChange}
                      label="Directory (tenant) ID"
                      help="The GUID of the tenant to which the content belongs."
                      autoComplete="off"
                      required />

      <ValidatedInput className=""
                      id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      help="The GUID of your application that created the subscription."
                      label="Application (client) ID"
                      required />

      <ValidatedInput className=""
                      id="clientSecret"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.clientSecret}
                      label="Client Secret"
                      help="A secret string that the application uses to prove its identity when requesting a token."
                      required />
    </FormWrap>
  );
};

export default StepAuthorize;
