import type { Pack } from 'illuminate/types';

export function getRequirements({ selectedPack, packs }: { selectedPack: Pack, packs: Pack[] }) {
  return selectedPack.requirements.reduce((acc: Pack[], reqPackId: string) => {
    const dep = packs.find((pack: Pack) => pack.pack_id === reqPackId);
    if (dep) acc.push(dep);

    return acc;
  }, []);
}

export function getDependents({ selectedPack, packs }: { selectedPack: Pack, packs: Pack[] }) {
  const deps:Pack[] = [];

  packs.forEach((pack) => {
    if (pack.requirements.indexOf(selectedPack.pack_id) > -1) {
      deps.push(pack);
    }
  });

  return deps;
}
