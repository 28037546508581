import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import type { PacksIndexAPIType, Pack } from 'illuminate/types';

export const fetchPacks = async (
  bundleId: string,
  page: number,
  perPage: number,
  query: string = null,
  includeDeps: boolean = false,
): Promise<PacksIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `include_deps=${includeDeps}`,
  ];
  if (query) params.push(`query=${encodeURI(query)}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.packs(bundleId).index(params.join('&')).url));
};

type UpdatePacks = {
  bundleVersion: string
  requestObject: {
    enabled: boolean,
    processingPackIds: string[],
    spotlightPackIds: string[]
  }
}

export const updatePacks = async ({ bundleVersion, requestObject }: UpdatePacks): Promise<Pack[]> => {
  const { url } = EnterpriseApiRoutes.Illuminate.bundles.packs(bundleVersion).update();

  return fetch('POST', qualifyUrl(url), requestObject);
};
