import type { SearchParams, Attribute, PaginatedListJSON } from 'stores/PaginationTypes';
import PaginationURL from 'util/PaginationURL';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { ReportSummary } from 'report/types';
import { SOURCE_URL } from 'report/Constants';
import FiltersForQueryParams from 'components/common/EntityFilters/FiltersForQueryParams';

type PaginatedReportsResponse = PaginatedListJSON & {
  elements: Array<ReportSummary>,
  attributes: Array<Attribute>,
}

const fetchReports = (searchParams: SearchParams) => {
  const url = PaginationURL(
    `${SOURCE_URL}/reports/paginated`,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    {
      sort: searchParams.sort.attributeId,
      order: searchParams.sort.direction,
      filters: FiltersForQueryParams(searchParams.filters),
    });

  return fetch<PaginatedReportsResponse>('GET', qualifyUrl(url)).then(
    ({ elements, total, count, page, per_page: perPage, attributes }) => ({
      list: elements,
      pagination: { total, count, page, perPage },
      attributes,
    }),
  );
};

export default fetchReports;
