// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mWhbpRLhcZ1dQxNGzolp {
    cursor: pointer;
}

.I7WhVAPVbPzC35UkT4xf {
    width: 300px;
}

.Pf90UHT2g5siVjQ3QAPw {
    margin-top: 5px;
}

.p1bhNRj3mu0JvhflZUYM {
    margin-top: 10px;
}

.V_DdegPwtHpwO9w_M_v_ {
    white-space: pre-wrap;
}

.SJy0WON9io_KsQtb5qtx {
    width: 1%;
}`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/rules/rule-helper/RuleHelper.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,SAAS;AACb","sourcesContent":[":local(.clickableRow) {\n    cursor: pointer;\n}\n\n:local(.functionTableCell) {\n    width: 300px;\n}\n\n:local(.marginQuickReferenceText) {\n    margin-top: 5px;\n}\n\n:local(.marginTab) {\n    margin-top: 10px;\n}\n\n:local(.exampleFunction) {\n    white-space: pre-wrap;\n}\n\n:local(.adjustedTableCellWidth) {\n    width: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableRow": `mWhbpRLhcZ1dQxNGzolp`,
	"functionTableCell": `I7WhVAPVbPzC35UkT4xf`,
	"marginQuickReferenceText": `Pf90UHT2g5siVjQ3QAPw`,
	"marginTab": `p1bhNRj3mu0JvhflZUYM`,
	"exampleFunction": `V_DdegPwtHpwO9w_M_v_`,
	"adjustedTableCellWidth": `SJy0WON9io_KsQtb5qtx`
};
export default ___CSS_LOADER_EXPORT___;
