import * as React from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import { useUpdateSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';

import List from './List';

function UsersModal() {
  const { setModal, entity: securityEvent, setEntity } = useModalContext();
  const [ownerId, setOwnerId] = React.useState<string>(securityEvent.owner);
  const { updateSecurityEvent, updatingSecurityEvent } = useUpdateSecurityEvent();

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  const assignOwner = async () => {
    const payload = {
      owner: ownerId,
      notes: securityEvent.notes,
      status: securityEvent.status,
    };

    await updateSecurityEvent({ id: securityEvent.event.id, data: payload });

    setModal(null);
    setEntity(null);
  };

  return (
    <ConfirmDialog show
                   onConfirm={assignOwner}
                   confirmDisabled={updatingSecurityEvent}
                   isLoading={updatingSecurityEvent}
                   confirmText="Assign"
                   onCancel={onCancel}
                   title="Select an Event Owner">
      <List ownerId={ownerId} setOwnerId={setOwnerId} />
    </ConfirmDialog>
  );
}

export default UsersModal;
