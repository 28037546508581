import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import { DATA_WAREHOUSE_API_ROUTES } from '../Constants';
import type { StreamDataWarehouseConfig } from '../Types';

export const fetchStreamDataWarehouseStatus = (streamId: string) => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.stream_data_warehouse_config(streamId).url));

const useStreamDataWarehouseStatus = (streamId: string): {
  data: StreamDataWarehouseConfig,
  refetch: () => void,
  isFetching: boolean,
  isError: boolean,
} => {
  const { data, refetch, isFetching, isError } = useQuery<StreamDataWarehouseConfig, Error>(
    ['data-warehouse-config', streamId, 'enabled'],
    () => fetchStreamDataWarehouseStatus(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  return {
    data,
    refetch,
    isFetching,
    isError,
  };
};

export default useStreamDataWarehouseStatus;
