import { v4 as uuidv4 } from 'uuid';

import type { SigmaRuleZipValidationErrorAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';

export type FileForm = {
  id: string;
  file: File;
  ruleErrors?: (SigmaRuleZipValidationErrorAPIType | string)[];
  streams: {
    value: string[];
    touched: boolean;
    valid: boolean;
  },
  stream_categories: {
    value: string[];
    touched: boolean;
    valid: boolean;
  },
  notifications: {
    value: string[];
    touched: boolean;
    valid: boolean;
  },
  search_within: {
    value: number;
    touched: boolean;
    valid: boolean;
    error: string;
  },
  search_within_unit: {
    value: 'MINUTES' | 'HOURS' | 'DAYS' | string;
    touched: boolean;
    valid: boolean;
  },
  execute_every: {
    value: number;
    touched: boolean;
    valid: boolean;
    error: string;
  };
  execute_every_unit: {
    value: 'MINUTES' | 'HOURS' | 'DAYS' | string;
    touched: boolean;
    valid: boolean;
  },
  use_cron_scheduling: {
    value: boolean,
    touched: boolean,
    valid: boolean,
  },
  cron_expression: {
    value: string,
    touched: boolean,
    valid: boolean,
    error: string,
  },
  cron_timezone: {
    value: string,
    touched: boolean,
    valid: boolean,
  },
  filters: {
    value: SearchFilter[];
    touched: boolean;
    valid: boolean;
  },
  remediation_steps: {
    value: string;
    touched: boolean;
    valid: boolean;
  },
}

const getFileId = () => {
  const id = uuidv4().split('-');

  return id[id.length - 1];
};

export const filesToForms = (files: File[]): FileForm[] => (
  files.map((f: File) => ({
    id: getFileId(),
    file: f,
    streams: { value: [], touched: false, valid: true },
    stream_categories: { value: [], touched: false, valid: true },
    notifications: { value: [], touched: false, valid: true },
    search_within: { value: 5, touched: false, valid: true, error: '' },
    search_within_unit: { value: 'MINUTES', touched: false, valid: true },
    execute_every: { value: 5, touched: false, valid: true, error: '' },
    execute_every_unit: { value: 'MINUTES', touched: false, valid: true },
    use_cron_scheduling: { value: false, touched: false, valid: true },
    cron_expression: { value: '', touched: false, valid: true, error: '' },
    cron_timezone: { value: null, touched: false, valid: true },
    filters: { value: [], touched: false, valid: true },
    remediation_steps: { value: '', touched: false, valid: true },
  }))
);

export type BodyPayload = {
  [key: string]: {
    streams: string[];
    stream_categories: string[];
    notifications: string[];
    search_within_ms: number;
    execute_every_ms: number;
    use_cron_scheduling: boolean;
    cron_expression: string;
    cron_timezone: string;
    filters: SearchFilter[];
    remediation_steps: string;
  },
};

const toMilliseconds = (time: number, unit: 'HOURS' | 'MINUTES' | 'DAYS' | string) => {
  let milliseconds = time * 1000;
  if (unit === 'MINUTES' || unit === 'HOURS') milliseconds *= 60;
  if (unit === 'HOURS') milliseconds *= 60;

  return milliseconds;
};

export const getBodyPayload = (fileForms: FileForm[]): BodyPayload => {
  const bodyPayload: BodyPayload = fileForms.reduce((acc: BodyPayload, fileForm: FileForm) => {
    const { streams, stream_categories, notifications, search_within, search_within_unit, execute_every, execute_every_unit, use_cron_scheduling, cron_expression, cron_timezone, filters } = fileForm;

    acc[fileForm.id] = {
      streams: streams.value,
      stream_categories: stream_categories.value,
      notifications: notifications.value,
      search_within_ms: toMilliseconds(search_within.value, search_within_unit.value),
      execute_every_ms: toMilliseconds(execute_every.value, execute_every_unit.value),
      use_cron_scheduling: use_cron_scheduling.value,
      cron_expression: cron_expression.value,
      cron_timezone: cron_timezone.value,
      filters: filters.value,
      remediation_steps: fileForm.remediation_steps.value,
    };

    return acc;
  }, {} as BodyPayload);

  return bodyPayload;
};
