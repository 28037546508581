import * as React from 'react';
import styled from 'styled-components';

import Routes from 'security-app/routing/Routes';
import PageNavigation from 'components/common/PageNavigation';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import { Spinner } from 'components/common';
import OpenSearchRequiredPage from 'security-app/pages/OpenSearchRequiredPage';
import StyledPageContentLayout from 'security-app/components/common/StyledPageContentLayout';
import PageContentLayout from 'components/layout/PageContentLayout';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';

const NAV_ITEMS = [
  { title: 'Dashboard', path: Routes.ANOMALIES.LIST, exactPathMatch: true },
  { title: 'Anomaly Detectors', path: Routes.ANOMALIES.DETECTORS.LIST },
];

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

type AnomalyLayoutProps = {
  children: React.ReactNode;
};

export default function AnomalyLayout({ children }: AnomalyLayoutProps) {
  const { data: usingOpenSearch, isLoading, error } = useSearchVersionCheck('opensearch');
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';

  if (isLoading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;

  if (!usingOpenSearch.satisfied) {
    return (
      <StyledPageContentLayout>
        <OpenSearchRequiredPage />
      </StyledPageContentLayout>
    );
  }

  return (
    <PageContentLayout>
      <Main>
        <Header $isSecurityPerspective={isSecurityPerspective}>
          {!isSecurityPerspective && (<SectionHeading>Anomalies</SectionHeading>)}
          {isSecurityPerspective ? (<PageNavigation items={NAV_ITEMS} />) : (
            <NavRow>
              <NavTabLink to={Routes.ANOMALIES.LIST} end>Dashboard</NavTabLink>
              <NavTabLink to={Routes.ANOMALIES.DETECTORS.LIST}>Anomaly Detectors</NavTabLink>
            </NavRow>
          )}
        </Header>
        <div>{children}</div>
      </Main>
    </PageContentLayout>

  );
}
