import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';

import type { Assignee } from '../Investigations/hooks/useUsersAndTeams';

const ICON_TYPES = {
  team: 'group',
  user: 'person',
} as const;

const StyledIcon = styled(Icon)`
  width: 24px;
  margin-right: 4px;
  vertical-align: middle;
`;

type Props = {
  assignee: Assignee,
}

const AssigneeCell = ({ assignee }: Props) => (
  <>
    <StyledIcon name={ICON_TYPES[assignee.type]} size="xs" />
    {assignee.label}
  </>
);

export default AssigneeCell;
