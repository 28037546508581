import * as React from 'react';
import styled, { css } from 'styled-components';

import type { InvestigationsDetailsAPIType } from 'security-app/hooks/api/investigationsAPI.types';

import Events from './Events';
import Dashboards from './Dashboards';
import Logs from './Logs';
import Searches from './Searches';

import { Container, Row } from '../styled.components';

type EvidenceType = {
  id: string,
  label: string,
  count: number,
  component: (investigation: InvestigationsDetailsAPIType) => React.ReactNode,
};

const EVIDENCE_MAP = {
  events: {
    id: 'events',
    label: 'Events',
    count: 0,
    component: (investigation: InvestigationsDetailsAPIType) => (
      <Events investigation={investigation} />
    ),
  },
  dashboards: {
    id: 'dashboards',
    label: 'Dashboards',
    count: 0,
    component: (investigation: InvestigationsDetailsAPIType) => (
      <Dashboards investigation={investigation} />
    ),
  },
  logs: {
    id: 'logs',
    label: 'Logs',
    count: 0,
    component: (investigation: InvestigationsDetailsAPIType) => (
      <Logs investigation={investigation} />
    ),
  },
  searches: {
    id: 'searches',
    label: 'Searches',
    count: 0,
    component: (investigation: InvestigationsDetailsAPIType) => (
      <Searches investigation={investigation} />
    ),
  },
};

const NavTab = styled.span<{ $active: boolean }>(({ $active, theme }) => css`
  cursor: pointer;
  color: ${$active ? `${theme.colors.global.textDefault}` : 'grey'};
  padding-bottom: 5px;
  border-bottom: 1px solid ${$active ? `${theme.colors.button.primary.background}` : 'transparent'};

  &:hover {
    color: ${theme.colors.global.textDefault};
  }
`);

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Evidence({ investigation }: Props) {
  const [evidenceId, setEvidenceId] = React.useState<string>();

  const sortedEvidence = React.useMemo(() => {
    const auxSorted = Object.values(EVIDENCE_MAP).sort((a: EvidenceType, b: EvidenceType) => {
      const aCount = investigation[a.id].length;
      const bCount = investigation[b.id].length;
      EVIDENCE_MAP[a.id].count = aCount;
      EVIDENCE_MAP[b.id].count = bCount;

      if (aCount === 0 && bCount === 0) {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
      }

      if (aCount === 0) return 1;
      if (bCount === 0) return -1;

      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;

      return 0;
    });

    if (!evidenceId) setEvidenceId(auxSorted[0].id);

    return auxSorted;
  }, [investigation, evidenceId]);

  return (
    <Container>
      <div>Evidence:</div>
      <Row $gap="1rem">
        {
          sortedEvidence.map((evidence: EvidenceType) => (
            <NavTab key={evidence.id}
                    onClick={() => setEvidenceId(evidence.id)}
                    $active={evidenceId === evidence.id}>
              {evidence.label} ({evidence.count})
            </NavTab>
          ))
        }
      </Row>
      {evidenceId && EVIDENCE_MAP[evidenceId].component(investigation)}
    </Container>
  );
}

export default Evidence;
