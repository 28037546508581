import React, { useCallback } from 'react';

import DataWarehouseDetailsPageSubactions from 'data-warehouse/components/DataWarehouseDetailsPageSubactions';
import { Button } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import type { DataWarehouse } from 'data-warehouse/Types';

const useTableElements = () => {
  const entityActions = useCallback((dataWarehouse: DataWarehouse) => (
    <>
      <LinkContainer to={`${Routes.stream_view(dataWarehouse.stream_id)}?segment=destinations`}>
        <Button bsStyle="info" bsSize="xsmall">Data Routing</Button>
      </LinkContainer>
      <DataWarehouseDetailsPageSubactions dataWarehouse={dataWarehouse} />
    </>
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
