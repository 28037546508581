import React from 'react';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import Azure from './Azure';
import INITIAL_FORMDATA from './_initialFormData';

const EmbeddedAzureApp = () => (
  <StepsProvider>
    <FormDataProvider initialFormData={INITIAL_FORMDATA}>
      <SidebarProvider>
        <AdvancedOptionsProvider>
          <Azure externalInputSubmit={false} />
        </AdvancedOptionsProvider>
      </SidebarProvider>
    </FormDataProvider>
  </StepsProvider>
);

export default EmbeddedAzureApp;
