import * as React from 'react';
import styled from 'styled-components';

import OverlayDropdownButton from 'components/common/OverlayDropdownButton';
import type { Attributes } from 'stores/PaginationTypes';
import type { Filters, Filter } from 'components/common/EntityFilters/types';
import FilterConfiguration from 'components/common/EntityFilters/FilterConfiguration';

const Container = styled.div`
  margin-left: 5px;
`;

type Props = React.PropsWithChildren<{
  attribute: string,
  filterableAttributes: Attributes,
  activeFilters: Filters | undefined,
  onCreateFilter: (attributeId: string, filter: Filter) => void,
  filterValueRenderers?: { [attributeId: string]: (value: Filter['value'], title: string) => React.ReactNode } | undefined;
}>;

const FilterDropdown = ({ attribute, children, filterableAttributes, filterValueRenderers, onCreateFilter, activeFilters }: Props) => {
  const selectedAttribute = filterableAttributes.find(({ id }) => id === attribute);

  return (
    <Container>
      <OverlayDropdownButton title={children}
                             bsSize="small"
                             buttonTitle="Create Filter"
                             closeOnSelect={false}
                             dropdownZIndex={1050}>
        {({ toggleDropdown }) => {
          const _onCreateFilter = (filter: { title: string, value: string }, closeDropdown = true) => {
            if (closeDropdown) {
              toggleDropdown();
            }

            onCreateFilter(attribute, { value: filter.value, title: filter.title });
          };

          return (
            <FilterConfiguration onSubmit={_onCreateFilter}
                                 allActiveFilters={activeFilters}
                                 attribute={selectedAttribute}
                                 filterValueRenderer={filterValueRenderers?.[attribute]} />
          );
        }}
      </OverlayDropdownButton>
    </Container>
  );
};

FilterDropdown.defaultProps = {
  filterValueRenderers: undefined,
};

export default FilterDropdown;
