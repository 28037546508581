import { useQuery } from '@tanstack/react-query';
import type { Moment } from 'moment/moment';
import * as Immutable from 'immutable';

import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type {
  ExportWidgetValues,
  ExportWidgetData,
  ExportWidgetValuesJson,
} from 'search/ExportWidgetAction/types';
import type { TimeRange } from 'views/logic/queries/Query';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import GlobalOverride from 'views/logic/search/GlobalOverride';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import EventsWidget from 'views/logic/widgets/events/EventsWidget';
import InvestigationsWidget
  from 'security-app/components/Investigations/plugin/SearchWidgets/logic/InvestigationsWidget';
import { MESSAGE_LIST_WIDGET_LIMIT } from 'common/components/widgetRenderers/Constants';

const INITIAL_DATA = {
  result: null,
  calculatedAt: null,
  types: null,
  errorMessage: null,
  hasError: false,
};

const getUrl = (exportCallId: string) => qualifyUrl(`plugins/org.graylog.plugins.widgetexport/widgetexport/${exportCallId}/values`);

const getTypeSpecificSearchTypes = (widgetType: string, searchTypesId) => {
  switch (widgetType) {
    case EventsWidget.type:
      return ({
        [searchTypesId]: {
          page: 1,
          per_page: MESSAGE_LIST_WIDGET_LIMIT,
        },
      });
    case InvestigationsWidget.type:
      return ({
        [searchTypesId]: {
          page: 1,
          per_page: MESSAGE_LIST_WIDGET_LIMIT,
        },
      });
    default:
      return {};
  }
};

const getExecutionState = (exportWidgetData: ExportWidgetData, timerangeOverride: TimeRange = undefined, now: Moment = undefined): SearchExecutionState => {
  const parameterValues = exportWidgetData.parameter_values;
  const parameterBindings = Immutable.Map<string, ParameterBinding>(Object.entries(parameterValues || {}).map(([key, { value }]) => [key, ParameterBinding.forValue(value)]));
  const searchTypes = getTypeSpecificSearchTypes(exportWidgetData.type, exportWidgetData.search_type_id);
  const globalOverrides = GlobalOverride
    .builder()
    .searchTypes(searchTypes)
    .timerange(timerangeOverride)
    .now(now)
    .build();

  return SearchExecutionState.create(parameterBindings, globalOverrides);
};

export const fetchWidgetValues = (exportCallId: string, executionState: SearchExecutionState): Promise<ExportWidgetValues> => fetch<ExportWidgetValuesJson>('POST', getUrl(exportCallId), executionState).then((values) => ({
  calculatedAt: values.calculated_at,
  result: values.result,
  types: Immutable.List(values.field_types.map((json) => FieldTypeMapping.fromJSON(json))),
  hasError: values.errors?.length > 0,
  errorMessage: values.errors?.map((e) => e.description).join('\n'),
}));

const useWidgetValues = (exportCallId: string, exportWidgetData: ExportWidgetData, timerangeOverride: TimeRange, now: Moment, enabled: boolean): {
  data: ExportWidgetValues,
  refetch: () => void,
  isFetching: boolean,
} => {
  const { data, refetch, isFetching } = useQuery(
    ['exported-widget-values', exportCallId, exportWidgetData?.parameter_values, timerangeOverride, now],
    () => fetchWidgetValues(exportCallId, getExecutionState(exportWidgetData, timerangeOverride, now)),
    {
      onError: (error) => {
        UserNotification.error(`Fetching widget values failed with status: ${error}`, 'Could not retrieve widget values');
      },
      keepPreviousData: true,
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isFetching,
  });
};

export default useWidgetValues;
