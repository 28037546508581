import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import type { ReportStatus } from 'report/types';
import Spinner from 'components/common/Spinner';

const Wrapper = styled.div<{ $enabled: boolean }>(({ theme, $enabled }) => css`
  color: ${$enabled ? theme.colors.variant.success : theme.colors.variant.danger};
`);

type Props = {
  value: boolean,
  status: ReportStatus,
}

const PENDING_STATUSSES: ReportStatus[] = ['STARTING', 'RUNNING', 'RETRYING'];
const HasAsset = ({ value, status }: Props) => ((!value && PENDING_STATUSSES.includes(status))
  ? <Spinner delay={0} text="" />
  : (
    <Wrapper $enabled={value}>
      <Icon name={value ? 'check_circle' : 'cancel'} />
    </Wrapper>
  ));

export default HasAsset;
