import React, { useCallback } from 'react';

import { fetchLicenses, keyFn }
  from 'license/hooks/useLicenses';
import LicenseActions from 'license/LicenseList/LicenseActions';
import customColumnRenderers from 'license/LicenseList/helpers/customColumnRenderers';
import type { License } from 'license/types';
import type { Sort } from 'stores/PaginationTypes';
import { PaginatedEntityTable } from 'components/common';

export const DEFAULT_LAYOUT = {
  entityTableId: 'licenses',
  defaultPageSize: 20,
  defaultSort: { attributeId: 'status', direction: 'asc' } as Sort,
  defaultDisplayedAttributes: ['license_type', 'status', 'products', 'contact', 'license_id', 'start_date', 'end_date', 'details', 'violations'],
};

const COLUMNS_ORDER = ['license_type', 'status', 'products', 'licensed_to', 'contact', 'license_id', 'start_date', 'end_date', 'details', 'violations'];

const LicenseList = () => {
  const licenseAction = useCallback(({ id, license_id, details: { contract_id } }: License) => (
    <LicenseActions id={id} licenseId={license_id} contractId={contract_id} />
  ), []);

  return (
    <PaginatedEntityTable<License> humanName="licenses"
                                   columnsOrder={COLUMNS_ORDER}
                                   entityActions={licenseAction}
                                   tableLayout={DEFAULT_LAYOUT}
                                   fetchEntities={fetchLicenses}
                                   keyFn={keyFn}
                                   columnRenderers={customColumnRenderers}
                                   entityAttributesAreCamelCase={false}
                                   searchPlaceholder="Search for license" />
  );
};

export default LicenseList;
