import type Widget from 'views/logic/widgets/Widget';
import type { WidgetActionType } from 'views/components/widgets/Types';
import ExportMessageWidgetActionComponent
  from 'views/components/widgets/ExportWidgetAction/ExportMessageWidgetActionComponent';
import LogViewWidget from 'logview/logic/LogViewWidget';

const ExportLogViewWidgetAction: WidgetActionType = {
  type: 'export-log-view-widget-action',
  position: 'menu',
  isHidden: (w: Widget) => (w.type !== LogViewWidget.type),
  component: ExportMessageWidgetActionComponent,
};

export default ExportLogViewWidgetAction;
