import { useQuery } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { INDEXER_INCLUSION_TYPE, BOTH_INCLUSION_TYPE } from 'data-warehouse/Constants';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_INCLUSION_TYPE } from 'data-warehouse/Constants';
import useUserDateTime from 'hooks/useUserDateTime';
import type FetchError from 'logic/errors/FetchError';

type Options = {
  enabled: boolean,
}
type InclusionType = typeof DATA_WAREHOUSE_INCLUSION_TYPE | typeof INDEXER_INCLUSION_TYPE | typeof BOTH_INCLUSION_TYPE ;

export type EstimateParams = {
  to: string,
  from: string,
  streamIds: Array<string>,
  inclusion_type: InclusionType,
}

export type EstimateType = {
  from: string,
  to: string,
  size_estimates: {
    [_key: string]: {
      table_identifier: string,
      archive_name: string,
      row_count: number,
      size_bytes: number,
      files_count: number,
    }
  },
  row_count: number,
  total_size_bytes: number,
  total_files_count: number,
}

const fetchEstimate = (params: EstimateParams, userTimezone: string) => {
  const { streamIds, from, to, inclusion_type = DATA_WAREHOUSE_INCLUSION_TYPE } = params;
  const streamIdString = streamIds.join(',');
  const uriParams = (from !== '' && to !== '' && streamIdString !== '')
    ? `stream_ids=${encodeURIComponent(streamIdString)}&inclusion_type=${encodeURIComponent(inclusion_type)}&${getDataWarehouseUriParams({ from, to }, userTimezone)}`
    : '';

  if (uriParams === '') {
    return null;
  }

  return fetch(
    'GET',
    qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.estimate().url}?${uriParams}`),
  );
};

const useDataWarehouseEstimate = (params: EstimateParams, { enabled }: Options = { enabled: true }): {
  data: EstimateType,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { userTimezone } = useUserDateTime();
  const { data, refetch, isInitialLoading } = useQuery<EstimateType, FetchError>(
    ['data-warehouse', 'estimate'],
    () => fetchEstimate(params, userTimezone),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Data Warehouse action estimate failed with status: ${errorThrown}`,
          'Could not load Data Warehouse estimate.');
      },
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouseEstimate;
