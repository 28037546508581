import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import {
  DATA_WAREHOUSE_API_ROUTES,
  DATA_WAREHOUSE_CONFIG_QUERY_KEYS,
} from 'data-warehouse/Constants';
import type { Backend } from 'archive/types';

const fetchDataWarehouseBackend = (id: string) => fetch('GET', qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backend.get(id).url}`));

const useDataWarehouseBackend = (id: string, enabled = true): {
    data: Backend;
    refetch: () => void;
    isInitialLoading: boolean,
    isSuccess: boolean,
    error: Error,
} => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<Backend, Error>(
    [...DATA_WAREHOUSE_CONFIG_QUERY_KEYS, id],
    () => fetchDataWarehouseBackend(id),
    {

      keepPreviousData: true,
      enabled,
    },
  );

  return ({
    data: data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  });
};

export default useDataWarehouseBackend;
