import React from 'react';

import PageHeader from 'components/common/PageHeader';
import { ExternalLink } from 'components/common';

import EmbeddedDefenderApp from './EmbeddedDefenderApp';

const DefenderApp = () => (
  <>
    <PageHeader title="Microsoft Defender Integrations">
      <span>This feature retrieves log records from Microsoft Defender Platform.</span>
      <p>
        You need to have{' '}
        <ExternalLink href="https://security.microsoft.com">
          Microsoft Defender for Endpoint
        </ExternalLink>
        {' '}
      </p>
    </PageHeader>
    <EmbeddedDefenderApp />
  </>
);

export default DefenderApp;
