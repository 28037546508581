import * as React from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import { useCallback } from 'react';
import { useField } from 'formik';

import { Button, Input } from 'components/bootstrap';
import { MAX_LOGO_SIZE } from 'report/Constants';
import { Icon, InputDescription, Dropzone } from 'components/common';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';

const DropzoneInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const LogoWrapper = styled.div`
  position: relative;
  border: 1px #e3e3e3 solid;
  display: inline-block;
`;

const Logo = styled.img`
  max-width: 130px;
  max-height: 130px;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ReportLogoEdit = () => {
  const [, { value, error }, { setValue }] = useField<ReportFormValues['logo']>('logo');

  const updateReportLogo = useCallback((file: File) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setValue(reader.result as string);
    };

    reader.readAsDataURL(file);
  }, [setValue]);

  return (
    <Input id="report-logo-edit"
           label="Logo">
      <div>
        {value && (
          <LogoWrapper>
            <Logo src={value} alt="report-logo" />
            <RemoveButton bsSize="xsmall"
                          bsStyle="danger"
                          title="Remove logo"
                          onClick={() => setValue(undefined)}>
              <Icon name="close" />
            </RemoveButton>
          </LogoWrapper>
        )}
        {!value && (
          <Dropzone onDrop={(files) => updateReportLogo(files[0])}
                    onReject={() => {}}
                    accept={['image/png', 'image/jpeg']}
                    data-testid="upload-dropzone"
                    inputProps={{
                      placeholder: 'Drag a logo here or click to select file',
                    }}
                    maxSize={MAX_LOGO_SIZE}
                    loading={false}>
            <DropzoneInner>
              <Dropzone.Accept>
                <Icon name="image" type="regular" size="2x" />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <Icon name="warning" size="2x" />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <Icon name="image" type="regular" size="2x" />
              </Dropzone.Idle>
              <div>Drag a logo here or click to select file</div>
            </DropzoneInner>
          </Dropzone>
        )}
      </div>
      <InputDescription error={error}
                        help={(
                          <>
                            Choose an image to use as a logo in the report&#39;s cover page. The image must be in JPEG
                            or PNG formats and cannot exceed {numeral(MAX_LOGO_SIZE).format('0 b')}.
                          </>
                        )} />
    </Input>
  );
};

export default ReportLogoEdit;
