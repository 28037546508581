import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import URI from 'urijs';

import type { ReportHistory } from 'report/types';
import { Button } from 'components/bootstrap';
import { reportingUrl } from 'report/Constants';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import ExpandedSectionsContext from 'components/common/EntityDataTable/contexts/ExpandedSectionsContext';
import Icon from 'components/common/Icon';

const downloadUrl = (reportId: string, jobId: string) => new URI(reportingUrl(`${reportId}/history/${jobId}/download`)).toString();

type Props = {
  id: string,
  reportHistory: ReportHistory,
}

const detailsKey = 'details';

const ReportHistoryActions = ({ id, reportHistory }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const { toggleSection, expandedSections } = useContext(ExpandedSectionsContext);
  const detailsExpanded = useMemo(() => expandedSections?.[reportHistory.id]?.includes(detailsKey), [expandedSections, reportHistory.id]);

  const onDownload = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.DOWNLOAD, {
      app_pathname: 'report',
      app_section: 'report-history',
    });
  }, [sendTelemetry]);

  const onToggleDetails = useCallback(() => {
    toggleSection(reportHistory.id, detailsKey);
  }, [reportHistory.id, toggleSection]);

  return (
    <ButtonToolbar>
      {reportHistory.has_asset && (
        <a key="configuration-button" href={downloadUrl(id, reportHistory.id)} rel="noopener noreferrer">
          <Button disabled={!reportHistory.has_asset} bsSize="xsmall" onClick={onDownload}>
            <Icon name="download" /> Download
          </Button>
        </a>
      )}
      <Button bsSize="xsmall" onClick={onToggleDetails}>
        <Icon name={detailsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
      </Button>
    </ButtonToolbar>
  );
};

export default ReportHistoryActions;
