import * as React from 'react';
import styled from 'styled-components';

import Routes from 'security-app/routing/Routes';
import PageNavigation from 'components/common/PageNavigation';
import { IfPermitted } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';

const NAV_ITEMS = [
  { title: 'Rules', path: Routes.SIGMA.LIST, exactPathMatch: true },
  { title: 'Sigma Repos', path: Routes.SIGMA.CONFIGURATION, permissions: ['sigma_repository:read'] },
];

const StyledPageContentLayout = styled(PageContentLayout)`
  overflow: unset;
`;

export default function SigmaLayout({ children }: { children: React.ReactNode }) {
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';

  return (
    <StyledPageContentLayout>
      <Header $isSecurityPerspective={isSecurityPerspective}>
        {!isSecurityPerspective && (<SectionHeading>Sigma Rules</SectionHeading>)}
        {isSecurityPerspective ? (<PageNavigation items={NAV_ITEMS} />) : (
          <NavRow>
            <NavTabLink to={Routes.SIGMA.LIST} end>Rules</NavTabLink>
            <IfPermitted permissions="sigma_repository:read">
              <NavTabLink to={Routes.SIGMA.CONFIGURATION}>Sigma Repos</NavTabLink>
            </IfPermitted>
          </NavRow>
        )}
      </Header>
      <div>{children}</div>
    </StyledPageContentLayout>
  );
}
