import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import type { Backend } from 'archive/types';
import { FILESYSTEM_TYPE } from 'archive/ArchiveStore';
import { FormikFormGroup } from 'components/common';

type Props = {
  checkOutputPath: (path: string) => Promise<{ result: string }>,
  noPreview?: boolean,
};

const StyledDiv = styled.div`
  margin: 10px 0;
`;

const ArchiveBackendOutputPathFieldGroup = ({ checkOutputPath, noPreview }: Props) => {
  const { values: formValues, setFieldError } = useFormikContext<Backend>();
  const [pathCheck, setPathCheck] = useState<string | null>(null);
  const [pathError, setPathError] = useState<string | null>(null);
  const _validateOutputPath = useCallback((path: string) => {
    checkOutputPath(path).then((response) => {
      setPathCheck(response.result);
      setPathError(null);
    }).catch((error) => {
      try {
        setPathCheck(null);
        const errorMessage = error.additional.body.message;
        setPathError(errorMessage);

        setFieldError('settings.output_path', errorMessage);
      } catch {
        setPathCheck(null);
        setPathError(error.message);

        setFieldError('settings.output_path', error.message);
      }
    });
  }, [checkOutputPath, setFieldError]);
  const _debouncedValidateOutputPath = debounce(_validateOutputPath, 500);
  const label = formValues.settings.type === FILESYSTEM_TYPE ? <span>Output base path</span> : <span>S3 Output base path</span>;
  const helpText = formValues.settings.type === FILESYSTEM_TYPE ? <>Base path where the archives should be stored.</> : <>Base path where the archives should be stored within the S3 bucket.</>;

  const getPathError = () => pathError && <span><strong>ERROR:</strong> {pathError}</span>;

  const _onChange = (event) => {
    _debouncedValidateOutputPath(event.target.value);
  };

  useEffect(() => {
    _validateOutputPath(formValues.settings.output_path);
  }, [formValues, _validateOutputPath]);

  return (
    <StyledDiv>
      <FormikFormGroup name="settings.output_path"
                       placeholder="/data/archives/graylog/example"
                       label={label}
                       required={formValues.settings.type === FILESYSTEM_TYPE}
                       help={<span>{helpText} Can <strong>only</strong> be set on backend creation and not be changed later!</span>}
                       labelClassName=""
                       error={getPathError()}
                       wrapperClassName=""
                       onChange={_onChange}
                       disabled={!!formValues?.id} />
      {!noPreview && (
      <span>
        <strong>{formValues.settings.type === FILESYSTEM_TYPE ? 'Example output path: ' : 'S3 Output path preview: '}</strong>
        <code>{pathCheck}</code>
      </span>
      )}
    </StyledDiv>
  );
};

ArchiveBackendOutputPathFieldGroup.propTypes = {
  checkOutputPath: PropTypes.func.isRequired,
  noPreview: PropTypes.bool,
};

ArchiveBackendOutputPathFieldGroup.defaultProps = {
  noPreview: false,
};

export default ArchiveBackendOutputPathFieldGroup;
