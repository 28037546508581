import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from './context/FormData';
import { AdvancedOptionsContext } from './context/AdvancedOptions';
import AdditionalFields from './common/AdditionalFields';
import type {
  HandleFieldUpdateType,
  FormDataContextType,
  AdvancedOptionsContextType,
} from './types';

interface Props {
  onChange: HandleFieldUpdateType;
}

const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

const FormAdvancedOptions = (
  {
    onChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const {
    azureThrottleEnabled,
    azureStoreFullMessage,
  } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  return (
    <StyledAdditionalFields title="Advanced Options"
                            visible={isAdvancedOptionsVisible}
                            onToggle={handleToggle}>
      <Input id="azureThrottleEnabled"
             type="checkbox"
             value="enable-throttling"
             defaultChecked={azureThrottleEnabled?.value}
             onChange={onChange}
             label="Enable Throttling"
             help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled." />
      <Input id="azureStoreFullMessage"
             type="checkbox"
             value="enable-storeFullMessage"
             defaultChecked={azureStoreFullMessage?.value}
             onChange={onChange}
             label="Store Full Message"
             help="Store the full original Azure log record message as full_message?" />
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
