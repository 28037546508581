import * as React from 'react';

import { Alert } from 'components/bootstrap';

const DataWarehouseStreamDeleteWarning = () => (
  <Alert bsStyle="danger">
    Data Warehouse is enabled on this stream and have data. <br />
    Please disable it and delete the data before deleting the stream.
  </Alert>
);

export default DataWarehouseStreamDeleteWarning;
