import * as React from 'react';
import moment from 'moment';

import type { DataWarehouse } from '../../Types';

type Props = {
  datawarehouse: DataWarehouse,
}

const DaysOfData = ({ datawarehouse }: Props) => {
  const days = (!datawarehouse.timestamp_from || !datawarehouse.timestamp_to)
    ? 0 : moment(datawarehouse.timestamp_to).diff(datawarehouse.timestamp_from, 'days');

  return (
    <>{days}</>
  );
};

export default DaysOfData;
