import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import moment from 'moment/moment';
import * as React from 'react';
import { styled, css, ThemeProvider } from 'styled-components';

import PDFPage, { PREVIEW_PADDING } from 'report/report-creation/PDFPage';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import useThemes from 'theme/hooks/useThemes';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';
import GlobalOverride from 'views/logic/search/GlobalOverride';
import ReportPreviewContext from 'report/report-creation/ReportPreviewContext';
import WidgetResultContext from 'report/report-creation/WidgetResultContext';
import ColorSchemeContext from 'theme/ColorSchemeContext';
import RenderedReport from 'report/common/RenderedReport';
import { REPORT_PREVIEW_ID } from 'report/report-creation/ReportPreview';
import useWidgetResult from 'report/hooks/useWidgetResult';

export const Canvas = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: scroll
`;

const PreviewCanvas = styled(Canvas)(({ theme }) => css`
  background-color: ${theme.colors.gray[90]};
`);

const useObserveWidgetVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);
  const widgetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    });

    if (widgetRef.current) {
      observer.observe(widgetRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return ({ widgetRef, isVisible });
};

type WidgetResultProviderProps = React.PropsWithChildren<{
  widgetId: string,
  dashboardId: string,
}>

const WidgetResultProvider = ({ widgetId, dashboardId, children }: WidgetResultProviderProps) => {
  const { widgetRef, isVisible } = useObserveWidgetVisibility();
  const reportPreviewContext = useContext(ReportPreviewContext);

  const { data } = useWidgetResult({
    dashboardId,
    widgetId,
    enabled: isVisible,
    parameterValues: reportPreviewContext.parameterValues,
    globalOverride: reportPreviewContext.globalOverride,
    staleTime: 1000 * 60 * 60,
  });

  return (
    <WidgetResultContext.Provider value={data}>
      <div ref={widgetRef}>
        {children}
      </div>
    </WidgetResultContext.Provider>
  );
};

type Props = {
  report: ReportFormValues,
};

const PDFPreview = ({ report }: Props) => {
  const scrollContainer = useRef(null);
  const { scTheme, colorScheme } = useThemes(COLOR_SCHEME_LIGHT, true);
  const now = useMemo(() => moment(), []);

  const contextValue = useMemo(() => ({
    parameterValues: report.parameterValues,
    globalOverride: GlobalOverride.builder().now(now).build(),
  }), [now, report.parameterValues]);

  const handleTocClick = useCallback((scale: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    const tocEntry = event.target as HTMLAnchorElement;

    if (tocEntry.hash) {
      event.preventDefault();
      const widgetHeaderId = tocEntry.hash.substring(1);
      const widgetHeader = document.getElementById(widgetHeaderId);

      if (widgetHeader) {
        const widgetTopOffset = widgetHeader.offsetTop;

        scrollContainer.current.scrollTo({
          top: (widgetTopOffset * scale) + PREVIEW_PADDING,
        });
      }
    }
  }, []);

  return (
    <ReportPreviewContext.Provider value={contextValue}>
      <ColorSchemeContext.Provider value={colorScheme}>
        <ThemeProvider theme={scTheme}>
          <PreviewCanvas id={REPORT_PREVIEW_ID} data-testid="report-preview" ref={scrollContainer}>
            <PDFPage report={report}
                     pageSize={report.layout?.pageSize}
                     pageOrientation={report.layout?.orientation}>
              {({ contentWidth, pageScaleFactor }) => (
                <RenderedReport report={report}
                                handleTocClick={handleTocClick(pageScaleFactor)}
                                width={contentWidth}
                                preview
                                widgetResultProvider={WidgetResultProvider} />
              )}
            </PDFPage>
          </PreviewCanvas>
        </ThemeProvider>
      </ColorSchemeContext.Provider>
    </ReportPreviewContext.Provider>
  );
};

export default PDFPreview;
