import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Warning } from 'license/LicenseCheck';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';

const Description = styled.div`
  max-width: 700px;
`;

const ErrorList = styled.ul`
  padding: 0;
`;

const Row = styled.div`
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin: 3px 15px 0 0;
`;

const StyledWarning = styled(Warning)`
  padding: 0;
`;

type Props = {
  featureName: string
}

const InvalidLicenseWidgetInfo = ({ featureName }: Props) => {
  const { data, isInitialLoading: isLoading } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);

  if (isLoading) return null;

  if (data.valid) return null;

  return (
    <SpinnerContainer>
      <StyledIcon name="enterprise" size="3x" />
      <Description>
        <Row>
          <strong>No valid license found</strong>
        </Row>
        <ErrorList>
          <StyledWarning featureName={featureName} />
        </ErrorList>
      </Description>
    </SpinnerContainer>
  );
};

export default InvalidLicenseWidgetInfo;
