import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/common/Select';
import { Input } from 'components/bootstrap';

import FormWrap from './common/FormWrap';
import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';
import INITIAL_FORMDATA from './_initialFormData';

const StepSubscribe = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const {
    pollingInterval,
  } = formData;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              description="">

      <Input id="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue}
             min="1"
             max="60"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new data in google workspace. The smallest allowable interval is 5 minutes."
             label="Polling Interval" />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

StepSubscribe.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepSubscribe;
