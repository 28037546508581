import { useMutation } from '@tanstack/react-query';

import ApiRoutes from 'common/ApiRoutes';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';

const deleteSnapshot = async (indexSetId : string) => {
  const url = qualifyUrl(ApiRoutes.DataTiering.deleteFailedSnapshot(indexSetId).url);

  return fetch('DELETE', url);
};

const useDeleteFailedSnapshotMutation = (indexSetId: string) => {
  const remove = useMutation(() => deleteSnapshot(indexSetId), {
    onError: (errorThrown) => {
      UserNotification.error(`Snapshot deletion failed: ${errorThrown}`,
        'Could not delete snapshot');
    },
    onSuccess: () => {
      UserNotification.success('Snapshot has been successfully deleted.', 'Success!');
    },
  });

  return { deleteFailedSnapshot: remove.mutateAsync };
};

export default useDeleteFailedSnapshotMutation;
