import * as React from 'react';

import { SelectedRowsProvider } from 'illuminate/context/SelectedRows';
import type { Pack } from 'illuminate/types';
import { PaginationProvider } from 'security-app/components/common/contexts';
import IlluminatePacks from 'illuminate/components/illuminate/IlluminatePacks';
import { Alert } from 'components/bootstrap';
import useThreatRelatedIlluminatePacks
  from 'security-app/components/Welcome/ThreatCoverageWidget/ImproveCoverageModal/useThreatRelatedIlluminatePacks';
import { Spinner } from 'components/common';

const IlluminatePacksEnable = () => {
  const { allPacks, notEnabledPacks, isInitialLoading } = useThreatRelatedIlluminatePacks();

  if (isInitialLoading) {
    return <Spinner />;
  }

  return (
    <SelectedRowsProvider<Pack>>
      <PaginationProvider initQuery="tags:attack">
        {allPacks.length > 0 && notEnabledPacks.length === 0 ? (
          <Alert bsStyle="success">All related Illuminate Packs have been activated</Alert>
        ) : (
          <IlluminatePacks showSearchForm={false}
                           includeDepPacks
                           showLoadingSpinner
                           showInstallSuccessAlert={false}
                           onInstallFinished={(setLocalPagination) => setLocalPagination((cur) => ({ ...cur, page: 1 }))} />
        )}
      </PaginationProvider>
    </SelectedRowsProvider>
  );
};

export default IlluminatePacksEnable;
