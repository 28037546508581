import * as React from 'react';
import * as Immutable from 'immutable';

import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import { DocumentTitle, Spinner } from 'components/common';
import WizardPageHeader from 'components/authentication/directoryServices/ldap/WizardPageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import type { Backend } from 'logic/authentication/okta/types';
import Routes from 'routing/Routes';
import useInitialGroupSyncValues from 'authentication/logic/oidc/useInitialGroupSyncValues';
import OidcBackendMetaProvider from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';
import LicenseCheck from 'license/LicenseCheck';
import useHistory from 'routing/useHistory';

import { handleUpdate } from './helpers/handleSubmit';
import payloadFromFormValues from './helpers/payloadFromFormValues';
import BackendWizard from './BackendWizard';
import type { ConfigFormValues } from './types';
import getDisplayName from './helpers/getDisplayName';

interface BackendEditProps {
  authenticationBackend: Backend;
}

const BackendEdit = (
  {
    authenticationBackend,
  }: BackendEditProps,
) => {
  const history = useHistory();
  const {
    formValues: groupFormValues,
    finishedLoading,
  } = useInitialGroupSyncValues(authenticationBackend.id, authenticationBackend.config.type, { teamSelection: Immutable.Set<string>() });

  if (authenticationBackend?.id && !finishedLoading) {
    return <Spinner />;
  }

  const { config: { type: backendType } } = authenticationBackend;
  const isOkta = backendType === OKTA_TYPE_KEY;
  const displayName = getDisplayName(backendType);
  const title = `Edit ${displayName} Authentication Service`;

  const onSubmitForm = (values: ConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync?: boolean) => handleUpdate(payloadFromFormValues(values), authenticationBackend.id, values, backendGroupSyncIsActive, shouldUpdateGroupSync)
    .then(() => {
      history.push(Routes.SYSTEM.AUTHENTICATION.BACKENDS.OVERVIEW);
    }).catch((error) => {
      throw error;
    });

  return (
    <DocumentTitle title={title}>
      <WizardPageHeader authenticationBackend={authenticationBackend} />
      <SectionComponent title={title}>
        <LicenseCheck>
          {({ licenseIsValid }) => (
            <OidcBackendMetaProvider defaultValue={{
              backendId: authenticationBackend?.id,
              backendGroupSyncIsActive: !!groupFormValues?.synchronizeGroups,
              licenseIsValid,
            }}>
              <BackendWizard authenticationBackend={authenticationBackend}
                             onSubmitForm={onSubmitForm}
                             groupSyncValues={groupFormValues}
                             backendType={backendType}
                             isOkta={isOkta} />
            </OidcBackendMetaProvider>
          )}
        </LicenseCheck>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default BackendEdit;
