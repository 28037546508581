import * as React from 'react';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { ReportSummary } from 'report/types';
import { Link, LinkContainer } from 'components/common/router';
import ReportRoutes from 'report/ReportRoutes';
import { Button } from 'components/bootstrap';
import StatusCell from 'report/report-history-page/StatusCell';

const customColumnRenderers: ColumnRenderers<ReportSummary> = {
  attributes: {
    title: {
      renderCell: (title: string, report) => (
        <Link to={ReportRoutes.archive(report.id)}>{title}</Link>
      ),
      width: 0.7,
    },
    subtitle: {
      width: 0.7,
    },
    timezone: {
      staticWidth: 140,
    },
    status: {
      renderCell: (_, report) => (report.status ? <StatusCell value={report.status} /> : null),
      staticWidth: 150,
    },
    recipients: {
      renderCell: (_, report) => (
        <LinkContainer key="configuration-button" to={ReportRoutes.EDIT.delivery(report.id)}>
          <Button bsSize="xsmall">{report.delivery.email_receivers.length + report.delivery.user_receivers.length === 0 ? '+ Add Emails' : report.delivery.email_receivers.length + report.delivery.user_receivers.length}</Button>
        </LinkContainer>
      ),
      staticWidth: 100,
    },
  },
};

export default customColumnRenderers;
