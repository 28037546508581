/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import { useState, useEffect, useRef } from 'react';

import EventHandlersThrottler from 'util/EventHandlersThrottler';

const useGraphWidth = (initialWidth: number = 600) => {
  const eventThrottler = useRef(new EventHandlersThrottler());
  const graphContainerRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(initialWidth);

  useEffect(() => {
    const resizeGraphs = () => {
      const { clientWidth } = graphContainerRef?.current ?? initialWidth;

      setGraphWidth(clientWidth);
    };

    const onResize = () => {
      eventThrottler.current.throttle(() => resizeGraphs());
    };

    resizeGraphs();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [initialWidth]);

  return { graphWidth, graphContainerRef };
};

export default useGraphWidth;
