import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { DataWarehouse } from 'data-warehouse/Types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';

export type DataWarehouseRetrievalOperationDeleteProps = {
  streamId: string,
  retreivalOperationId: string
};

const fetchDataWarehouse = (streamId: string) => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.get(streamId).url));

const onDeleteDataWarehouseRetrievalOperation = ({
  streamId,
  retreivalOperationId,
}: DataWarehouseRetrievalOperationDeleteProps) => fetch('DELETE', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.delete(streamId, retreivalOperationId).url));

const useDataWarehouse = (streamId: string): {
  dataWarehouseData: {
    data: DataWarehouse,
    refetch: () => void,
    isFetching: boolean,
    error: Error,
  },
  onDeleteDataWarehouseRetrievaldOperationMutation: (retrievalOperationDeleteProps: DataWarehouseRetrievalOperationDeleteProps) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { data, refetch, isFetching, error } = useQuery<DataWarehouse, Error>(
    ['data-warehouse', streamId],
    () => fetchDataWarehouse(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  const { mutateAsync: onDeleteDataWarehouseRetrievaldOperationMutation } = useMutation(onDeleteDataWarehouseRetrievalOperation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-warehouse']);

      UserNotification.success('Successfully deleted Data Warehouse retrieval operation',
        'Delete Data Warehouse retrieval operation');
    },
    onError: (errorThrown) => {
      UserNotification.error(`Deleting Data Warehouse retrieval operation failed with status: ${errorThrown}`,
        'Could not delete Data Warehouse retrieval operation');
    },
  });

  return ({
    dataWarehouseData: {
      data,
      refetch,
      isFetching,
      error,
    },
    onDeleteDataWarehouseRetrievaldOperationMutation,
  });
};

export default useDataWarehouse;
