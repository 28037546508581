import type { WorkspaceGenericInputCreateRequest, WorkspaceInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toWorkspaceInputCreateRequest = ({
  contentTypes,
  serviceAccountKey,
  workspaceName,
  workspaceThrottleEnabled,
  workspaceStoreFullMessage,
  pollingInterval,
  pageSize,
  lagOffset,
  workspaceDatasetName,
}: FormDataType): WorkspaceInputCreateRequest => ({
  name: workspaceName.value,
  content_types: contentTypes.value.split(','),
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  enable_throttling: !!workspaceThrottleEnabled?.value,
  store_full_message: !!workspaceStoreFullMessage?.value,
  service_account_key: serviceAccountKey.fileContent,
  page_size: pageSize.value,
  lag_offset: lagOffset.value,
  workspace_dataset_name: workspaceDatasetName.value,
});

export const toGenericInputCreateRequest = ({
  contentTypes,
  serviceAccountKey,
  workspaceName,
  workspaceThrottleEnabled,
  workspaceStoreFullMessage,
  pollingInterval,
  pageSize,
  lagOffset,
  workspaceDatasetName,
}: FormDataType): WorkspaceGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.googleworkspacev2.GoogleWorkspaceV2Input',
  title: workspaceName.value,
  global: false,
  configuration: {
    content_types: contentTypes.value.split(','),
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    throttling_allowed: !!workspaceThrottleEnabled?.value,
    store_full_message: !!workspaceStoreFullMessage?.value,
    service_account_key: serviceAccountKey.fileContent,
    page_size: pageSize.value,
    lag_offset: lagOffset.value,
    workspace_dataset_name: workspaceDatasetName.value,
  },
});
