import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_CONFIG_QUERY_KEYS } from 'data-warehouse/Constants';

import type { DataWarehouseConfig } from './useDataWarehouseConfig';

const updateDataWarehouseConfig = ({ config }:{config:DataWarehouseConfig}) => fetch('PUT', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.config.base().url), {
  ...config,
});

const useDataWarehouseConfigMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: onUpdateDataWarehouseConfig } = useMutation(updateDataWarehouseConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries(DATA_WAREHOUSE_CONFIG_QUERY_KEYS);

      UserNotification.success('Data Warehouse config updated successfully.',
        'Updating Data Warehouse configuration');
    },
    onError: (errorThrown) => {
      UserNotification.error(`Updating Data Warehouse config failed with status: ${errorThrown}`,
        'Updating Data Warehouse configuration');
    },
  });

  return { onUpdateDataWarehouseConfig };
};

export default useDataWarehouseConfigMutation;
