import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import RetrievalArchiveAction from 'data-warehouse/data-warehouse-overview/BulkActions/RetrievalArchiveAction';
import DeleteArchiveAction from 'data-warehouse/data-warehouse-overview/BulkActions/DeleteArchiveAction';
import { ButtonToolbar } from 'components/bootstrap';
import type { DataWarehouse } from 'data-warehouse/Types';

type Props = {
  dataWarehouse: DataWarehouse,
 }

const DataWarehouseDetailsPageSubactions = ({ dataWarehouse }: Props) => {
  const queryClient = useQueryClient();

  const refetchArchives = useCallback(() => queryClient.invalidateQueries(['data-warehouse']), [queryClient]);
  const handleFailures = useCallback((failures: Array<{ entity_id: string }>, actionPastTense: string) => {
    if (failures?.length) {
      UserNotification.error(`Data warehouse could not be ${actionPastTense}.`);
    } else {
      UserNotification.success(`Data warehouse was ${actionPastTense} successfully.`, 'Success');
    }
  }, []);

  return (
    <ButtonToolbar>
      <RetrievalArchiveAction handleFailures={handleFailures}
                              buttonType="menu"
                              archive={dataWarehouse}
                              refetchArchives={refetchArchives}
                              descriptor="data warehouse" />
      <DeleteArchiveAction handleFailures={handleFailures}
                           buttonType="menu"
                           archive={dataWarehouse}
                           refetchArchives={refetchArchives}
                           descriptor="data warehouse" />
    </ButtonToolbar>
  );
};

export default DataWarehouseDetailsPageSubactions;
