import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';

import type { SelectedSearchFilter as SelectedSearchFilterType } from 'search-filter/types';
import { SplitButton } from 'components/bootstrap';
import { Icon, TextOverflowEllipsis } from 'components/common';
import SearchFilterActionsPopover from 'search-filter/components/search-filter-bar/SearchFilterActionsPopover';
import { REFERENCED_USER_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import SearchFilterEditPopover from 'search-filter/components/search-filter-bar/SearchFilterEditPopover';
import UserFilterShareModal from 'search-filter/components/UserFilterShareModal';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import useSearchFiltersFormActions from '../../hooks/useSearchFiltersFormActions';

type Props = {
  filter: SelectedSearchFilterType,
}

const HelperSpan = styled.span`
  font-size: 70%;
  text-align: right;
  position: absolute;
  bottom: -1.3em;
  right: 0;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
`;

const InlineDiv = styled.div<{ $isDisabled?: boolean }>(({ $isDisabled, theme }) => css`
  text-decoration: ${$isDisabled ? 'line-through' : 'initial'};
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-template-columns: auto auto;
  position: relative;
  color: ${theme.colors.global.textDefault};
`);

const ButtonTitle = styled.div<{ $isDisabled: boolean }>(({ $isDisabled }) => css`
  text-decoration: ${$isDisabled ? 'line-through' : 'initial'};
`);

const StyledSplitButton = styled(SplitButton)`
  && {
    padding-right: 0;
  }

  &:hover {
    text-decoration: none;
    background: transparent;
  }

  &:focus {
    text-decoration: none;
  }
`;

const PlaceholderPopoverTarget = styled.div`
  width: 100%;
`;

const StyledTextOverflowEllipsis = styled(TextOverflowEllipsis)`
  max-width: 125px;
`;

const FilterReferenceLabel = ({ filterType, filterId }: { filterType: string, filterId: string }) => {
  const currentUser = useCurrentUser();

  if (filterType !== REFERENCED_USER_SEARCH_FILTER_TYPE) {
    return null;
  }

  const userIsOwner = isPermitted(currentUser.permissions, [`search_filters:delete:${filterId}`]);

  return (
    <HelperSpan>
      {userIsOwner ? 'from "My Filters"' : 'shared with me'}
    </HelperSpan>
  );
};

const SelectedSearchFilter = ({
  filter,
  filter: { id, title, queryString, type, frontendId, negation, description, disabled },
}: Props) => {
  const { updateFilter } = useSearchFiltersFormActions();
  const filterContainerRef = useRef();
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [showSharePopover, setShowSharePopover] = useState(false);
  const filterTitle = title || queryString;
  const hoverTitle = `${title ? `Title: ${title}. ` : ''}Query: ${queryString}.${description ? ` Description: ${description}.` : ''}`;
  const selectedFilters = useSearchFiltersFormState();
  const sendTelemetry = useSendTelemetry();
  const toggleShowFilterMenu = useCallback(() => {
    setShowFilterMenu((cur) => !cur);

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_MENU_TOGGLED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });
  }, [sendTelemetry]);
  const toggleDisableFilter = useCallback((e) => {
    e.preventDefault();
    updateFilter(frontendId, { ...filter, disabled: !disabled });

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_DISABLED_TOGGLED, {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'filter-disable',
      event_details: {
        disabled,
      },
    });
  }, [disabled, filter, frontendId, sendTelemetry, updateFilter]);

  const toggleShowSharePopover = useCallback(() => {
    setShowSharePopover((cur) => !cur);

    if (!showSharePopover) {
      toggleShowFilterMenu();
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_SHARE_CLICKED, {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'filter-share',
    });
  }, [sendTelemetry, showSharePopover, toggleShowFilterMenu]);

  const toggleShowEditPopover = useCallback(() => {
    setShowEditPopover((cur) => !cur);

    if (!showEditPopover) {
      toggleShowFilterMenu();
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_EDIT_CLICKED, {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'filter-edit',
    });
  }, [sendTelemetry, showEditPopover, toggleShowFilterMenu]);

  const updateFilterInCurrentSearch = useCallback((newFilter: SelectedSearchFilterType) => {
    const previousFilter = selectedFilters.get(newFilter.frontendId);

    if (!isEqual(newFilter, previousFilter)) {
      updateFilter(newFilter.frontendId, newFilter);
    }

    toggleShowEditPopover();
  }, [selectedFilters, toggleShowEditPopover, updateFilter]);

  return (
    <div data-testid={`selected-filter-${frontendId}`}>
      <StyledSplitButton bsSize="xsmall"
                         bsStyle="link"
                         ref={filterContainerRef}
                         open={showFilterMenu}
                         onMenuChange={setShowFilterMenu}
                         id={`filter-menu-popover-split-button-${frontendId}`}
                         width={240}
                         title={(
                           <InlineDiv onClick={toggleDisableFilter}>
                             <Icon name={disabled ? 'square' : 'check_box'} />
                             <ButtonTitle $isDisabled={disabled} data-testid="selected-filter-title">
                               {negation && <b>NOT{' '}</b>}
                               <StyledTextOverflowEllipsis titleOverride={hoverTitle}>
                                 {filterTitle}
                               </StyledTextOverflowEllipsis>
                             </ButtonTitle>
                             <FilterReferenceLabel filterType={type} filterId={id} />
                           </InlineDiv>
                         )}>
        <SearchFilterActionsPopover filter={filter}
                                    onEditClick={toggleShowEditPopover}
                                    onShareClick={toggleShowSharePopover}
                                    onItemClick={toggleShowFilterMenu} />
      </StyledSplitButton>
      {showEditPopover && (
        <SearchFilterEditPopover onSave={updateFilterInCurrentSearch}
                                 onCancel={toggleShowEditPopover}
                                 filter={filter}>
          <PlaceholderPopoverTarget />
        </SearchFilterEditPopover>
      )}
      {showSharePopover && <UserFilterShareModal filter={filter} toggleShareModalOpen={toggleShowSharePopover} />}
    </div>
  );
};

export default SelectedSearchFilter;
