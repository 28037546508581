import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import { Col, Row } from 'components/bootstrap';
import DocsHelper from 'util/DocsHelper';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import Routes from 'routing/Routes';
import { NodesActions, NodesStore } from 'stores/nodes/NodesStore';
import ImportLicenseModal from 'license/ImportLicenseModal';
import { useStore } from 'stores/connect';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import LicenseList from 'license/LicenseList/LicenseList';
import useLicenseMutation from 'license/hooks/useLicenseMutation';
import ActiveLicenseInformation from 'license/ActiveLicenseInformation';

const LicenseSectionHeading = styled.h2(({ theme }) => `
  margin-bottom: ${theme.spacings.md};
`);

const LicensesPage = () => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [verifiedLicense, setVerifiedLicense] = useState(undefined);
  const sendTelemetry = useSendTelemetry();

  const { saveLicense, verifyLicense } = useLicenseMutation();

  const { nodes } = useStore(NodesStore);

  useEffect(() => {
    NodesActions.list();
  }, []);

  const onVerifyLicense = (token: string) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.VALIDATED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    verifyLicense(token).then(({ license, error_message }) => {
      setErrorMessage(error_message);
      setVerifiedLicense(license);
    });
  };

  const onSaveLicense = (token: string, callback: () => void) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.SAVED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    saveLicense(token).then(() => {
      callback();
      window.location.reload();
    });
  };

  const clusterIdFromFirstNode = () => {
    if (nodes) {
      return Object.keys(nodes).map((id) => nodes[id]).map((node) => node.cluster_id)[0].toUpperCase();
    }

    return undefined;
  };

  const clusterId = clusterIdFromFirstNode();

  return (
    <DocumentTitle title="Graylog license management">
      <span>
        <PageHeader title="Graylog License Management"
                    actions={(
                      <ImportLicenseModal key="new-license"
                                          saveLicense={onSaveLicense}
                                          verifyLicense={onVerifyLicense}
                                          errorMessage={errorMessage}
                                          verifiedLicense={verifiedLicense} />
                    )}
                    documentationLink={{
                      title: 'License documentation',
                      path: DocsHelper.PAGES.LICENSE,
                    }}>
          <span>
            Manage your Graylog Enterprise licenses. You can get a new license from the{' '}
            <Link to={Routes.SYSTEM.ENTERPRISE}>Enterprise page</Link>.
          </span>
        </PageHeader>

        <Row className="content">
          <Col md={12}>
            <LicenseSectionHeading>Active license</LicenseSectionHeading>
            <ActiveLicenseInformation />
          </Col>
        </Row>

        <Row className="content">
          <Col md={12}>
            <LicenseSectionHeading>Installed licenses</LicenseSectionHeading>
            {clusterId && <p>This Graylog cluster ID is {clusterId}</p>}
            {nodes
              ? (
                <LicenseList />
              )
              : <Spinner />}
          </Col>
        </Row>
      </span>
    </DocumentTitle>
  );
};

export default LicensesPage;
