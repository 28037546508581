import Reflux from 'reflux';
import URI from 'urijs';

import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type {
  BackendReport,
  ReportCreate,
  ParameterValues,
  ReportDelivery,
  ReportWidgetPosition,
  BackendReportParameterValues,
  Report,
} from 'report/types';
import { reportingUrl } from 'report/Constants';
import deserializeBackendReport from 'report/deserializeBackendReport';

import ReportsActions from './ReportsActions';

const ReportsStore = Reflux.createStore<{}>({
  listenables: [ReportsActions],
  reports: undefined,

  getInitialState() {
    return {
      reports: this.reports,
    };
  },

  _errorHandler(error) {
    let errorMessage;

    try {
      errorMessage = error.additional.body.message;
    } catch (e) {
      errorMessage = error.message;
    }

    return errorMessage;
  },

  _adaptReportToRequest(report: Report, reportLogo: string, parameterValues: ParameterValues) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id, parameterValues: _,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      parameters,
      positions = [],
      widgets,
      layout,
      hideWidgetDescription,
      hideWidgetQuery,
      ...rest
    } = report;

    // We omit `parameters` on purpose, since they are injected from the server when serving the response.
    return {
      logo: reportLogo,
      widgets: widgets.map(({ dashboard_id, dashboard_widget_id }) => ({ dashboard_id, dashboard_widget_id })),
      parameter_values: this._adaptParameterValuesToBindings(parameterValues),
      positions,
      layout: layout ? { format: layout.format, page_size: layout.pageSize, orientation: layout.orientation } : null,
      hide_widget_description: hideWidgetDescription,
      hide_widget_query: hideWidgetQuery,
      ...rest,
    };
  },

  _adaptParameterValuesToBindings(parameterValues: ParameterValues): BackendReportParameterValues {
    return Object.entries(parameterValues)
      .map<[string, ParameterBinding]>(([name, value]) => [name, ParameterBinding.create('value', value)])
      .reduce((prev, [name, binding]) => ({ ...prev, [name]: binding }), {});
  },

  create(report: ReportCreate, reportLogo: string, parameterValues: ParameterValues) {
    const promise = fetch('POST', reportingUrl(), this._adaptReportToRequest(report, reportLogo, parameterValues));

    promise.then(
      (response) => {
        UserNotification.success('Report created successfully', `Report "${response.title}" was created successfully.`);

        return response;
      },
      (error) => {
        UserNotification.error(`Creating report "${report.title}" failed with status: ${error}`,
          'Could not save report');
      },
    );

    ReportsActions.create.promise(promise);
  },

  get(reportId: string) {
    const promise = fetch('GET', reportingUrl(reportId))
      .then((response: BackendReport) => deserializeBackendReport(response));

    ReportsActions.get.promise(promise);
  },

  getSummary(reportId: string) {
    const promise = fetch('GET', `${reportingUrl(reportId)}/summary`)
      .then((response: BackendReport) => deserializeBackendReport(response));

    ReportsActions.getSummary.promise(promise);
  },

  getReportLogo(reportId: string) {
    const promise = fetch('GET', reportingUrl(`${reportId}/logo`));

    ReportsActions.getReportLogo.promise(promise);
  },

  update(updatedReport: Report, updatedReportLogo: string, updatedParameterValues: ParameterValues) {
    const promise = fetch('PUT', reportingUrl(updatedReport.id), this._adaptReportToRequest(updatedReport, updatedReportLogo, updatedParameterValues));

    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" was updated successfully.`, 'Report updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Updating Report "${updatedReport.title}" contents failed with status: ${error}.`,
          'Report contents could not be updated');
      },
    );

    ReportsActions.update.promise(promise);
  },

  updateDelivery(reportId: string, updatedDelivery: ReportDelivery) {
    const promise = fetch('PUT', reportingUrl(`${reportId}/delivery`), {
      delivery: updatedDelivery,
    });

    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" delivery was updated successfully.`,
          'Report delivery updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Updating report ${reportId} delivery failed with status: ${error}.`,
          'Report delivery could not be updated');
      },
    );

    ReportsActions.updateDelivery.promise(promise);
  },

  updatePositions(reportId: string, updatedPositions: Array<ReportWidgetPosition>) {
    const promise = fetch('PUT', reportingUrl(`${reportId}/positions`), {
      positions: updatedPositions,
    }).then(
      (response) => {
        UserNotification.success(undefined, 'Report layout updated successfully');

        return deserializeBackendReport(response);
      },
      (error) => {
        UserNotification.error(`Updating Report ${reportId} layout failed with status: ${error}.`,
          'Report layout could not be updated');
      },
    );

    ReportsActions.updatePositions.promise(promise);
  },

  delete(reportId: string) {
    const promise = fetch('DELETE', reportingUrl(`${reportId}`));

    ReportsActions.delete.promise(promise);
  },

  sendEmail(reportId: string) {
    const promise = fetch('GET', reportingUrl(`${reportId}/email/`));

    ReportsActions.sendEmail.promise(promise);
  },

  getHistory(reportId: string, reportTitle: string, skip: number, limit: number) {
    const url = new URI(reportingUrl(`${reportId}/history/`));
    const queryParams = {
      skip: skip,
      limit: limit,
    };
    url.query(queryParams);
    const promise = fetch('GET', url.toString()).then(
      (response) => response,
      (error) => {
        UserNotification.error(`Getting Report "${reportTitle}" history failed with status: ${error}.`,
          'Could not get Report history');
      },
    );

    ReportsActions.getHistory.promise(promise);
  },

});

export { ReportsActions };
export default ReportsStore;
