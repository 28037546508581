import * as React from 'react';

import type View from 'views/logic/views/View';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { PaginationProvider, SelectedRowsProvider } from 'security-app/components/common/contexts';
import NewInvestigation from 'security-app/components/Investigations/NewInvestigation';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';

import SearchRow from './Search';
import List from './List';

type Props = {
  type: 'logs' | 'dashboards' | 'searches' | 'events',
  id: string,
  index?: string,
  searchMeta?: {
    searchTitle: string,
    isSearchRelative: boolean,
    saveAsAbsoluteSearch: (newTitle: string) => Promise<View>,
  },
  show?: boolean,
  onClose?: () => void,
};

const modalTitleByType = {
  logs: 'Add log',
  dashboards: 'Add dashboard',
  searches: 'Add search',
  events: 'Add event',
};

const AddEvidenceModal = React.forwardRef(({ type, id, index, searchMeta, show, onClose }: Props, ref: React.MutableRefObject<ModalHandler>) => {
  const newModalRef = React.useRef<ModalHandler>(null);
  const listRef = React.useRef<ModalHandler>(null);
  const [showModal, setShowModal] = React.useState(show);

  React.useLayoutEffect(() => {
    if (listRef && showModal) {
      const modalDialog = document.querySelector('.modal-dialog') as HTMLElement;
      modalDialog.style.width = '700px';
    }
  }, [listRef, showModal]);

  const coloseModal = () => {
    listRef.current.onCancel();
    setShowModal(false);
    if (onClose) onClose();
  };

  const onConfirm = () => {
    listRef.current.onConfirm();
    if (onClose) onClose();
  };

  React.useImperativeHandle(ref, () => ({
    toggle: () => setShowModal(true),
  }));

  return (
    <SelectedRowsProvider<InvestigationAPIType>>
      <BootstrapModalConfirm showModal={showModal}
                             onCancel={coloseModal}
                             onConfirm={onConfirm}
                             title={`Investigations - ${modalTitleByType[type]} as evidence`}>
        <PaginationProvider>
          <SearchRow onNewInvestigation={() => setShowModal(false)} newModalRef={newModalRef} />
          <List type={type}
                id={id}
                index={index}
                setShowModal={setShowModal}
                searchMeta={searchMeta}
                ref={listRef} />
        </PaginationProvider>
      </BootstrapModalConfirm>
      <NewInvestigation ref={newModalRef} onClose={() => setShowModal(true)} />
    </SelectedRowsProvider>
  );
});

AddEvidenceModal.defaultProps = {
  index: undefined,
  searchMeta: undefined,
  show: false,
  onClose: undefined,
};

export default AddEvidenceModal;
