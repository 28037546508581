import * as React from 'react';
import { Field } from 'formik';

import { Select } from 'components/common';
import { PAGE_SIZES, DEFAULT_SIZE } from 'report/Constants';
import ControlLabel from 'components/bootstrap/ControlLabel';
import Input from 'components/bootstrap/Input';

type Props = {
  disabled: boolean;
};
const PageSizeSelect = ({ disabled }: Props) => (
  <Field name="layout.pageSize">
    {({ field: { name, value, onChange }, meta: { error } }) => (
      <Input id={name} error={error}>
        <ControlLabel>Size</ControlLabel>
        <Select disabled={disabled}
                onChange={(newValue: string) => onChange({ target: { name, value: newValue } })}
                value={value ?? DEFAULT_SIZE}
                inputId={name}
                placeholder="Pick a page size"
                clearable={false}
                options={PAGE_SIZES} />
      </Input>
    )}
  </Field>
);
export default PageSizeSelect;
