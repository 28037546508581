import { useQuery } from '@tanstack/react-query';

import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { LicenseMetrics } from 'license/types';
import type FetchError from 'logic/errors/FetchError';

export const LICENSE_METRICS_QUERY_KEY = 'license_metrics';

const getMetrics = () => fetch(
  'GET',
  qualifyUrl(EnterpriseApiRoutes.License.metrics().url),
);

const useLicenseMetrics = (): {
  data: LicenseMetrics,
  isLoading: boolean,
  isFetching: boolean,
  isError: boolean,
  refetch: () => void
} => {
  const { data, isFetching, isLoading, isError, refetch } = useQuery<LicenseMetrics, FetchError>([LICENSE_METRICS_QUERY_KEY], getMetrics, {
    onError: (errorThrown) => {
      if (!(errorThrown.status === 404)) {
        UserNotification.error(`Loading license metrics failed with: ${errorThrown}`);
      }
    },
  });

  return ({
    data: data,
    isFetching,
    isLoading,
    isError,
    refetch,
  });
};

export default useLicenseMetrics;
