import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import trim from 'lodash/trim';

import { Col, Row, Button, ButtonToolbar } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import ReportContents from 'report/report-history-page/ReportContents';
import { LinkContainer } from 'components/common/router';
import ReportRoutes from 'report/ReportRoutes';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import useParams from 'routing/useParams';
import Icon from 'components/common/Icon';
import useReportSummary from 'report/report-history-page/useReportSummary';

import ReportHistoryList from './ReportHistoryList';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const ReportHistorySubNav = ({ reportId }: { reportId: string }) => (
  <ButtonToolbar>
    <LinkContainer to={ReportRoutes.EDIT.overview(reportId)}>
      <Button bsStyle="success">Edit configuration</Button>
    </LinkContainer>
  </ButtonToolbar>
);

const ReportHistoryPage = () => {
  const { reportId } = useParams();
  const { data: report, isInitialLoading } = useReportSummary(reportId, true);

  const description = useMemo(() => ((!report?.description || trim(report.description) === '')
    ? <i>No further description available.</i>
    : <span>{report.description}</span>), [report?.description]);

  if (isInitialLoading || !report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  const pageTitle = (
    <HeaderContainer>
      <LinkContainer to={ReportRoutes.OVERVIEW}>
        <Button bsSize="small"><Icon name="arrow_back" /> Back</Button>
      </LinkContainer>
      <h1>{report.title}</h1>
    </HeaderContainer>
  );

  return (
    <DocumentTitle title={`${report.title} Archive`}>
      <PageHeader title={pageTitle}
                  actions={<ReportHistorySubNav reportId={report.id} />}
                  documentationLink={{
                    title: 'Reporting documentation',
                    path: DocsHelper.PAGES.REPORTING,
                  }}>
        {description}
      </PageHeader>

      <Row className="content">
        <Col md={12}>
          <ReportContents report={report} />
        </Col>
        <Col md={12}>
          <ReportHistoryList id={report.id} />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

const ReportHistoryPageWrapper = () => (
  <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report}
                bsStyle="danger"
                displayWarningContainer
                hideChildren>
    <ReportHistoryPage />
  </LicenseCheck>
);

export default ReportHistoryPageWrapper;
