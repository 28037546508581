import * as React from 'react';
import { useRef } from 'react';
import { styled, css } from 'styled-components';

import { PAGE_PRINT_MARGIN_PX, PAGE_SIZES_MAP, DEFAULT_SIZE, DEFAULT_ORIENTATION } from 'report/Constants';
import useElementDimensions from 'hooks/useElementDimensions';
import type { Orientation } from 'search/ExportWidgetAction/types';
import type { ReportLayout } from 'report/types';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import usePaperDimensions from 'report/report-creation/common/usePaperDimensions';

export const PREVIEW_PADDING = 40;

const Container = styled.div<{ $orientation: Orientation }>(({ $orientation }) => css`
  width: ${$orientation === 'portrait' ? 60 : 85}%;
  margin: ${PREVIEW_PADDING}px 0;
  height: fit-content;
`);

const FixedDimensions = styled.div<{ $width: number, $height: number, $layoutMultiplier: number }>(({ $width, $height, $layoutMultiplier }) => css`
  overflow: hidden;
  position: relative;
  width: ${$width}px;
  height: ${$height + (PAGE_PRINT_MARGIN_PX * $layoutMultiplier) * 2}px;
`);

const Page = styled.div<{ $pageScaleFactor: number, $width: number, $minHeight: number, $layoutMultiplier: number }>(({ $pageScaleFactor, $width, $minHeight, $layoutMultiplier }) => css`
  // The top padding is calculated differently in the actual PDF, because the header is not part of the page content. 
  padding: ${PAGE_PRINT_MARGIN_PX}px ${PAGE_PRINT_MARGIN_PX * $layoutMultiplier}px;
  width: ${$width}px;
  transform: scale(${$pageScaleFactor});
  transform-origin: top left;
  min-height: ${$minHeight}px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
`);

const Header = styled.div<{ $layoutMultiplier: number }>(({ $layoutMultiplier }) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${15 * $layoutMultiplier}px;
`);

const Footer = styled(Header)<{ $layoutMultiplier: number }>(({ $layoutMultiplier }) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Arial;
  gap: ${15 * $layoutMultiplier}px;
  background-color: rgba(255, 255, 255, 0.9);
`);

const Logo = styled.div<{ $layoutMultiplier: number }>(({ $layoutMultiplier }) => css`
  min-width: ${130 * $layoutMultiplier}px;

  img {
    max-height: ${130 * $layoutMultiplier}px;
    max-width: ${130 * $layoutMultiplier}px;
  }
`);

const PageNumbers = styled.div<{ $layoutMultiplier: number }>(({ $layoutMultiplier }) => css`
  min-width: ${130 * $layoutMultiplier}px;
`);

type Props = {
  children: (props: { contentWidth: number, pageScaleFactor: number }) => React.ReactNode,
  pageSize: ReportLayout['pageSize'] | undefined,
  pageOrientation: Orientation | undefined,
  report: ReportFormValues,
}

const PDFPage = ({ children, pageSize: pageSizeProp, pageOrientation: pageOrientationProp, report }: Props) => {
  const pageSize = pageSizeProp ?? DEFAULT_SIZE;
  const pageOrientation = pageOrientationProp ?? DEFAULT_ORIENTATION;
  const containerRef = useRef(null);
  const pageRef = useRef(null);
  const { width: containerWidth } = useElementDimensions(containerRef);
  const paperSize = usePaperDimensions(pageSize, pageOrientation);
  const scaledPageDimensions = useElementDimensions(pageRef);
  const pageScaleFactor = containerWidth / paperSize.width;
  const previewLayoutMultiplier = PAGE_SIZES_MAP[pageSize].previewLayoutMultiplier[pageOrientation];

  return (
    <Container ref={containerRef} $orientation={pageOrientation}>
      <FixedDimensions $width={paperSize.width * pageScaleFactor}
                       $height={scaledPageDimensions.height * pageScaleFactor}
                       $layoutMultiplier={previewLayoutMultiplier}>
        <Page $pageScaleFactor={pageScaleFactor}
              $width={paperSize.width}
              $minHeight={paperSize.height}
              $layoutMultiplier={previewLayoutMultiplier}
              ref={pageRef}>
          {/* For the actual PDF the header and footer will be added in the backend */}
          {report.layout?.printHeader && (
            <Header $layoutMultiplier={previewLayoutMultiplier}>
              {report.logo && (
                <Logo $layoutMultiplier={previewLayoutMultiplier}>
                  <img src={report.logo} alt="Report logo" />
                </Logo>
              )}
              {report.layout?.header}
            </Header>
          )}
          {children({ contentWidth: paperSize.width - PAGE_PRINT_MARGIN_PX * 2, pageScaleFactor })}
          {report.layout?.printFooter && (
            <Footer $layoutMultiplier={previewLayoutMultiplier}>
              {report.layout?.printPageNumbers && <PageNumbers $layoutMultiplier={previewLayoutMultiplier}>Page <i>current</i> of <i>total</i></PageNumbers>}
              {report.layout?.footer}
            </Footer>
          )}
        </Page>
      </FixedDimensions>
    </Container>
  );
};

export default PDFPage;
