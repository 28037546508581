import * as React from 'react';

import { ReadOnlyFormGroup } from 'components/common';

type Props = {
  datawareHouseEnabled: boolean;
}

const DataWarehouseStatus = ({ datawareHouseEnabled }: Props) => (
  <ReadOnlyFormGroup label="Enabled" value={datawareHouseEnabled || false} />
);

export default DataWarehouseStatus;
