import { useQuery, useMutation } from '@tanstack/react-query';

import type { PacksIndexAPIType } from 'illuminate/types';
import { fetchPacks, updatePacks } from 'illuminate/hooks/api/packs';
import UserNotification from 'util/UserNotification';

type PaginatedProps = {
  page: number,
  perPage: number,
  query?: string,
  includeDeps?: boolean
};

export function useGetPacks({
  page,
  perPage,
  query,
  includeDeps,
}: PaginatedProps, bundleVersion: string) {
  const { isLoading, isInitialLoading, data, isError, error } = useQuery<PacksIndexAPIType, Error>(
    ['illuminate-bundle-packs', page, perPage, query, bundleVersion, includeDeps],
    () => fetchPacks(bundleVersion, page, perPage, query, includeDeps),
    {
      enabled: !!bundleVersion,
      retry: 1,
    },
  );

  if (isError && error) UserNotification.error(error.message);

  const packs = data?.packs || [];

  return {
    packs: packs,
    loadingPacks: isLoading,
    isInitialLoading,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useUpdatePacks() {
  const { mutateAsync, isLoading } = useMutation(updatePacks);

  return { updatePacks: mutateAsync, updatingPacks: isLoading };
}
