import React, { createContext, useContext, useState } from 'react';

const ImportPaginationContext = createContext(null);
const ImportPaginationUpdateContext = createContext(null);

type Props = {
  children: React.ReactNode;
};

export function ImportBackendsPaginationProvider({ children }: Props) {
  const [localImportPagination, setLocalImportPagination] = useState({
    page: 1,
    perPage: 10000,
    query: null,
    orderBy: null,
    direction: null,
    filters: {},
  });

  return (
    <ImportPaginationContext.Provider value={localImportPagination}>
      <ImportPaginationUpdateContext.Provider value={setLocalImportPagination}>
        {children}
      </ImportPaginationUpdateContext.Provider>
    </ImportPaginationContext.Provider>
  );
}

export function useImportBackendsPagination() {
  return useContext(ImportPaginationContext);
}

export function useSetImportBackendsPagination() {
  return useContext(ImportPaginationUpdateContext);
}
