import * as React from 'react';

import { Label } from 'components/bootstrap';

const DataWarehouseBackendState = ({ isActiveBackend }: { isActiveBackend: boolean }) => {
  if (!isActiveBackend) {
    return null;
  }

  return (
    <Label bsStyle="success" bsSize="xsmall">Active</Label>
  );
};

export default DataWarehouseBackendState;
