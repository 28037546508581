import * as React from 'react';

import { TH } from 'security-app/components/common';

function ListHeader() {
  return (
    <>
      <colgroup>
        <col style={{ width: 32 }} />
        <col style={{ width: '32%' }} />
        <col style={{ width: '32%' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <TH />
          <TH>Title</TH>
          <TH>Description</TH>
          <TH>Type</TH>
        </tr>
      </thead>
    </>
  );
}

export default ListHeader;
