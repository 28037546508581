import { useQuery } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import type { ExportData, ExportDataResponse } from 'export/types';

const url = qualifyUrl('plugins/org.graylog.plugins.widgetexport/widgetexport');
export const fetchExportData = (exportCallId: string): Promise<ExportData> => fetch<ExportDataResponse>('GET', `${url}/${exportCallId}`)
  .then(({ widget, data }): ExportData => ({
    data,
    widget: {
      widgetType: widget.widget_type,
      renderType: widget.render_type,
    },
  }));

const useExportData = (exportCallId: string): {
  data: ExportData,
  refetch: () => void,
  isFetching: boolean,
} => {
  const { data, refetch, isFetching } = useQuery<ExportData>(
    ['exported-data', exportCallId],
    () => fetchExportData(exportCallId),
    {
      onError: (error) => {
        UserNotification.error(`Fetching data failed with status: ${error}`, 'Could not retrieve data');
      },
      keepPreviousData: true,
      enabled: !!exportCallId,
    },
  );

  return ({
    data,
    refetch,
    isFetching,
  });
};

export default useExportData;
