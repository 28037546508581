import CoreSigmaModal from './CoreSigmaModal';

export default {
  'eventDefinitions.components.editSigmaModal': [
    {
      component: CoreSigmaModal,
      key: 'coreSigmaModal',
    },
  ],
};
