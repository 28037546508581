import React from 'react';
// eslint-disable-next-line no-restricted-imports
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';

import QueryHelper from 'components/common/QueryHelper';
import { Alert, DropdownButton, MenuItem } from 'components/bootstrap';
import { PaginatedList, SearchForm } from 'components/common';
import withPaginationQueryParameter from 'components/common/withPaginationQueryParameter';
import AuditLogEntriesTable from 'auditlog/AuditLogEntriesTable';
import AuditLogActions from 'auditlog/AuditLogActions';
import AuditLogStore from 'auditlog/AuditLogStore';
import style from 'auditlog/AuditLogEntriesStyle.lazy.css';

const PAGE_SIZES = [20, 50, 100, 200, 500];

const queryExamples = (
  <>
    <p>
      Find audit log messages created after the given date:<br />
      <code>{'timestamp:>=2017-03-23'}</code><br />
    </p>
    <p>
      Find audit log messages where the action is <tt>create</tt> or <tt>delete</tt>:<br />
      <code>action:create action:delete</code><br />
      <code>action:create,delete</code>
    </p>
  </>
);

const fieldMap = {
  actor: 'User that triggered the audit event',
  namespace: 'Namespace of the audit event; might be different in plugins',
  object: 'Object of the audit event; what has been changed',
  action: 'Name of the action that has been executed on the object',
  success_status: 'If the action failed or succeeded',
  message: 'The actual audit event message',
  timestamp: 'Timestamp of the audit event',
};

const queryHelpComponent = (
  <QueryHelper entityName="audit event" example={queryExamples} commonFields={[]} fieldMap={fieldMap} />
);

const AuditLogEntries = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  displayName: 'AuditLogEntries',
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    paginationQueryParameter: PropTypes.object.isRequired,
  },
  mixins: [Reflux.connect(AuditLogStore)],

  getInitialState() {
    return {
      paginatedEntries: undefined,
    };
  },

  componentDidMount() {
    const { page, pageSize } = this.props.paginationQueryParameter;
    AuditLogActions.entriesPaginated(page, pageSize);
    style.use();
  },

  componentWillUnmount() {
    style.unuse();
  },

  _onPageChange(newPage, pageSize) {
    if (this.state.paginatedEntries && this.state.paginatedEntries.query) {
      AuditLogActions.searchPaginated(newPage, pageSize, this.state.paginatedEntries.query);
    } else {
      AuditLogActions.entriesPaginated(newPage, pageSize);
    }
  },

  _onSearch(query, cb) {
    const { resetPage, pageSize } = this.props.paginationQueryParameter;
    resetPage();
    AuditLogActions.searchPaginated(1, pageSize, query).then(() => cb());
  },

  _onReset() {
    const { resetPage, pageSize } = this.props.paginationQueryParameter;
    resetPage();
    AuditLogActions.entriesPaginated(1, pageSize);
  },

  render() {
    const { paginatedEntries } = this.state;

    if (!paginatedEntries) {
      return (
        <Alert bsStyle="info">
          Loading audit log entries...
        </Alert>
      );
    }

    return (
      <div className="auditlog-entries">
        <h2>
          Audit Log Entries
          <span>&nbsp;<small>{paginatedEntries.total} total</small></span>
        </h2>

        <div className="auditlog-entries-content">
          <PaginatedList totalItems={paginatedEntries.total}
                         pageSizes={PAGE_SIZES}
                         onChange={this._onPageChange}>
            <SearchForm onSearch={this._onSearch}
                        onReset={this._onReset}
                        queryWidth={500}
                        useLoadingState
                        queryHelpComponent={queryHelpComponent}>
              <DropdownButton id="export-entries-dropdown" title="Export Results" style={{ marginLeft: 5 }}>
                <MenuItem href={AuditLogStore.getExportURL('json', this.state.paginatedEntries.query)} target="_blank">JSON</MenuItem>
                <MenuItem href={AuditLogStore.getExportURL('csv', this.state.paginatedEntries.query)} target="_blank">CSV</MenuItem>
              </DropdownButton>
            </SearchForm>
            <AuditLogEntriesTable entries={paginatedEntries.entries} />
          </PaginatedList>
        </div>
      </div>
    );
  },
});

export default withPaginationQueryParameter(AuditLogEntries, { pageSizes: PAGE_SIZES });
