import React, { useEffect } from 'react';

import ArchivePageHeaderDescription from 'archive/components/ArchivePageHeaderDescription';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import ArchiveCreationSection from 'archive/components/ArchiveCreationSection';
import ArchiveSystemJobs from 'archive/components/ArchiveSystemJobs';
import DocsHelper from 'util/DocsHelper';
import { IndicesActions, IndicesStore } from 'stores/indices/IndicesStore';
import type { Indices } from 'stores/indices/IndicesStore';
import { useStore } from 'stores/connect';
import { DocumentTitle, ExternalLinkButton, PageHeader, IfPermitted } from 'components/common';
import { Button, Row, Col } from 'components/bootstrap';

import ArchivePageNavigation from './components/ArchivePageNavigation';

type IndicesStoreState = {
  indices: Indices,
};

const ArchivePage = () => {
  useEffect(() => {
    IndicesActions.listAll();
  }, []);

  const indices = useStore(IndicesStore, (indicesStore: IndicesStoreState) => indicesStore.indices);

  return (
    <DocumentTitle title="Archives">
      <ArchivePageNavigation />
      <PageHeader title="Archives">
        <ArchivePageHeaderDescription />
      </PageHeader>
      <ArchiveCreationSection indices={indices} />
      <ArchiveSystemJobs />
      <Row className="content">
        <Col md={12}>
          <div className="pull-right">
            <IfPermitted permissions="archivecatalog:rebuild">
              <Button bsStyle="info" onClick={() => { ArchiveActions.rebuildCatalog(); }}>Rebuild Catalog</Button>
                &nbsp;
            </IfPermitted>
            <ExternalLinkButton bsStyle="info" href={DocsHelper.toString('archiving')}>
              Archive documentation
            </ExternalLinkButton>
          </div>
          <ArchiveCatalog />
        </Col>
      </Row>

    </DocumentTitle>
  );
};

export default ArchivePage;
