import * as React from 'react';

import ActionDropdown from 'views/components/common/ActionDropdown';
import { IconButton, Spinner } from 'components/common';

const AggregationWidgetExportDropdown = ({ children, isExporting }: React.PropsWithChildren & { isExporting: boolean }) => {
  if (isExporting) return <Spinner text="" />;
  const widgetActionDropdownCaret = <IconButton name="download" title="Export widget" />;

  return (
    <ActionDropdown element={widgetActionDropdownCaret} header="Export to file">
      {children}
    </ActionDropdown>
  );
};

export default AggregationWidgetExportDropdown;
