import * as React from 'react';

import { Icon } from 'components/common';

import {
  ImportErrorsBox,
  ClearErrors,
  ImportError,
} from './ImportRepoRulesModal.styles';
import type { ImportErrorsType } from './ImportRepoRulesModal';

type Props = {
  importErrors: ImportErrorsType[],
  setImportErrors: (errors: ImportErrorsType[]) => void,
};

const ErrorMessages = ({ importErrors, setImportErrors }: Props) => importErrors.length > 0 && (
<ImportErrorsBox bsStyle="danger">
  <ClearErrors data-testid="clear-errors" onClick={() => setImportErrors([])}>
    <Icon style={{ cursor: 'pointer' }}
          name="close"
          title="Clear errors" />
  </ClearErrors>
  {importErrors.map((err: ImportErrorsType) => (
    <ImportError key={err.ruleId}><b>{err.ruleId}:</b><br />
      <div className="message">{err.message}</div>
    </ImportError>
  ))}
</ImportErrorsBox>
);

export default ErrorMessages;
