import React, { useCallback } from 'react';

import PageHeader from 'components/common/PageHeader';
import DocumentTitle from 'components/common/DocumentTitle';
import UserNotification from 'util/UserNotification';
import SectionGrid from 'components/common/Section/SectionGrid';
import SectionComponent from 'components/common/Section/SectionComponent';
import useHeaderBadge, { useUpdateHeaderBadge } from 'customization/header-badge/useHeaderBadge';
import Spinner from 'components/common/Spinner';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';

import type { BadgeConfig } from './header-badge/HeaderBadgeConfiguration';
import HeaderBadgeConfiguration from './header-badge/HeaderBadgeConfiguration';
import ThemeEditor from './theme-editor/ThemeEditor';
import PublicNotifications from './public-notifications/PublicNotifications';

const CustomizationPage = () => {
  const { data: badgeConfig, isInitialLoading } = useHeaderBadge();
  const { mutateAsync: _updateHeaderConfiguration } = useUpdateHeaderBadge();
  const updateHeaderConfiguration = useCallback((config: BadgeConfig) => _updateHeaderConfiguration(config).then(() => {
    UserNotification.success('Successfully updated.');
  }), [_updateHeaderConfiguration]);

  if (isInitialLoading) {
    return <Spinner />;
  }

  return (
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.customization}
                  title="Customization is disabled"
                  text="Customization is disabled because there is no valid Enterprise license."
                  bsStyle="danger"
                  displayWarningContainer
                  hideChildren>
      <DocumentTitle title="Customization">
        <div>
          <PageHeader title="Customization">
            <span>
              Here you can customize your Graylog experience.
            </span>
          </PageHeader>

          <SectionGrid>
            <div>
              <SectionComponent title="Modify Global Theme">
                <p>Any changes made & saved will affect all users.</p>
                <ThemeEditor />
              </SectionComponent>
            </div>

            <div>
              <SectionComponent title="Header Badge">
                <HeaderBadgeConfiguration config={badgeConfig} updateConfig={updateHeaderConfiguration} />
              </SectionComponent>

              <PublicNotifications />
            </div>
          </SectionGrid>
        </div>
      </DocumentTitle>
    </LicenseCheck>
  );
};

export default CustomizationPage;
