import { useQuery } from '@tanstack/react-query';

import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type GlobalOverride from 'views/logic/search/GlobalOverride';
import type { WidgetValueSummary } from 'report/types';
import fetch from 'logic/rest/FetchProvider';
import URLUtils from 'util/URLUtils';
import { migrateWidgetValueResult } from 'report/report-render-page/fetchReportValues';

const sourceUrl = '/plugins/org.graylog.plugins.report';

const fetchReportingWidgetValue = (
  dashboardId: string,
  widgetId: string,
  parameterBindings: { [key: string]: ParameterBinding } = {},
  globalOverride: GlobalOverride | undefined | {} = {},
): Promise<WidgetValueSummary> => {
  const request = {
    parameter_bindings: Object.entries(parameterBindings)
      .map(([key, value]): [string, ParameterBinding] => [key, ParameterBinding.create('value', value)])
      .reduce((prev, [key, binding]) => ({ ...prev, [key]: binding }), {}),
    global_override: globalOverride,
  };

  return fetch('POST', URLUtils.qualifyUrl(`${sourceUrl}/widgets/${dashboardId}/${widgetId}/value`), request).then((result) => migrateWidgetValueResult(result));
};

type Props = {
  dashboardId: string,
  widgetId: string,
  parameterValues?: { [key: string]: any },
  globalOverride?: GlobalOverride | undefined | {},
  enabled?: boolean
  staleTime?: number
};

const useWidgetResult = ({ dashboardId, widgetId, parameterValues, globalOverride, enabled = true, staleTime = 0 }: Props) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['report.widget.preview', dashboardId, widgetId, parameterValues, globalOverride],
    queryFn: () => fetchReportingWidgetValue(dashboardId, widgetId, parameterValues, globalOverride),
    enabled,
    staleTime,
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export default useWidgetResult;
