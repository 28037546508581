import * as React from 'react';

import { ColumnFilter, TH } from 'security-app/components/common';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import type { OwnerOptionType } from 'security-app/hooks/api/securityEventsAPI.types';

const columnsWithFilter = ['type'];

type Props = {
  ownerOptions: OwnerOptionType[],
  setCurrentOptions: (options: OwnerOptionType[]) => void,
  filters: ColumnFilterData,
  setFilters: (filters: ColumnFilterData) => void,
};

function ListHeader({ ownerOptions, setCurrentOptions, filters, setFilters }: Props) {
  const filtersByColumn = React.useMemo(() => ownerOptions.reduce((acc: ColumnFilterData, owner: OwnerOptionType) => {
    Object.entries(owner).filter(([key, _]) => columnsWithFilter.includes(key)).forEach(([key, value]) => {
      if (value == null) return;
      if (!acc[key]) acc[key] = [];
      const labelValue = value.toString();
      const filterValue = value.toString();
      const valueType = typeof value;

      if (!acc[key].find((filter: FilterData) => filter.value.toString() === filterValue)) {
        acc[key].push({ label: labelValue, value: filterValue, type: valueType });
      }
    });

    return acc;
  }, {} as ColumnFilterData), [ownerOptions]);

  const applyFilters = (filterKey: string, filterValues: FilterData[]) => {
    const auxFilters = { ...filters };
    if (!filterValues || filterValues.length === 0) delete auxFilters[filterKey];
    else auxFilters[filterKey] = filterValues;

    setFilters(auxFilters);

    if (Object.keys(auxFilters).length) {
      setCurrentOptions(
        ownerOptions.filter((option: OwnerOptionType) => (
          filterValues.map(({ value }) => value).includes(option[filterKey])
        )),
      );
    } else {
      setCurrentOptions(ownerOptions);
    }
  };

  return (
    <>
      <colgroup>
        <col style={{ width: 32 }} />
        <col style={{ width: '70%' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <TH />
          <TH>Name</TH>
          <TH>
            <ColumnFilter filterBy="type"
                          type="text"
                          filterData={filtersByColumn?.type}
                          appliedFilters={filters}
                          onClose={applyFilters}>
              Type
            </ColumnFilter>
          </TH>
        </tr>
      </thead>
    </>
  );
}

export default ListHeader;
