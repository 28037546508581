import * as React from 'react';
import { Field } from 'formik';

import { Select } from 'components/common';
import ControlLabel from 'components/bootstrap/ControlLabel';
import Input from 'components/bootstrap/Input';
import { DEFAULT_ORIENTATION } from 'report/Constants';

const ORIENTATION_OPTIONS = [
  { label: 'Portrait', value: 'portrait' },
  { label: 'Landscape', value: 'landscape' },
];
type Props = {
  disabled: boolean;
};
const PageSizeSelect = ({ disabled }: Props) => (
  <Field name="layout.orientation">
    {({ field: { name, value, onChange }, meta: { error } }) => (
      <Input id={name} error={error}>
        <ControlLabel>Orientation</ControlLabel>
        <Select disabled={disabled}
                onChange={(newValue: string) => onChange({ target: { name, value: newValue } })}
                value={value ?? DEFAULT_ORIENTATION}
                inputId={name}
                placeholder="Pick an orientation"
                clearable={false}
                options={ORIENTATION_OPTIONS} />
      </Input>
    )}
  </Field>
);
export default PageSizeSelect;
