import React, { useCallback, useMemo, useState } from 'react';

import AggregationWidgetExportDropdown from 'search/ExportWidgetAction/AggregationWidgetExportDropdown';
import { MenuItem } from 'components/bootstrap';
import useWidgetResults from 'views/components/useWidgetResults';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import useView from 'views/hooks/useView';
import type { WidgetMenuActionComponentProps } from 'views/components/widgets/Types';
import useSearchExecutionState from 'views/hooks/useSearchExecutionState';
import usePluginEntities from 'hooks/usePluginEntities';
import { defaultCompare } from 'logic/DefaultCompare';
import downloadByExportStrategy from 'search/ExportWidgetAction/downloadByExportStrategy';
import type { Result } from 'search/ExportWidgetAction/util/WidgetExportUtils';
import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentQuery from 'views/logic/queries/useCurrentQuery';

const ExportOverviewWidgetsActionComponent = ({ widget, disabled }: WidgetMenuActionComponentProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const { error: errors, widgetData: widgetResult } = useWidgetResults(widget.id);
  const view = useView();
  const showExportAggregationWidgetAction = widgetResult && !errors?.length;
  const executionState = useSearchExecutionState();
  const sendTelemetry = useSendTelemetry();
  const currentQuery = useCurrentQuery();
  const currentUser = useCurrentUser();
  const { pathname } = useLocation();
  const exportFormats = usePluginEntities('views.export.formats');

  const exportFormatOptions = useMemo(() => exportFormats.sort((type1, type2) => defaultCompare(type1?.displayName, type2?.displayName))
    .map(({ type, displayName, disabled: disabledFn = () => false }) => {
      const isDisabled = disabledFn();
      const title = displayName();

      return { title, id: type, disabled: isDisabled };
    }), [exportFormats]);
  const onExportWidget = useCallback((extension: string) => {
    setIsExporting(true);

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_WIDGET_ACTION.EXPORT, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'search-widget',
      app_action_value: { extension },
    });

    return downloadByExportStrategy({
      widget,
      widgetResult: widgetResult as Result,
      currentUser,
      extension,
      view,
      currentQuery,
      executionState,
    }).finally(() => setIsExporting(false));
  }, [sendTelemetry, pathname, widget, widgetResult, currentUser, view, currentQuery, executionState]);

  return (showExportAggregationWidgetAction && (
  <AggregationWidgetExportDropdown isExporting={isExporting}>
    {
      exportFormatOptions.map(({ id: extension, title: extensionTitle, disabled: optionDisabled }) => (
        <MenuItem disabled={disabled || optionDisabled} key={extension} onSelect={() => onExportWidget(extension)}>
          {extensionTitle}
        </MenuItem>
      ))
        }
  </AggregationWidgetExportDropdown>
  )
  );
};

export default ExportOverviewWidgetsActionComponent;
