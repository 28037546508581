import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedSymantecEDRApp from './EmbeddedSymantecEDRApp';

const SymantecEDRApp = () => (
  <>
    <PageHeader title="Symantec EDR Integrations">
      <span>This feature retrieves log records from Symantec EDR Platform.</span>
    </PageHeader>
    <EmbeddedSymantecEDRApp />
  </>
);

export default SymantecEDRApp;
