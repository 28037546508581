import React from 'react';
import PropTypes from 'prop-types';

import { LinkContainer } from 'components/common/router';
import { OverlayTrigger } from 'components/common';
import { Button } from 'components/bootstrap';
import Routes from 'routing/Routes';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';

type Props ={
  buttonTitle: string,
}

const CreateForwarderButton = ({ buttonTitle } : Props) => {
  const { data: { valid: isValidLicense }, isInitialLoading } = useLicenseValidityForSubject(LICENSE_SUBJECTS.forwarder);

  if (isInitialLoading) {
    return null;
  }

  return (
    isValidLicense ? (
      <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_NEW')}>
        <Button bsStyle="success">{buttonTitle}</Button>
      </LinkContainer>
    ) : (
      <OverlayTrigger rootClose
                      overlay={<>Creating new forwarder is disabled because there is no valid license.</>}
                      placement="top">
        <Button bsStyle="success" className="disabled">{buttonTitle}</Button>
      </OverlayTrigger>
    ));
};

CreateForwarderButton.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
};

export default CreateForwarderButton;
