import { useState, useEffect } from 'react';

import type { Report } from 'report/types';
import { ReportsActions } from 'report/ReportsStore';

type State = {
  report: Report | undefined,
  reportLogo: string | undefined,
  error: Error | undefined,
}

const useLoadReportAndLogo = (reportId: string, setState: (state: State) => void) => {
  useEffect(() => {
    Promise.all([
      ReportsActions.get(reportId),
      ReportsActions.getReportLogo(reportId),
    ]).then(([reportResponse, { logo }]) => setState({ report: reportResponse, reportLogo: logo, error: undefined }),
    ).catch(
      (error) => setState({
        error,
        report: undefined,
        reportLogo: undefined,
      }),
    );
  }, [reportId, setState]);
};

const useHandlePositionsUpdate = (reportId: string, setState: (fn: (prevState: State) => State) => void) => {
  useEffect(() => ReportsActions.updatePositions.completed.listen((updatedReport: Report) => {
    if (reportId === updatedReport.id) {
      setState((prevState) => ({ ...prevState, report: updatedReport }));
    }
  }), [setState, reportId]);
};

const useReportAndLogo = (reportId: string) => {
  const [{ report, reportLogo, error }, setState] = useState<State>({
    report: undefined,
    reportLogo: undefined,
    error: undefined,
  });

  useLoadReportAndLogo(reportId, setState);
  useHandlePositionsUpdate(reportId, setState);

  return { report, reportLogo, error };
};

export default useReportAndLogo;
