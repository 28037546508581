import * as React from 'react';
import styled from 'styled-components';

const RenderError = styled.div`
  white-space: break-spaces;
`;
type Props = {
  error: Error
}
const ExportErrorPage = ({ error }: Props) => (
  <RenderError>
    {error.stack}
  </RenderError>
);

export default ExportErrorPage;
