import DeleteFailedSnapshotMenuItem from './DeleteFailedSnapshotMenuItem';
import TiersConfigurationFields from './TiersConfigurationFields';
import TiersSummary from './TiersSummary';
import WarmTierReadinessInfo from './WarmTierReadinessInfo';

export default {
  dataTiering: {
    type: 'hot_warm',
    TiersConfigurationFields,
    TiersSummary,
    WarmTierReadinessInfo,
    DeleteFailedSnapshotMenuItem,
  },
};
