import * as React from 'react';

import { Col, ControlLabel, FormGroup } from 'components/bootstrap';
import TypeSpecificParameterInput from 'enterprise/parameters/components/TypeSpecificParameterInput';
import type { ParameterInputComponentProps } from 'enterprise/parameters/components/ParameterTypes';

const ParameterInput = ({ parameter, value, onChange, searchId, onBlur }: ParameterInputComponentProps) => {
  const { name, title } = parameter;

  return (
    <FormGroup key={`parameter-${name}`} controlId={`parameter-${name}`}>
      <Col componentClass={ControlLabel} sm={3}>
        {title}
      </Col>
      <Col sm={9}>
        <TypeSpecificParameterInput parameter={parameter} value={value} onChange={onChange} onBlur={onBlur} searchId={searchId} />
      </Col>
    </FormGroup>
  );
};

export default ParameterInput;
