import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import SalesforceRoutes from './common/Routes';

const SalesforceInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = SalesforceRoutes.INTEGRATIONS.Salesforce.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default SalesforceInputConfiguration;
