import * as React from 'react';

import AutoRefreshContext from 'views/components/contexts/AutoRefreshContext';

const emptyFn = () => {};
const refreshDisabled = {
  refreshConfig: { enabled: false, interval: 0 },
  startAutoRefresh: emptyFn,
  stopAutoRefresh: emptyFn,
};

const AutoRefreshDisabledProvider = ({ children }: React.PropsWithChildren) => (
  <AutoRefreshContext.Provider value={refreshDisabled}>{children}</AutoRefreshContext.Provider>
);

export default AutoRefreshDisabledProvider;
