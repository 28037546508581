import { useMutation } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

const getUrlPrefix = (version: string): string => `/plugins/org.graylog.plugins.securityapp.investigations/investigations/summary/consents/${version}`;
const acceptTerms = (version: string) => fetch(
  'PUT',
  qualifyUrl(getUrlPrefix(version)),
);

const useAcceptanceMutation = (onConsent: () => void) => {
  const { mutateAsync } = useMutation(acceptTerms, {
    onSuccess: onConsent,
  });

  return mutateAsync;
};

export default useAcceptanceMutation;
