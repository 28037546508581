import * as React from 'react';
import PropTypes from 'prop-types';
import type { ColorVariant } from '@graylog/sawmill';

import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { IfPermitted, Spinner } from 'components/common';
import { Alert, Col, Row } from 'components/bootstrap';
import AppConfig from 'util/AppConfig';
import type { LicenseSubject } from 'license/types';
import { LICENSE_TYPES, LICENSE_SUBJECTS } from 'license/constants';

import useLicenseValidityForSubject from './hooks/useLicenseValidityForSubject';

const isCloud = AppConfig.isCloud();

const warningDefaultProps = {
  title: undefined,
  text: undefined,
  licenseSubject: LICENSE_SUBJECTS.enterprise,
  bsStyle: 'info',
};

type WarningProps = {
  licenseSubject?: LicenseSubject,
  featureName: string,
  title?: string,
  text?: string,
  bsStyle?: ColorVariant,
}

export const Warning = ({ title, text, bsStyle, licenseSubject, featureName }: WarningProps) => {
  const licenseType = LICENSE_TYPES[licenseSubject];

  return (
    <Alert bsStyle={bsStyle} title={title ?? `${licenseType} license required`}>
      <p>{text ?? `A valid ${licenseType} license is required to use ${featureName ?? 'this'} functionality.`}</p>
      {isCloud
        ? (<>Contact your Graylog account manager.</>)
        : (
          <IfPermitted permissions="licenses:create">
            <p>
              See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
            </p>
          </IfPermitted>
        )}
    </Alert>
  );
};

Warning.defaultProps = warningDefaultProps;

type InvalidLicenseWarningProps = WarningProps & {
  displayWarningContainer: boolean,
};

export const InvalidLicenseWarning = ({ licenseSubject, featureName, displayWarningContainer, title, text, bsStyle }: InvalidLicenseWarningProps) => {
  if (displayWarningContainer) {
    return (
      <Row className="content">
        <Col md={12}>
          <Warning licenseSubject={licenseSubject}
                   featureName={featureName}
                   title={title}
                   text={text}
                   bsStyle={bsStyle} />
        </Col>
      </Row>
    );
  }

  return (
    <Warning licenseSubject={licenseSubject}
             featureName={featureName}
             title={title}
             text={text}
             bsStyle={bsStyle} />
  );
};

InvalidLicenseWarning.defaultProps = warningDefaultProps;

/**
 * Component which provides a `licenseIsValid` prop. E.g. to display a read only version of its children.
 */
type Props = InvalidLicenseWarningProps & {
  children?: React.ReactNode | ((props: { licenseIsValid: boolean }) => React.ReactNode),
  displayLicenseWarning: boolean,
  hideChildren: boolean,
};

const LicenseCheck = ({ children, featureName, licenseSubject, displayLicenseWarning, displayWarningContainer, hideChildren, title, text, bsStyle }: Props) => {
  const { data: { valid }, isInitialLoading: isLoading } = useLicenseValidityForSubject(licenseSubject);
  const resultChildren = typeof children === 'function' ? children({ licenseIsValid: valid }) : children;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {(!valid && displayLicenseWarning) && (
        <InvalidLicenseWarning licenseSubject={licenseSubject}
                               featureName={featureName}
                               displayWarningContainer={displayWarningContainer}
                               title={title}
                               text={text}
                               bsStyle={bsStyle} />
      )}
      {valid && resultChildren}
      {!valid && !hideChildren && resultChildren}
    </>
  );
};

LicenseCheck.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  displayLicenseWarning: PropTypes.bool,
  hideChildren: PropTypes.bool,
  featureName: PropTypes.string,
  licenseSubject: PropTypes.string,
  displayWarningContainer: PropTypes.bool,
};

LicenseCheck.defaultProps = {
  ...warningDefaultProps,
  children: null,
  displayLicenseWarning: true,
  featureName: undefined,
  licenseSubject: '/license/enterprise',
  displayWarningContainer: false,
  hideChildren: false,
  title: undefined,
  text: undefined,
  bsStyle: 'info',
};

export default LicenseCheck;
