import * as React from 'react';
import styled from 'styled-components';

import { ProgressBar } from 'components/common';
import { Button, Col, Row } from 'components/bootstrap';
import { Modal } from 'security-app/components/common';
import { useUploadSigmaRules } from 'security-app/hooks/useSigmaAPI';

import { getBodyPayload } from './fileFormLogic';
import type { FileForm } from './fileFormLogic';
import FileList from './FileList';
import RuleSettingsForm from './RuleSettingsForm';

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FlexRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 0;

  div[role="progressbar"] {
    white-space: nowrap;
  }
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
`;

type Props = {
  show: boolean,
  onClose: () => void,
};

function UploadRulesModal({ show, onClose }: Props) {
  const [files, setFiles] = React.useState<FileForm[]>([]);
  const [selectedFile, selectFile] = React.useState<FileForm | null>();
  const [uploadProgress, setUploadProgress] = React.useState<number | null>();

  const { uploadSigmaRules, uploadingRules } = useUploadSigmaRules();

  React.useEffect(() => {
    if (!files.find((fileForm: FileForm) => fileForm.id === selectedFile?.id)) {
      if (files.length > 0) selectFile(files[0]);
      else selectFile(null);
    }
  }, [files, selectedFile]);

  const onCancel = React.useCallback(() => {
    setFiles([]);
    selectFile(null);
    onClose();
  }, [onClose]);

  const reportProgress = React.useCallback((percent: number) => {
    setUploadProgress(percent);
  }, []);

  const uploadFiles = React.useCallback(async () => {
    const bodyPayload = getBodyPayload(files);

    await uploadSigmaRules({
      fileForms: files,
      body: bodyPayload,
      progressCB: reportProgress,
    });

    const badFiles = files.filter((fileForm: FileForm) => fileForm.ruleErrors.length > 0);

    if (badFiles.length > 0) {
      setFiles(badFiles);
      selectFile(badFiles[0]);
      setUploadProgress(null);

      return;
    }

    onClose();
  }, [onClose, files, uploadSigmaRules, reportProgress]);

  const canSubmit = React.useMemo(() => (
    files.length > 0
    && files.every((fileForm: FileForm) => (
      Object.keys(fileForm).filter((key: string) => !['id', 'file', 'ruleErrors'].includes(key)).every((field: string) => (
        fileForm[field].valid
      ))
    ))
  ), [files]);

  const Buttons = React.useMemo(() => (
    <>
      <Button bsStyle="default" onClick={onCancel}>Cancel</Button>
      <Button bsStyle="success" onClick={uploadFiles} disabled={!canSubmit || uploadingRules}>Upload rules</Button>
    </>
  ), [onCancel, uploadFiles, canSubmit, uploadingRules]);

  return (
    <Modal show={show}
           onClose={onClose}
           title="Upload Sigma Rules"
           maxWidth="900px"
           buttons={Buttons}>
      <FlexRow>
        <Col md={5}>
          <FileList files={files}
                    setFiles={setFiles}
                    selectedFile={selectedFile}
                    selectFile={selectFile} />
        </Col>
        <FlexCol md={7}>
          {selectedFile && (
            <RuleSettingsForm selectedFile={selectedFile} selectFile={selectFile} setFiles={setFiles} />
          )}
          {uploadProgress && (
            <StyledProgressBar bars={[
              { value: uploadProgress, bsStyle: 'info', animated: true, label: `Uploading ${uploadProgress}%` },
            ]} />
          )}
        </FlexCol>
        {uploadProgress && <ProgressOverlay />}
      </FlexRow>
    </Modal>
  );
}

export default UploadRulesModal;
