import { createGlobalStyle, css } from 'styled-components';

import type { Orientation } from 'search/ExportWidgetAction/types';
import { PAGE_PRINT_MARGIN } from 'report/Constants';

const pdfExportTableStyles = ({ $orientation }: { $orientation: Orientation }) => css`
  .table-data-row .table-data-cell,
  .fields-row.message-group td {
    min-width: ${$orientation === 'portrait' ? '50px' : '75px'}!important;
    max-width: ${$orientation === 'portrait' ? undefined : '700px'}!important;
  }
`;

const GlobalPDFStyles = createGlobalStyle<{
  $orientation: Orientation,
  $rootSelector?: string
}>(({
  $orientation,
  $rootSelector = 'html body',
}) => css`
  ${$rootSelector} {
    margin-top: 0;
    font-size: 18px;
    ${pdfExportTableStyles({ $orientation })}   
  }
  
  @media print {
    @page {
      margin: ${PAGE_PRINT_MARGIN};
    }
  }
`);

export default GlobalPDFStyles;
