import * as React from 'react';
import styled, { css } from 'styled-components';
import { useState, useCallback, useMemo } from 'react';

import type { CoverageEntity } from 'security-app/components/Welcome/ThreatCoverageWidget/types';
import { Table, Button } from 'components/bootstrap';
import { ProgressBar, SortIcon } from 'components/common';
import { isFullyCovered } from 'security-app/components/Welcome/ThreatCoverageWidget/Utils';
import ImproveCoverageButton from 'security-app/components/Welcome/ThreatCoverageWidget/ImproveCoverageButton';
import { defaultCompare } from 'logic/DefaultCompare';

const Container = styled.div`
  flex: 1;
  max-height: 320px;
  min-width: 400px;
  display: flex;
  flex-direction: column;

  @media print {
    max-height: none;
  }
`;

const Headline = styled.h3(({ theme }) => css`
  margin-bottom: ${theme.spacings.sm};
`);

const TableContainer = styled.div`
  overflow: auto;

  @media print { {
    overflow: visible;   
  }
`;

const Thead = styled.thead(({ theme }) => css`
  background-color: ${theme.colors.global.contentBackground};
  position: sticky;
  top: 0;
  z-index: 1;
`);

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

const ProgressCell = styled.div`
  display: flex;
`;

const StyledProgressBar = styled(ProgressBar)`
  flex: 1;
  margin: 0;
`;

const CoveragePercentage = styled.div`
  width: 45px;
  text-align: right;
  margin-right: 5px;
`;

type Props = {
  tactics: Array<CoverageEntity>,
  onSelectTactic?: (tacticId: string) => void,
}

const TacticsList = ({ tactics, onSelectTactic }: Props) => {
  const [coverageSortDirection, setCoverageSortDirection] = useState<'Ascending' | 'Descending'>('Ascending');

  const sortedTactics = useMemo(() => tactics?.sort(
    ({ name: name1, coverage: coverage1 }, { name: name2, coverage: coverage2 }) => {
      if (coverage1 !== coverage2) {
        if (coverageSortDirection === 'Ascending') {
          return coverage1 - coverage2;
        }

        return coverage2 - coverage1;
      }

      return defaultCompare(name1, name2);
    }) ?? [], [coverageSortDirection, tactics]);

  const toggleCoverageSort = useCallback(() => {
    setCoverageSortDirection((cur) => (cur === 'Ascending' ? 'Descending' : 'Ascending'));
  }, []);

  return (
    <Container>
      <Headline>Coverage Details</Headline>
      <TableContainer>
        <Table striped>
          <Thead>
            <tr>
              <th>
                <Header>Tactic Name</Header>
              </th>
              <th style={{ width: '160px', textAlign: 'center' }}>
                <Header>Technique Count</Header>
              </th>
              <th style={{ width: '160px' }}>
                <Header>Coverage <SortIcon activeDirection={coverageSortDirection} onChange={toggleCoverageSort} title={`Sort coverage ${coverageSortDirection === 'Ascending' ? 'descending' : 'ascending'}`} /></Header>
              </th>
            </tr>
          </Thead>
          <tbody>
            {sortedTactics.map((tactic) => {
              const { name, coverage, id, numChildren, numFullyCoveredChildren } = tactic;

              return (
                <tr key={id}>
                  <td><Button bsStyle="link" onClick={() => onSelectTactic(tactic.id)}>{name}</Button></td>
                  <td style={{ textAlign: 'center' }}>{numFullyCoveredChildren}/{numChildren}</td>
                  <td>
                    <ProgressCell>
                      <StyledProgressBar bars={[{ value: coverage, bsStyle: isFullyCovered(coverage) ? 'success' : 'warning' }]} />
                      <CoveragePercentage>{coverage}%</CoveragePercentage>
                      <ImproveCoverageButton coverageEntity={tactic} isTactic />
                    </ProgressCell>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

TacticsList.defaultProps = {
  onSelectTactic: () => {},
};

export default TacticsList;
