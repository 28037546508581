import * as React from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import { Icon, Spinner } from 'components/common';

const StyledInput = styled.div`
  width: 100%;

  & .form-group {
    margin-bottom: 0 !important;
  }

  & .input-group * {
    background: none !important;
  }

  & .input-group input {
    padding: 6px 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  & * {
    border: none;
  }
`;

type Props = {
  query: string;
  searching: boolean;
  onSearch: (query: string) => void;
};

function SearchRule({ query, searching, onSearch }: Props) {
  const [localQuery, setLocalQuery] = React.useState(query);
  const [debouncedSearch, setDebouncedSeach] = React.useState(null);

  React.useEffect(() => setLocalQuery(query), [query]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLocalQuery(value);

    if (debouncedSearch) clearTimeout(debouncedSearch);

    setDebouncedSeach(setTimeout(() => onSearch(value), 800));
  };

  return (
    <StyledInput>
      <Input id="search-rule"
             data-testid="search-rule"
             type="text"
             name="search-rule"
             value={localQuery}
             onChange={handleOnChange}
             placeholder="Search rules by title, file name, product, or tags ..."
             addonAfter={searching ? <Spinner text="" /> : <Icon name="search" />} />
    </StyledInput>
  );
}

export default SearchRule;
