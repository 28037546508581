import LicensesPage from 'license/LicensesPage';
import LicenseNotificationContainer from 'license/notifications/LicenseNotificationContainer';
import LicenseGraphWithMetrics from 'license/LicenseGraphWithMetrics';
import EnterpriseTrafficGraph from 'license/EnterpriseTrafficGraph';
import LicenseCheckProvider from 'license/LicenseCheckProvider';
import EnterpriseProductLink from 'license/EnterpriseProductLink';

export default {
  license: {
    EnterpriseTrafficGraph,
    EnterpriseProductLink,
    LicenseGraphWithMetrics,
  },
  routes: [
    { path: '/system/licenses', component: LicensesPage },
  ],
  globalNotifications: [
    {
      key: 'org.graylog.plugins.license.LicenseNotificationContainer',
      component: LicenseNotificationContainer,
    },
  ],
  globalContextProviders: [
    LicenseCheckProvider,
  ],
};
