import * as React from 'react';

import type { Stream } from 'stores/streams/StreamsStore';
import type { ColumnRenderer } from 'components/common/EntityDataTable/types';

import DataWareHouseCell from './components/DataWarehouseCell';

const dataWarehousecustomColumnRenderer: {datawarehouse: ColumnRenderer<Stream>} = {
  datawarehouse: {
    renderCell: (_datawarehouse: boolean, stream: Stream) => <DataWareHouseCell stream={stream} />,
    staticWidth: 130,
  },
};

export default dataWarehousecustomColumnRenderer;
