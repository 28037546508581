import * as React from 'react';
import { useParams } from 'react-router-dom';

import ReportForm from 'report/report-creation/ReportForm';
import Spinner from 'components/common/Spinner';
import useReport from 'report/report-history-page/useReport';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import { DocumentTitle } from 'components/common';
import ReportRoutes from 'report/ReportRoutes';

const EditReportPage = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const { data: report, isFetching: isFetchingReport } = useReport(reportId);

  if (isFetchingReport || !report) {
    return <Spinner />;
  }

  return (
    <ReportForm action="edit"
                report={report}
                ignoredWindowLeaveRoutes={[
                  ReportRoutes.EDIT.overview(report.id),
                  ReportRoutes.EDIT.content(report.id),
                  ReportRoutes.EDIT.delivery(report.id),
                  ReportRoutes.EDIT.style(report.id),
                ]} />
  );
};

const EditReportPageWrapper = () => (
  <DocumentTitle title="Edit Report">
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report}
                  bsStyle="danger"
                  displayWarningContainer
                  hideChildren>
      <EditReportPage />
    </LicenseCheck>
  </DocumentTitle>
);

export default EditReportPageWrapper;
