import * as React from 'react';

import { Badge } from 'components/bootstrap';

function RiskScoreBadge({ riskScore }: { riskScore: number }) {
  const getRiskScoreStyle = () => {
    if (riskScore < 25) return 'default';
    if (riskScore < 50) return 'info';
    if (riskScore < 75) return 'warning';

    return 'danger';
  };

  return (
    <Badge bsStyle={getRiskScoreStyle()}>{riskScore.toFixed(1)}</Badge>
  );
}

export default RiskScoreBadge;
