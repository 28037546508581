import * as React from 'react';
import type { Reducer } from 'react';

const SelectedRowsContext = React.createContext(null);
const SelectedRowsDispatchContext = React.createContext(null);

export type SelectedRowsAction<T> =
  | { type: 'add', payload: T[] }
  | { type: 'remove', payload: T[] }
  | { type: 'clear' };

type BaseStateType = {
  pack_id?: string,
};

function selectedRowsReducer<T extends BaseStateType>(state: T[], action: SelectedRowsAction<T>) {
  switch (action.type) {
    case 'add':
      return [
        ...state,
        ...action.payload.filter((payloadRow: T) => (
          !state.find((stateRow: T) => stateRow.pack_id === payloadRow.pack_id)
        )),
      ];
    case 'remove':
      return state.filter((stateRow: T) => (
        !action.payload.find((payloadRow: T) => stateRow.pack_id === payloadRow.pack_id)
      ));
    case 'clear':
      return [];
    default:
      return state;
  }
}

type ProviderProps = {
  children: React.ReactNode;
};

export function SelectedRowsProvider<T extends BaseStateType>({ children }: ProviderProps) {
  const [state, dispatch] = React.useReducer<Reducer<T[], SelectedRowsAction<T>>>(selectedRowsReducer, []);

  return (
    <SelectedRowsContext.Provider value={state}>
      <SelectedRowsDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedRowsDispatchContext.Provider>
    </SelectedRowsContext.Provider>
  );
}

export function useSelectedRows() {
  return React.useContext(SelectedRowsContext);
}

export function useSelectedRowsDispatch() {
  return React.useContext(SelectedRowsDispatchContext);
}
