import * as React from 'react';

import ReportForm from 'report/report-creation/ReportForm';
import useUserDateTime from 'hooks/useUserDateTime';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';
import { DocumentTitle } from 'components/common';
import { DEFAULT_ORIENTATION, DEFAULT_SIZE } from 'report/Constants';
import ReportRoutes from 'report/ReportRoutes';

const CreateReportPage = () => {
  const { userTimezone } = useUserDateTime();
  const initialReport = {
    title: 'New Report',
    subtitle: '',
    description: '',
    timezone: userTimezone,
    layout: {
      orientation: DEFAULT_ORIENTATION,
      pageSize: DEFAULT_SIZE,
      printToc: false,
    },
    widgets: [],
    positions: [],
    hideWidgetQuery: false,
    hideWidgetDescription: false,
    delivery: {
      active: false,
      email_body: '',
      email_receivers: [],
      email_subject: '',
      scheduling: [],
      user_receivers: [],
    },
    parameterValues: {},
  };

  return (
    <DocumentTitle title="Create Report">
      <LicenseCheck licenseSubject={LICENSE_SUBJECTS.report}
                    bsStyle="danger"
                    displayWarningContainer
                    hideChildren>
        <ReportForm action="create"
                    report={initialReport}
                    ignoredWindowLeaveRoutes={[
                      ReportRoutes.NEW,
                      ReportRoutes.CREATE.CONTENT,
                      ReportRoutes.CREATE.DELIVERY,
                      ReportRoutes.CREATE.STYLE,
                    ]} />
      </LicenseCheck>
    </DocumentTitle>
  );
};

export default CreateReportPage;
