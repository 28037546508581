import * as React from 'react';
import styled, { css } from 'styled-components';
import { useContext } from 'react';

import type { WidgetRef } from 'report/types';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import { widgetTitle } from 'report/report-render-page/ReportWidgets';
import { reportWidgetHeaderId } from 'report/Constants';
import WidgetTitle from 'report/common/WidgetTitle';

const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TocLi = styled.li(({ theme }) => css`
  &:not(:last-child) {
    margin-bottom: 6px;
  }

  a {
    width: 100%;
    text-decoration: none;
    color: ${theme.colors.text.primary};
    display: block;
  }
`);

const TocHeader = styled.h3(({ theme }) => css`
  color: ${theme.colors.text.primary};
`);

type Props = {
  handleTocClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
  widgets: Array<WidgetRef>;
}

const Toc = ({ widgets, handleTocClick }: Props) => {
  const { widgets: widgetDetails, isLoading } = useContext(WidgetDetailsContext);

  return (
    <Container>
      <span>
        <TocHeader className="has-bm">Table of Contents</TocHeader>
        <ol>
          {widgets.map((widget) => {
            const completeWidget = widgetDetails[widget.widgetId];

            return (
              <TocLi>
                <a href={`#${reportWidgetHeaderId(widget.dashboardId, widget.widgetId)}`} onClick={handleTocClick}>
                  <WidgetTitle title={widgetTitle(widget, completeWidget)} isLoading={isLoading} detailsAreAvailable={!isLoading && !!completeWidget} />
                </a>
              </TocLi>
            );
          })}
        </ol>
      </span>
    </Container>
  );
};

Toc.defaultProps = {
  handleTocClick: undefined,
};

export default Toc;
