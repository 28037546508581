import React from 'react';
import PropTypes from 'prop-types';

import type { LicenseViolations, LicenseVersion } from 'license/types';
import LicenseState from 'license/LicenseState';

type Props = {
  violations: LicenseViolations,
  version?: LicenseVersion
}

const StateCell = ({ violations, version }: Props) => (
  <LicenseState violations={violations} version={version} />
);

StateCell.propTypes = {
  violations: PropTypes.shape({
    cluster_not_covered: PropTypes.bool.isRequired,
    nodes_exceeded: PropTypes.bool.isRequired,
    traffic_exceeded: PropTypes.bool.isRequired,
    remote_checks_failed: PropTypes.bool.isRequired,
    expired: PropTypes.bool.isRequired,
    violated: PropTypes.bool.isRequired,
  }).isRequired,
  version: PropTypes.number,
};

StateCell.defaultProps = {
  version: undefined,
};

export default StateCell;
