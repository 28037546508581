import * as React from 'react';
import * as Immutable from 'immutable';

import { DocumentTitle, Spinner } from 'components/common';
import type { Backend } from 'logic/authentication/okta/types';
import useRoles from 'common/hooks/useRoles';

import ConfigSection from './components/ConfigSection';
import GroupSyncSection from './components/GroupSyncSection';

interface BackendConfigProps {
  authenticationBackend: Backend;
}

const BackendConfig = ({ authenticationBackend }: BackendConfigProps) => {
  const { isLoading, roles } = useRoles();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Okta Authentication Services">
      <ConfigSection authenticationBackend={authenticationBackend} />
      <GroupSyncSection authenticationBackend={authenticationBackend} roles={Immutable.List(roles)} />
    </DocumentTitle>
  );
};

export default BackendConfig;
