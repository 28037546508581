import ExportPage from 'export/ExportPage';

const bindings = {
  routes: [
    {
      path: 'widgetexport/:exportCallId/simple_list_render',
      component: ExportPage,
      parentComponent: null,
    },
  ],
};

export default bindings;
