import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import CrowdStrikeRoutes from './common/Routes';

const CrowdStrikeInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const indexUrl = CrowdStrikeRoutes.INTEGRATIONS.CrowdStrike.ACTIVITYAPI.index;
    history.push(indexUrl);
  }, [history]);

  return null;
};

export default CrowdStrikeInputConfiguration;
