import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import type { Report } from 'report/types';
import Alert from 'components/bootstrap/Alert';
import { Description } from 'report/common/Description';

const ParametersWarning = styled(Alert)`
  margin-bottom: 30px;
`;

const ParametersTable = styled.div`
  font-size: 0.75em;
  min-width: 50%;
  margin: 0 auto 30px auto;

  td, th {
    border: 1px #ccc solid;
    padding: 5px;
  }
`;

const getParameterValue = (parameterValues: Report['parameterValues'], name: string, defaultValue: any) => (isNil(parameterValues[name]) ? defaultValue : parameterValues[name]);

type Props = {
  parameters: Array<{
    default_value: any,
    optional: boolean,
    needsBinding: boolean,
    name: string
  }>,
  parameterValues: Report['parameterValues']
}

const ReportParameters = ({ parameters, parameterValues } : Props) => {
  if (isEmpty(parameters)) {
    return null;
  }

  const formattedParameters = Object.entries(parameters)
    .map(([name, { default_value: defaultValue }]) => {
      const value = getParameterValue(parameterValues, name, defaultValue);

      return <tr key={name}><td>${name}$</td><td>{value || <em>Not set</em>}</td></tr>;
    });

  const parametersWithMissingValue = Object.values(parameters)
    .filter((p) => !p.optional && p.needsBinding)
    .filter((({ name, default_value: defaultValue }) => !getParameterValue(parameterValues, name, defaultValue)))
    .map(({ name }) => name);

  return (
    <>
      {parametersWithMissingValue.length > 0 && (
        <ParametersWarning bsStyle="danger" title="Attention: Missing parameter value">
          <p>
            This report contains parameters without any value. Widgets which include these parameters will not display a result.
            Please add a default value on the related dashboard or add a value on the report edit page for the following parameters:
          </p>
          <b>{parametersWithMissingValue.join(', ')}</b>
        </ParametersWarning>
      )}
      <Description>This report uses the following parameters and values:</Description>
      <ParametersTable>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {formattedParameters}
        </tbody>
      </ParametersTable>
    </>
  );
};

export default ReportParameters;
