import * as React from 'react';
import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';

import { contentSuffix, deliverySuffix, styleSuffix } from 'report/ReportRoutes';
import Delivery from 'report/report-creation/Delivery';

import Overview from './overview';
import Style from './Style';
import Content from './content';

const reportCreationRoutes = [
  { path: '', component: <Overview /> },
  { path: contentSuffix, component: <Content /> },
  { path: deliverySuffix, component: <Delivery /> },
  { path: styleSuffix, component: <Style /> },
];

const ReportCreationRouter = () => {
  const routes = useMemo(() => reportCreationRoutes.map(({ path, component }) => (
    <Route key={path}
           path={path}
           element={component} />
  )), []);

  return (
    <Routes>
      {routes}
    </Routes>
  );
};

export default ReportCreationRouter;
