import * as React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import ParameterInput from 'enterprise/parameters/components/ParameterInput';
import type ParameterType from 'views/logic/parameters/Parameter';
import Input from 'components/bootstrap/Input';
import newParameterState from 'enterprise/parameters/logic/newParameterState';
import { Alert } from 'components/bootstrap';

const ParameterInputRow = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  .form-group {
    display: flex;
    align-items: baseline;
  }
`;

const Parameter = ({ parameter, searchId }: { parameter: ParameterType, searchId: string }) => (
  <Field key={`parameter-field-${parameter.name}`} name={`parameterValues.${parameter.name}`}>
    {({ field: { name, value, onBlur }, form: { setFieldValue }, meta: { error } }) => (
      <Input id={`parameter-${name}`} error={error}>
        <ParameterInputRow key={name}>
          <ParameterInput parameter={parameter}
                          searchId={searchId}
                          value={value}
                          onBlur={onBlur}
                          onChange={(_field, newValue, triggerValidation) => setFieldValue(name, newValue, triggerValidation)} />
        </ParameterInputRow>
      </Input>
    )}
  </Field>
);

type Props = {
  parameters: { [parameterName: string]: Array<ParameterType> },
  parameterSearchIds: { [key: string]: string | undefined },
};

const ReportParameters = ({ parameters, parameterSearchIds }: Props) => (
  <dl>
    <dd>
      {Object.values(parameters).map((paramDefinitions) => {
        if (paramDefinitions.length > 1 && !paramDefinitions.every((definition) => isEqual(definition, paramDefinitions[0]))) {
          const genericParam = newParameterState(paramDefinitions[0].name);
          const parameterTitles = uniq(paramDefinitions.map(({ title }) => title));

          return (
            <React.Fragment key={paramDefinitions[0].name}>
              <Alert bsStyle="warning">
                Different parameters with the same name <b>{paramDefinitions[0].name}</b> exist
                ({parameterTitles.join(', ')}).
                You can define a value, but you won&apos;t be able to use parameter type specific functionality like
                dropdown options.
                To avoid this you can define a different name for each parameter in the related dashboards or you make
                sure the parameters are identical.
              </Alert>
              <Parameter parameter={genericParam}
                         searchId={undefined} />
            </React.Fragment>
          );
        }

        return (
          <Parameter parameter={paramDefinitions[0]}
                     key={paramDefinitions[0].name}
                     searchId={parameterSearchIds[paramDefinitions[0].name]} />
        );
      })}
    </dd>
  </dl>
);

export default ReportParameters;
