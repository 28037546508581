import * as React from 'react';

import SigmaModal from 'security-app/components/SigmaRules/ImportRulesModal/SigmaModal';
import { useGetSigmaRule } from 'security-app/hooks/useSigmaAPI';
import { ConfirmDialog } from 'security-app/components/common/Modal';

type Props = {
  ruleId: string;
  onConfirm: () => void;
  onCancel: () => void;
};

function CoreSigmaModal({ ruleId, onConfirm, onCancel }: Props) {
  const { rule, loadingRule } = useGetSigmaRule(ruleId);

  if (!rule && !loadingRule) {
    return (
      <ConfirmDialog show
                     onConfirm={onCancel}
                     confirmText="Close"
                     noCancelButton
                     type="danger"
                     title="Edit Sigma Event Definition">
        <p>A Sigma Rule with ID: &quot;{ruleId}&quot; could not be found.</p>
      </ConfirmDialog>
    );
  }

  return loadingRule ? null : <SigmaModal rule={rule} onConfirm={onConfirm} onCancel={onCancel} />;
}

export default CoreSigmaModal;
