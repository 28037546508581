import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import GenericS3Routes from './Routes';

const GenericS3InputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = GenericS3Routes.INTEGRATIONS.GenericS3.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default GenericS3InputConfiguration;
