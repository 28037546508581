import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import SymantecSESRoutes from './Routes';

const SymantecSESInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = SymantecSESRoutes.INTEGRATIONS.SymantecSES.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default SymantecSESInputConfiguration;
