import * as React from 'react';

import AddEvidenceModal from 'security-app/components/Investigations/plugin/shared/AddEvidenceModal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import EventDefinitionCreateHOC from 'security-app/components/SecurityEvents/Alerts/AlertsModals/EventDefinitionCreateHOC';

import UsersModal from './UsersModal';
import ConfirmStatusChange from './ConfirmStatusChange';
import ConfirmOwnerRemove from './ConfirmOwnerRemove';
import NotesModal from './NotesModal';
import ReplaySearch from './ReplaySearch';
import SendNotification from './NotificationsModal/SendNotification';
import EventDefinitionHOC from './EventDefinitionHOC';
import EditSigmaEventDefinition from './EditSigmaEventDefinition';

export type ModalTypes =
  | 'ADD-EVIDENCE'
  | 'SELECT-OWNER'
  | 'CONFIRM-STATUS-CHANGE'
  | 'CONFIRM-OWNER-REMOVE'
  | 'NOTES-EDITOR'
  | 'REPLAY-SEARCH-VIEW'
  | 'SEND-NOTIFICATION'
  | 'EVENT-DEFINITION'
  | 'EVENT-DEFINITION-CREATE'
  | 'EDIT-SIGMA-EVENT-DEFINITION';

function AlertsModals() {
  const { modal, setModal, entity, setEntity } = useModalContext();

  const onAddEvidenceClose = () => {
    setModal(null);
    setEntity(null);
  };

  switch (modal) {
    case 'ADD-EVIDENCE':
      return <AddEvidenceModal show type="events" id={entity.id} onClose={onAddEvidenceClose} />;
    case 'SELECT-OWNER':
      return <UsersModal />;
    case 'CONFIRM-STATUS-CHANGE':
      return <ConfirmStatusChange />;
    case 'CONFIRM-OWNER-REMOVE':
      return <ConfirmOwnerRemove />;
    case 'NOTES-EDITOR':
      return <NotesModal />;
    case 'REPLAY-SEARCH-VIEW':
      return <ReplaySearch />;
    case 'SEND-NOTIFICATION':
      return <SendNotification />;
    case 'EVENT-DEFINITION':
      return <EventDefinitionHOC />;
    case 'EVENT-DEFINITION-CREATE':
      return <EventDefinitionCreateHOC />;
    case 'EDIT-SIGMA-EVENT-DEFINITION':
      return <EditSigmaEventDefinition />;
    default:
      return null;
  }
}

export default AlertsModals;
