import React, { createContext, useContext } from 'react';

import InvestigationDrawer from 'security-app/components/Investigations/Drawer';
import { singleton } from 'logic/singleton';

const InvestigationDrawerContext = createContext(null);

type Props = {
  children: React.ReactNode,
};

export function InvestigationDrawerProvider({ children }: Props) {
  const currentInvestigationId = localStorage.getItem('currentInvestigationId');

  const [onMount, setOnMount] = React.useState<boolean>(false);
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [selectedInvestigationId, setSelectedInvestigationId] = React.useState<string>();

  const updateInvestigationId = React.useCallback((investigationId: string) => {
    setSelectedInvestigationId(investigationId);

    if (investigationId) {
      localStorage.setItem('currentInvestigationId', investigationId);
    } else {
      localStorage.removeItem('currentInvestigationId');
    }
  }, []);

  React.useEffect(() => setOnMount(true), []);

  React.useEffect(() => {
    if (onMount || !currentInvestigationId) {
      setShowDrawer(!!currentInvestigationId);
      setCollapsed(!!currentInvestigationId);
      setSelectedInvestigationId(currentInvestigationId);
      setOnMount(false);
    }
  }, [currentInvestigationId, onMount]);

  const contextValue = React.useMemo(() => ({
    collapsed,
    setCollapsed,
    setShowDrawer,
    selectedInvestigationId,
    setSelectedInvestigationId: updateInvestigationId,
  }), [
    collapsed,
    setCollapsed,
    setShowDrawer,
    selectedInvestigationId,
    updateInvestigationId,
  ]);

  return (
    <InvestigationDrawerContext.Provider value={contextValue}>
      {showDrawer && <InvestigationDrawer />}
      {children}
    </InvestigationDrawerContext.Provider>
  );
}

export const useInvestigationDrawer = singleton('security.useInvestigationDrawer', () => () => useContext(InvestigationDrawerContext));
