import React from 'react';
import PropTypes from 'prop-types';

import ProductLink from 'components/enterprise/ProductLink';
import type { LicenseSubject } from 'license/types';
import LicenseCheck from 'license/LicenseCheck';

type Props = {
  children: React.ReactNode,
  href: string,
  clusterId: string,
  licenseSubject?: LicenseSubject,
}

const EnterpriseProductLink = ({ children, clusterId, href, licenseSubject }: Props) => (
  <LicenseCheck licenseSubject={licenseSubject} displayLicenseWarning={false} hideChildren>
    <ProductLink href={href} clusterId={clusterId}>
      {children}
    </ProductLink>
  </LicenseCheck>
);

EnterpriseProductLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  clusterId: PropTypes.string,
  licenseSubject: PropTypes.string,
};

EnterpriseProductLink.defaultProps = {
  children: null,
  href: '',
  clusterId: null,
  licenseSubject: '/license/enterprise',
};

export default EnterpriseProductLink;
