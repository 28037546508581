import * as React from 'react';
import styled from 'styled-components';

import TacticsChart from 'security-app/components/Welcome/ThreatCoverageWidget/TacticsChart';
import TacticsList from 'security-app/components/Welcome/ThreatCoverageWidget/TacticsList';
import type { CoverageEntity } from 'security-app/components/Welcome/ThreatCoverageWidget/types';
import type { ExportData } from 'export/types';
import { Timestamp } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import { Alert } from 'components/bootstrap';

export const RENDER_TYPE = 'threat_coverage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type Props = {
  data: ExportData<Array<CoverageEntity>>
}

const ThreatCoverageExportPage = ({ data }: Props) => {
  const { userTimezone } = useUserDateTime();
  const currentUser = useCurrentUser();

  if (!isPermitted(currentUser?.permissions, 'graylog_security:read')) {
    return <Alert bsStyle="danger">You are not permitted to view this page.</Alert>;
  }

  return (
    <div>
      <h1 className="has-bm">Threat Coverage</h1>
      <Container>
        <TacticsChart tactics={data.data.elements} />
        <TacticsList tactics={data.data.elements} />

        <p>
          Created at <Timestamp dateTime={new Date()} /> ({userTimezone})
        </p>
      </Container>
    </div>
  );
};

export default ThreatCoverageExportPage;
