import React from 'react';
import PropTypes from 'prop-types';

import { LICENSE_TYPES, LICENSE_TYPE, DRAWDOWN_PRODUCTS_NAMES } from 'license/constants';
import type { LicenseType } from 'license/types';

type Props = {
  products: string,
  licenseType: LicenseType,
}

const ProductsCell = ({ products, licenseType }: Props) => {
  const formatSubject = (product: string) => LICENSE_TYPES[product];

  if (licenseType === LICENSE_TYPE.drawDown) {
    return (
      <>
        {products.split(',').map((product) => {
          if (!DRAWDOWN_PRODUCTS_NAMES[product]) return null;

          return <p key={product}>{DRAWDOWN_PRODUCTS_NAMES[product]}</p>;
        })}
      </>
    );
  }

  if (licenseType === LICENSE_TYPE.dailyUsage) {
    return (<p>{formatSubject(products)}</p>);
  }

  return null;
};

ProductsCell.propTypes = {
  products: PropTypes.string.isRequired,
  licenseType: PropTypes.string.isRequired,
};

export default ProductsCell;
