import type Widget from 'views/logic/widgets/Widget';
import type { WidgetActionType } from 'views/components/widgets/Types';
import AggregationWidget from 'views/logic/aggregationbuilder/AggregationWidget';
import ExportAggregationWidgetActionComponent from 'search/ExportWidgetAction/ExportAggregationWidgetActionComponent';

const ExportAggregationWidgetAction: WidgetActionType = {
  type: 'export-aggregation-widget-action',
  position: 'menu',
  isHidden: (w: Widget) => (w.type !== AggregationWidget.type),
  component: ExportAggregationWidgetActionComponent,
};

export default ExportAggregationWidgetAction;
