import * as React from 'react';

import Routes from 'routing/Routes';
import { Button } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import { ReadOnlyFormGroup, Spinner } from 'components/common';
import SectionComponent from 'components/common/Section/SectionComponent';
import type { Backend, OidcBackendConfig, OktaBackendConfig } from 'logic/authentication/okta/types';
import useRoles from 'common/hooks/useRoles';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';

import convertToSeconds from '../helpers/convertToSeconds';
import getDisplayName from '../helpers/getDisplayName';

interface ConfigSectionProps {
  authenticationBackend: Backend;
}

const ConfigSection = (
  {
    authenticationBackend,
  }: ConfigSectionProps,
) => {
  const { id, title, description, defaultRoles, config } = authenticationBackend;
  const { callbackUrl, clientId, clientSecret, tokenVerifierConnectTimeout, type } = config;
  const isOkta = type === OKTA_TYPE_KEY;
  const baseUrl = isOkta ? (config as Omit<OktaBackendConfig, 'clientSecret'>).oktaBaseUrl : (config as Omit<OidcBackendConfig, 'clientSecret'>).baseUrl;
  const baseUrlLabel = getDisplayName(type);
  const { isLoading, roles } = useRoles();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SectionComponent title="Server Configuration"
                      headerActions={(
                        <LinkContainer to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.edit(id, 'server_config')}>
                          <Button bsStyle="success" bsSize="small">Edit</Button>
                        </LinkContainer>
                      )}>
      <ReadOnlyFormGroup label="Title" value={title} />
      <ReadOnlyFormGroup label="Description" value={description} />
      <ReadOnlyFormGroup label={`${baseUrlLabel} base URL`} value={baseUrl} />
      <ReadOnlyFormGroup label="Callback URL" value={callbackUrl} />
      <ReadOnlyFormGroup label="Client ID" value={clientId} />
      <ReadOnlyFormGroup label="Client Secret" value={clientSecret?.is_set ? '******' : null} />
      <ReadOnlyFormGroup label="Token verifier connect timeout" value={convertToSeconds(tokenVerifierConnectTimeout)} />
      <ReadOnlyFormGroup label="Default roles" value={defaultRoles.map((roleId) => roles.find((role) => role.id === roleId)?.name).join(', ')} />
    </SectionComponent>
  );
};

export default ConfigSection;
