import * as React from 'react';
import PropTypes from 'prop-types';

import { ReadOnlyFormGroup } from 'components/common';

const ScriptNotificationDetails = ({ notification }) => (
  <>
    <ReadOnlyFormGroup label="Script Path" value={notification.config?.script_path} />
    <ReadOnlyFormGroup label="Script Arguments" value={notification.config?.script_args} />
    <ReadOnlyFormGroup label="Send Alert Data Through STDIN" value={notification.config?.script_send_stdin} />
    <ReadOnlyFormGroup label="Script Timeout (milliseconds)" value={notification.config?.script_timeout} />
  </>
);

ScriptNotificationDetails.propTypes = {
  notification: PropTypes.shape({
    config: PropTypes.shape({
      script_path: PropTypes.string,
      script_args: PropTypes.string,
      script_send_stdin: PropTypes.bool,
      script_timeout: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default ScriptNotificationDetails;
