import * as React from 'react';
import { useContext, useEffect } from 'react';

import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';

const RenderTypeNotSupported = ({ renderType }: { renderType: string }) => {
  const onRenderComplete = useContext(RenderCompletionCallback);

  useEffect(() => {
    onRenderComplete();
  }, [onRenderComplete]);

  return (
    <div>
      Render type &quot;{renderType}&quot; is not supported.
    </div>
  );
};

export default RenderTypeNotSupported;
