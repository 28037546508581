import * as React from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { PluginStore } from 'graylog-web-plugin/plugin';
import type Immutable from 'immutable';

import InteractiveContext from 'views/components/contexts/InteractiveContext';
import WidgetVisualizationNotFound from 'components/widgets/WidgetVisualizationNotFound';
import { Spinner } from 'components/common';
import type Widget from 'views/logic/widgets/Widget';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

const LEGEND_HEIGHT = 55;

const Center = styled.div`
  font-size: 70px;
  text-align: center;
  vertical-align: middle;
`;

type Props = {
  errorMessage: string | undefined,
  hasError: boolean,
  interactive: boolean,
  limitHeight: boolean,
  result: any,
  types: Immutable.List<FieldTypeMapping>,
  visualizationHeight: number,
  widget: { type: Widget['type'], config: Widget['config'] },
  widgetId: string,
  width: number,
}

const setLoadingState = () => {};

const PrintingWidgetVisualization = ({
  widget, widgetId, result, hasError, errorMessage, types,
  visualizationHeight, width, interactive, limitHeight,
}: Props) => {
  const visualizationRef = useRef<HTMLDivElement>();

  if (widget.type === '') {
    return null;
  }

  if (result === undefined) {
    return (
      <Center><Spinner text="" /></Center>
    );
  }

  if (result === 'N/A') {
    return (
      <>
        <Center>{result}</Center>
        {hasError && errorMessage}
      </>
    );
  }

  const widgetPlugin = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());

  if (!widgetPlugin) {
    return <WidgetVisualizationNotFound widgetClassName={widget.type} />;
  }

  const Visualization = widgetPlugin.visualizationComponent;
  const maxHeight = (limitHeight || widgetPlugin.needsControlledHeight(widget))
    ? visualizationHeight - LEGEND_HEIGHT
    : visualizationRef.current?.offsetHeight;

  return (
    <InteractiveContext.Provider value={interactive}>
      <Visualization id={widgetId}
                     config={widget.config}
                     editing={false}
                     data={result}
                     fields={types}
                     height={maxHeight}
                     width={width}
                     setLoadingState={setLoadingState}
                     queryId="reporting-id" />
    </InteractiveContext.Provider>
  );
};

export default PrintingWidgetVisualization;
