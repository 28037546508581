import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';

import FormAdvancedOptions from './FormAdvancedOptions';

import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = (
  {
    onSubmit,
    onChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { pollingInterval } = formData;

  const availableContent = [
    { label: 'JSON Array', value: 'JSON_ARRAY' },
    { label: 'Comma Separated Values (CSV)', value: 'COMMA_SEPARATED_VALUES' },
    { label: 'Text (Newline Delimited)', value: 'TEXT_PLAIN' }];

  const availableCompressionType = [
    { label: 'GZIP', value: 'GZIP' },
    { label: 'None', value: 'NONE' }];

  const [contentTypes, setContentTypes] = useState<string>('');
  const [compressionType, setCompressionType] = useState<string>('');

  const [contentTypeVal] = useState(false);
  const [compressionTypeVal] = useState(false);

  const handleSubmit = () => {
    formData.contentTypes.value = contentTypes;
    formData.compressionType.value = compressionType;

    if (pollingInterval.value >= 1) {
      setFormError(null);
      onSubmit();
    } else {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message:
          'Minimum allowable polling interval is 1 minutes.',
      });
    }
  };

  const handleSqsMessageBatchSizeChange = (errorMessage) => {
    if (errorMessage) {
      setFormError({
        full_message: errorMessage,
        nice_message: errorMessage,
      });
    } else {
      setFormError(null); // Reset formError if no error message
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              disabled={!!formError}
              description="">
      <label className="control-label" htmlFor="contents">Content Type</label>
      <Select name="content_type"
              id="contentTypes"
              onChange={(selected:string) => setContentTypes(selected)}
              options={availableContent}
              value={contentTypes || ''}
              matchProp="label" />

      {contentTypeVal && contentTypes.length === 0 && <label htmlFor="contentTypes">Select at least one</label>}

      <span className="help-block">The media type of your log files.</span>

      <label className="control-label" htmlFor="contents">Compression Type</label>
      <Select name="compression_type"
              id="compressionType"
              onChange={(selected:string) => setCompressionType(selected)}
              options={availableCompressionType}
              value={compressionType || ''}
              matchProp="label" />
      {compressionTypeVal && compressionType.length === 0 && <label htmlFor="compressionType">Select at least one</label>}

      <span className="help-block">The format of the log files.</span>

      <Input id="pollingInterval"
             name="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue || ''}
             min="1"
             max="60"
             step="1"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new logs from S3. The smallest allowable interval is 1 minute."
             label="Polling Interval" />

      <FormAdvancedOptions onChange={onChange}
                           handleSqsMessageBatchSizeChange={handleSqsMessageBatchSizeChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
