import React, { useCallback, useContext } from 'react';

import { Col, Form, ButtonToolbar, Button, Alert, Input } from 'components/bootstrap';
import { FormikFormGroup } from 'components/common';
import { Headline } from 'components/common/Section/SectionComponent';
import { validateField, formHasErrors } from 'util/FormsUtils';
import { OidcBackendMetaContext } from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';
import SelectionTypeOptions from 'authentication/components/directoryServices/SelectionTypeOptions';
import SelectedGroupsOverview from 'authentication/components/directoryServices/SelectedGroupsOverview';
import OktaMatchingGroupsOverview from 'authentication/components/oidc/config/components/OktaMatchingGroupsOverview';
import payloadFromFormValues from 'authentication/components/oidc/config/helpers/payloadFromFormValues';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import type { ConfigFormValues } from '../types';

interface GroupSyncFormProps {
  values: ConfigFormValues;

  setFieldValue: (key: string, value: any) => void;

  isSubmitting: boolean;

  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,

  validateForm: (values?: any) => Promise<any>

  hasApiToken: boolean;

  submitAllError: React.ReactNode | null | undefined;

  goToPrevious: () => void;
}

// Form validation needs to include all input names
// to be able to associate backend validation errors with the form
export const formValidation = (synchronizeGroups: boolean, selectionType: string | null | undefined) => {
  let teamSelection;
  let oktaApiToken;

  if (synchronizeGroups) {
    if (selectionType !== 'all') {
      teamSelection = {
        required: true,
      };
    }

    oktaApiToken = {
      required: true,
    };
  }

  const validation = {
    oktaApiToken,
    teamDefaultRoles: {},
    teamSelectionType: {},
    teamSelection,
  };

  return validation;
};

const GroupSyncForm = (
  {
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    validateForm,
    hasApiToken,
    submitAllError,
    goToPrevious,
  }: GroupSyncFormProps,
) => {
  const _formValidation = useCallback((synchronizeGroups, teamSelectionType) => formValidation(synchronizeGroups, teamSelectionType), []);
  const validation = _formValidation(values.synchronizeGroups, values.teamSelectionType);
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const _onSubmitAll = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_GROUP_SYNC_SAVE_CLICKED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'oidc-groupsync-step',
      app_action_value: 'save-service',
    });

    if (values.synchronizeGroups) {
      return validateForm().then((errors) => {
        if (!formHasErrors(errors)) {
          handleSubmit();
        }
      });
    }

    return handleSubmit();
  };

  const _onGroupSelect = (groupId) => {
    const selectedGroupsIds = values.teamSelection;

    if (selectedGroupsIds?.includes(groupId)) {
      setFieldValue('teamSelection', selectedGroupsIds.remove(groupId));
    } else {
      setFieldValue('teamSelection', selectedGroupsIds.add(groupId));
    }
  };

  const { licenseIsValid } = useContext(OidcBackendMetaContext);
  const disableGroupSyncStep = !licenseIsValid;
  const disableForm = disableGroupSyncStep || !values.synchronizeGroups;

  return (
    <Col md={7}>
      <Form className="form form-horizontal">
        <div>
          <Alert bsStyle="info" title="Introduction">
            This step enables importing Okta groups as Graylog teams.
            The group synchronization can be activated by clicking on the checkbox with the label <i>Enable Group Synchronization</i>.
            Afterwards fill out the form and test your settings in the second section <i>Load matching groups</i>.
            The last section <i>Select groups to import</i> allows you to specify which groups you want to import.
          </Alert>

          <Input label="Enable Group Synchronization"
                 id="synchronize-groups-checkbox"
                 disabled={disableGroupSyncStep}
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <FormikFormGroup label="Synchronize Groups"
                             disabled={disableGroupSyncStep}
                             wrapperClassName="col-sm-12"
                             name="synchronizeGroups"
                             help="Select this option if you want to import groups as teams"
                             type="checkbox" />
          </Input>
          {(hasApiToken && values.oktaApiToken === undefined) ? (
            <Input id="oktaApiToken"
                   label="Okta API Token"
                   help="Add an Okta API token used to sync groups."
                   labelClassName="col-sm-3"
                   disabled={disableForm}
                   wrapperClassName="col-sm-9">
              <Button type="button" onClick={() => setFieldValue('oktaApiToken', '')}>
                Reset Token
              </Button>
            </Input>
          ) : (
            <FormikFormGroup buttonAfter={(hasApiToken && values.oktaApiToken !== undefined) ? (
              <Button type="button" onClick={() => setFieldValue('oktaApiToken', undefined)}>
                Undo Reset
              </Button>
            ) : undefined}
                             label="Okta API Token"
                             disabled={disableForm}
                             required
                             name="oktaApiToken"
                             placeholder="Okta API Token"
                             validate={validateField(validation.oktaApiToken)}
                             help="Your Okta API token."
                             type="password" />
          )}
          <Headline>
            Load matching groups
          </Headline>

          <OktaMatchingGroupsOverview disabled={disableForm}
                                      prepareSubmitPayload={payloadFromFormValues}
                                      onGroupSelect={_onGroupSelect} />

          <Headline>
            Select groups to import
          </Headline>

          <SelectionTypeOptions disabled={disableForm} />

          <SelectedGroupsOverview onGroupSelect={_onGroupSelect}
                                  validation={validation} />
        </div>
        {submitAllError}
        <ButtonToolbar className="pull-right">
          <Button bsStyle="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_PREVIOUS_SERVER_CONFIG_CLICKED, {
                      app_pathname: getPathnameWithoutId(pathname),
                      app_section: 'oidc-groupsync-step',
                      app_action_value: 'server-configuration-button',
                    });

                    goToPrevious();
                  }}
                  type="button">
            Previous: Server Configuration
          </Button>
          <Button disabled={isSubmitting}
                  onClick={_onSubmitAll}>
            Finish & Save Service
          </Button>
        </ButtonToolbar>
      </Form>
    </Col>
  );
};

GroupSyncForm.defaultProps = {};

export default GroupSyncForm;
