import validLicensePresent from 'license/ValidLicensePresent';
import downloadByExportStrategy from 'search/ExportWidgetAction/downloadByExportStrategy';
import type { ExportFormat } from 'views/types';

const addNotificationToTitle = (title: string) => (validLicensePresent() ? title : `${title} (Enterprise license is missing or invalid.)`);

const exportBindings: { 'views.export.formats': Array<ExportFormat> } = {
  'views.export.formats': [
    {
      type: 'gelf-ndjson',
      displayName: () => addNotificationToTitle('GELF (Newline-delimited)'),
      mimeType: 'application/gelf+x-ndjson',
      fileExtension: 'gelf-ndjson',
      disabled: () => !validLicensePresent(),
    },
    {
      type: 'json',
      displayName: () => addNotificationToTitle('JSON'),
      mimeType: 'application/json',
      fileExtension: 'json',
      disabled: () => !validLicensePresent(),
    },
    {
      type: 'ndjson',
      displayName: () => addNotificationToTitle('NDJSON (Newline-delimited JSON)'),
      mimeType: 'application/x-ndjson',
      fileExtension: 'ndjson',
      disabled: () => !validLicensePresent(),
    },
    {
      type: 'log',
      displayName: () => addNotificationToTitle('Log File/Plain Text'),
      mimeType: 'text/plain',
      fileExtension: 'log',
      disabled: () => !validLicensePresent(),
    },
    {
      order: 0,
      type: 'pdf',
      displayName: () => addNotificationToTitle('PDF'),
      mimeType: 'application/json',
      fileExtension: 'pdf',
      disabled: () => !validLicensePresent(),
      formatSpecificFileDownloader: (_, widget,
        view,
        executionState,
        currentUser,
        currentQuery,
        payload,
      ) => downloadByExportStrategy({
        widget,
        view,
        extension: 'pdf',
        widgetResult: null,
        currentUser,
        currentQuery,
        executionState,
        payload,
      }),
    },
  ],
};

export default exportBindings;
