import { useQuery } from '@tanstack/react-query';
import * as Immutable from 'immutable';

import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type {
  ExportWidgetDataResponse, ExportWidgetDataWithCachedValues,
} from 'search/ExportWidgetAction/types';

const INITIAL_DATA: ExportWidgetDataWithCachedValues = {
  widget: null,
  cachedValues: null,
};

const url = qualifyUrl('plugins/org.graylog.plugins.widgetexport/widgetexport');

export const fetchWidgetData = (exportCallId: string): Promise<ExportWidgetDataWithCachedValues> => fetch<ExportWidgetDataResponse>('GET', `${url}/${exportCallId}`)
  .then(({ created_at, widget, data }): ExportWidgetDataWithCachedValues => ({
    widget,
    cachedValues: data && {
      type: data.type,
      calculatedAt: created_at,
      result: [data.results],
      types: Immutable.List(),
      hasError: undefined,
      errorMessage: undefined,
    },
  }));

const useWidgetData = (exportCallId: string): {
  data: ExportWidgetDataWithCachedValues,
  refetch: () => void,
  isFetching: boolean,
} => {
  const { data, refetch, isFetching } = useQuery<ExportWidgetDataWithCachedValues>(
    ['exported-widget-date', exportCallId],
    () => fetchWidgetData(exportCallId),
    {
      onError: (error) => {
        UserNotification.error(`Fetching widget data failed with status: ${error}`, 'Could not retrieve widget data');
      },
      keepPreviousData: true,
      enabled: !!exportCallId,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isFetching,
  });
};

export default useWidgetData;
