import { LICENSE_SUBJECTS } from 'license/constants';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';

const useSearchFilterLicenseStatus = (): { isValidLicense: boolean, isFetchingLicense: boolean } => {
  const { data: { valid: isValidLicense }, isInitialLoading: isFetchingLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.searchFilter);

  return { isValidLicense, isFetchingLicense };
};

export default useSearchFilterLicenseStatus;
