import { qualifyUrl } from 'util/URLUtils';
import type { ReportLayout } from 'report/types';

// This timezone will be used when a report has no time zone.
export const REPORT_TZ_FALLBACK = 'UTC';

export const SOURCE_URL = '/plugins/org.graylog.plugins.report';

export const reportingUrl = (path?: string) => {
  const effectivePath = path ? `${SOURCE_URL}/reports/${path}` : `${SOURCE_URL}/reports`;

  return qualifyUrl(effectivePath);
};

export const reportWidgetHeaderId = (dashboardId: string, widgetId: string) => `widget-header-${dashboardId}-${widgetId}`;
export const MAX_LOGO_SIZE = 1024 * 1024; // 1MB

export const DEFAULT_ORIENTATION: ReportLayout['orientation'] = 'portrait';
export const DEFAULT_SIZE: ReportLayout['pageSize'] = 'letter';

export const WIDGET_ASPECT_RATIO = { portrait: 9 / 23, landscape: 1 / 3 };
export const WIDGET_WIDTH = { landscape: 1400, portrait: 1100 };

// eslint-disable-next-line import/prefer-default-export
export const PAGE_PRINT_MARGIN_IN = 0.3;
export const PAGE_PRINT_MARGIN_PX = 96 * PAGE_PRINT_MARGIN_IN;
export const PAGE_PRINT_MARGIN = `${PAGE_PRINT_MARGIN_IN}in`;
// Print margin for header and footer
export const PAGE_PRINT_MARGIN_LARGE = '0.7in';

export const PAGE_SIZES = [
  {
    label: 'A4',
    value: 'a4',
    portraitDimensions: { width: 816, height: 1056 },
    previewLayoutMultiplier: { portrait: 1.45, landscape: 1.39 },
  },
  {
    label: 'US Letter',
    value: 'letter',
    portraitDimensions: { width: 794, height: 1123 },
    previewLayoutMultiplier: { portrait: 1.494, landscape: 1.315 },
  },
];

export const PAGE_SIZES_MAP = Object.fromEntries(PAGE_SIZES.map((pageSize) => [pageSize.value, pageSize]));
