import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';

const WarningIcon = styled(Icon)`
  margin-right: 5px;
`;

const WidgetTitle = ({ title, isLoading, detailsAreAvailable }: { title: string | undefined, isLoading: boolean, detailsAreAvailable: boolean }) => {
  if (isLoading) {
    return <>Loading...</>;
  }

  if (!detailsAreAvailable) {
    return (
      <span>
        <WarningIcon name="warning" /><i>Widget not accessible</i>
      </span>
    );
  }

  return <>{title}</>;
};

export default WidgetTitle;
