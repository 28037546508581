import * as React from 'react';

import useLocation from 'routing/useLocation';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import { DocumentTitle } from 'components/common';
import WizardPageHeader from 'components/authentication/directoryServices/ldap/WizardPageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import Routes from 'routing/Routes';
import OidcBackendMetaProvider from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';
import LicenseCheck from 'license/LicenseCheck';
import useHistory from 'routing/useHistory';

import BackendWizard from './BackendWizard';
import type { ConfigFormValues } from './types';
import { handleCreate } from './helpers/handleSubmit';
import payloadFromFormValues from './helpers/payloadFromFormValues';
import getDisplayName from './helpers/getDisplayName';

const BackendCreate = () => {
  const location = useLocation();
  const backendType = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const isOkta = backendType === OKTA_TYPE_KEY;
  const displayName = getDisplayName(backendType);
  const history = useHistory();

  const onSubmitForm = (values: ConfigFormValues, shouldUpdateGroupSync?: boolean) => handleCreate(payloadFromFormValues(values), values, shouldUpdateGroupSync)
    .then(() => {
      history.push(Routes.SYSTEM.AUTHENTICATION.BACKENDS.OVERVIEW);
    }).catch((error) => {
      throw error;
    });

  const title = `Create ${displayName} Authentication Service`;

  return (
    <DocumentTitle title={title}>
      <WizardPageHeader title={title} />
      <SectionComponent title={title}>
        <LicenseCheck>
          {({ licenseIsValid }) => (
            <OidcBackendMetaProvider defaultValue={{ backendId: undefined, backendGroupSyncIsActive: false, licenseIsValid }}>
              <BackendWizard onSubmitForm={onSubmitForm} groupSyncValues={{}} backendType={backendType} isOkta={isOkta} />
            </OidcBackendMetaProvider>
          )}
        </LicenseCheck>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default BackendCreate;
