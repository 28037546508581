import { useQuery } from '@tanstack/react-query';

import type { DataWarehouse } from 'data-warehouse/Types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';

export const fetchStreamDataWarehouse = (streamId: string): Promise<DataWarehouse> => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.get_stream_datawarehouse(streamId).url));

const useStreamDataWarehouse = (streamId: string): {
  streamDataWarehouseData: {
    data: DataWarehouse,
    refetch: () => void,
    isFetching: boolean,
    error: Error,
  },
} => {
  const { data, refetch, isFetching, error } = useQuery<DataWarehouse, Error>(
    ['data-warehouse', streamId],
    () => fetchStreamDataWarehouse(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  return ({
    streamDataWarehouseData: {
      data,
      refetch,
      isFetching,
      error,
    },
  });
};

export default useStreamDataWarehouse;
