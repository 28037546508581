import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './Routes';

import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';
import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize = (
  {
    onSubmit,
    onChange,
  }: StepAuthorizeProps,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const region = [
    { label: 'North America (us)', value: 'https://api.sep.securitycloud.symantec.com' },
    { label: 'Europe (eu)', value: 'https://api.sep.eu.securitycloud.symantec.com' },
    { label: 'India (in)', value: 'https://api.sep.in.securitycloud.symantec.com' },
  ];
  const { connectionString } = formData;
  const [formValidated, setFormValidated] = useState('');

  const fieldChange = (event) => {
    if (event) {
      setFormValidated(event.target.value);
    }

    onChange(event, null);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    const fetcher = fetch(
      'POST',
      qualifyUrl(ApiRoutes.INTEGRATIONS.SymantecSES.CHECK_CREDENTIALS),
      {
        oauth_credentials: formData?.oauthCredentials?.value || '',
        ses_base_url: formData?.sesBaseURL?.value || '',
      },
    );

    fetcher
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              'Unable to connect to Symantec SES using provided configuration.',
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        setFormError({
          full_message: err.message,
          nice_message:
            'Unable to connect to Symantec SES using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ['symantecSESName', 'oauthCredentials', 'sesBaseURL'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              disabled={isFormValid}
              loading={loading}
              title=""
              error={formError}
              description="">
      <ValidatedInput className=""
                      id="symantecSESName"
                      type="text"
                      fieldData={formData.symantecSESName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={connectionString?.value}
                      required />

      <ValidatedInput className=""
                      id="oauthCredentials"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.oauthCredentials}
                      label="OAuth Credentials"
                      help="The OAuth Credential of your client application."
                      required />

      <ValidatedInput type="select"
                      id="sesBaseURL"
                      onChange={(e) => fieldChange(e)}
                      fieldData={formData.sesBaseURL}
                      help="Symantec SES Hosting location."
                      required
                      label="Hosting Location">
        {renderOptions(region, 'Choose your account Location', false)}
      </ValidatedInput>
      <p>{formValidated && `Your Cloud Base Url: ${formValidated}`}</p>

    </FormWrap>
  );
};

export default StepAuthorize;
