import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';

import useFetch from './common/hooks/useFetch';
import FormWrap from './common/FormWrap';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import { toSalesforceInputCreateRequest } from './common/formDataAdapter';
import type {
  ErrorMessageType,
  FormDataContextType,
  HandleSubmitType,
} from './types';

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.variant.darkest.default};
    margin: 25px 0;
    padding: 15px;
    border-radius: 4px;
    width: max-content;
    whiteSpace: pre-wrap;
    overflow-wrap: break-word;
  `,
);

const containerStyle = {
  whiteSpace: 'pre-wrap' as 'pre-wrap',
  overflowWrap: 'break-word' as 'break-word',
  width: '1500px',

};

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0 0 25px 10px;
    padding: 0;

    li {
      line-height: 2;
      padding: 0 5px;

      &:nth-of-type(odd) {
        background-color: ${theme.colors.table.row.backgroundStriped};
      }
    }

    strong::after {
      content: ":";
      margin-right: 5px;
    }
  `,
);

type Props = {
  onSubmit: HandleSubmitType;
  externalInputSubmit: boolean;
};

const StepReview = (
  {
    onSubmit,
    externalInputSubmit = false,
  }: Props,
) => {
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { formData } = useContext<FormDataContextType>(FormDataContext);

  const throttleEnabled = !!formData.salesforceThrottleEnabled?.value;
  const [saveInput, setSaveInput] = useFetch(
    null,
    () => onSubmit(),
    'POST',
    toSalesforceInputCreateRequest(formData),
  );

  useEffect(() => {
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: (
          <span>
            We were unable to save your Input, please try again in a few
            moments.
          </span>
        ),
      });
    }
  }, [saveInput.error, setSaveInput]);

  const handleSubmit = () => {
    if (externalInputSubmit) {
      onSubmit(formData);

      return;
    }

    setSaveInput(ApiRoutes.INTEGRATIONS.Salesforce.SAVE_INPUT);
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Save and Start Input"
              loading={saveInput.loading}
              error={formError}
              description="Check out everything below to make sure it's correct, then click the button below to complete your Salesforce setup!">
      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name</strong>
            <span>{formData.salesforceName.value}</span>
          </li>
          <li>
            <strong>Salesforce Instance Name</strong>
            <span>{formData.salesforceInstanceName.value}</span>
          </li>
          <li>
            <strong>Salesforce Client ID</strong>
            <span>{formData.salesforceClientId.value}</span>
          </li>
          <li>
            <strong>Polling Interval</strong>
            <span>{formData.pollingInterval.value}</span>
          </li>
          <li>
            <div style={containerStyle}>
              <strong>Subscribed To</strong>
              {formData.logTypes.value.replaceAll(',', ', ')}
            </div>
          </li>
          <li>
            <strong>Enable Throttling</strong>
            <span>
              <Icon name={throttleEnabled ? 'check_circle' : 'cancel'} />
            </span>
          </li>
          <li>
            <strong>Access Token Expires In (Hours)</strong>
            <span>{formData.accessTokenExpiresIn.value}</span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

export default StepReview;
