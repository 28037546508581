import * as React from 'react';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import { useGetAssetsByIds } from 'security-app/hooks/useAssetsAPI';

type Props = {
  identifiers: string[],
  addToQuery: (id: string) => void,
};

const AssetNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AssetName = styled.div(({ theme }: { theme: DefaultTheme }) => css`
  color: ${theme.colors.global.link};
  cursor: pointer;
  font-weight: bold;

  &:hover { 
    color: ${theme.colors.global.linkHover};
    text-decoration: underline;
  }
`);

const IconContainer = styled.div`
  width: 16px;
  display: inline-block;
  margin-left: 3px;
`;

const AssetInfoRowWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
`;

const AssetCustomFieldWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const AssetCustomFieldsRow = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
`;

const AssetInfoField = styled.div`
  flex: 1;
`;

const AssetInfoFieldValue = styled.div`
  flex: 2;
`;

const AssetCustomInfoField = styled.div`
  flex: 3;
`;

const AssetCustomInfoFieldValue = styled.div`
  flex: 2;
`;

function AssetInformation({ identifiers, addToQuery }: Props) {
  const { assets, fetchingAssets } = useGetAssetsByIds(identifiers);
  const [selectedAsset, setSelectedAsset] = React.useState('');

  const handleAssetClick = (id: string) => {
    if (selectedAsset === id) {
      setSelectedAsset('');
    } else {
      setSelectedAsset(id);
    }
  };

  const handleAddToQuery = (id: string) => {
    addToQuery(id);
  };

  const renderField = (key, fieldValue) => {
    if (key === 'geo_info') {
      return (
        <AssetCustomFieldWrapper key={`asset-${key}`}>
          <AssetInfoField>{key}:</AssetInfoField>
          {Object.keys(fieldValue).map((valueKey) => {
            const subFieldValue = Array.isArray(fieldValue[valueKey].values) ? fieldValue[valueKey].values.join(', ') : fieldValue[valueKey];

            return (
              <AssetCustomFieldsRow key={`asset-custom-${valueKey}`}>
                {subFieldValue ? (
                  <>
                    <AssetCustomInfoField>{valueKey}:</AssetCustomInfoField>
                    <AssetCustomInfoFieldValue>{subFieldValue}</AssetCustomInfoFieldValue>
                  </>
                ) : null}
              </AssetCustomFieldsRow>

            );
          })}
        </AssetCustomFieldWrapper>
      );
    }

    if (key === 'custom_fields') {
      return (
        <AssetCustomFieldWrapper key={`asset-${key}`}>
          <AssetInfoField>{key}:</AssetInfoField>
          {Object.keys(fieldValue).map((valueKey) => {
            const subFieldValue = Array.isArray(fieldValue[valueKey].values) ? fieldValue[valueKey].values.join(', ') : fieldValue[valueKey].values;

            return (
              <AssetCustomFieldsRow key={`asset-custom-${valueKey}`}>
                <AssetInfoField>{valueKey}:</AssetInfoField>
                <AssetInfoFieldValue>{subFieldValue}</AssetInfoFieldValue>
              </AssetCustomFieldsRow>
            );
          })}
        </AssetCustomFieldWrapper>
      );
    }

    return (
      <AssetInfoRowWrapper key={`asset-${key}`}>
        <AssetInfoField>{key}:</AssetInfoField>
        <AssetInfoFieldValue>{fieldValue}</AssetInfoFieldValue>
      </AssetInfoRowWrapper>
    );
  };

  const renderAsset = (asset) => (
    Object.keys(asset.details).map((key) => {
      const fieldValue = Array.isArray(asset.details[key]) ? asset.details[key].join(', ') : asset.details[key];

      if (selectedAsset !== asset.id) return null;

      return (
        <div key={asset.id}>
          {fieldValue ? (
            renderField(key, fieldValue)
          ) : null}
        </div>
      );
    }));

  const renderAssets = (assetsList) => assetsList.map((asset) => (
    <div key={`${asset.id}-wrapper`}>
      <AssetNameWrapper>
        <AssetName onClick={() => handleAssetClick(asset.id)}>{asset.name}</AssetName>
        <IconContainer>
          {selectedAsset === asset.id ? (<Icon name="arrow_drop_up" size="xs" />) : (<Icon name="arrow_drop_down" size="xs" />)}
        </IconContainer>
      </AssetNameWrapper>
      {selectedAsset === asset.id && (
        <>
          <Button bsStyle="info" bsSize="xs" onClick={() => handleAddToQuery(asset.id)}>Add to query</Button>
          <div>{renderField('category', asset.category.join(', '))}</div>
          {renderAsset(asset)}
        </>
      )}
    </div>
  ));

  return (
    <div>
      {!fetchingAssets && assets !== undefined ? (
        <div>
          {renderAssets(assets)}
        </div>
      ) : <div>No information found in database for this asset.</div>}
    </div>
  );
}

export default AssetInformation;
