import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'components/bootstrap';
import AuditLogTableEntry from 'auditlog/AuditLogTableEntry';

class AuditLogEntriesTable extends React.Component {
  static propTypes = {
    entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const entries = this.props.entries.map((entry) => (
      <AuditLogTableEntry key={`audit-log-entry-${entry.id}`} entry={entry} />
    ));

    return (
      <Table condensed hover>
        <thead>
          <tr>
            <th className="small-col">Timestamp</th>
            <th className="tiny-col">Actor</th>
            <th className="tiny-col">Action</th>
            <th>Message</th>
          </tr>
        </thead>
        {entries}
      </Table>
    );
  }
}

export default AuditLogEntriesTable;
