import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import AWSSecurityLakeRoutes from './common/Routes';

const AWSSecurityLakeInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = AWSSecurityLakeRoutes.INTEGRATIONS.AWSSecurityLake.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default AWSSecurityLakeInputConfiguration;
