import * as React from 'react';
import styled, { css } from 'styled-components';

import Checkbox from 'components/bootstrap/Checkbox';
import IconButton from 'components/common/IconButton';

export const Column = styled.td<{ $displayCursor?: boolean }>(({ $displayCursor = true }) => css`
  ${$displayCursor ? 'cursor: pointer;' : ''};
`);

export const Row = styled.tr<{ $disabled: boolean }>(({ theme, $disabled }) => css`
  color: ${$disabled ? theme.colors.gray[80] : theme.colors.global.textDefault};
`);
export const StyledCheckbox = styled(Checkbox)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const SmallColumn = styled(Column)`
  width: 25px;
`;

type ExpandButtonProps = Omit<React.ComponentProps<typeof IconButton>, 'name'> & { expanded: boolean };

export const ExpandButton = ({ expanded, ...rest }: ExpandButtonProps) => <IconButton name={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} iconType="regular" {...rest} />;
