import { useQuery } from '@tanstack/react-query';

import type { Attribute, SearchParams } from 'stores/PaginationTypes';
import UserNotification from 'util/UserNotification';
import type { DataWarehouse } from 'data-warehouse/Types';
import DataWarehouseStore from 'data-warehouse/DataWarehouseStore';

const INITIAL_DATA = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};

type Options = {
  enabled: boolean,
}
const refetchInterval = 5000;

export const fetchDataWarehouses = (searchParams: SearchParams) => DataWarehouseStore.searchPaginated(
  searchParams.page,
  searchParams.pageSize,
  searchParams.query,
  {
    sort: searchParams?.sort.attributeId,
    order: searchParams?.sort.direction,
  },
);

export const keyFn = (searchParams: SearchParams) => ['data-warehouse', 'overview', searchParams];

const useDataWarehouses = (searchParams: SearchParams, { enabled }: Options = { enabled: true }): {
  data: {
    list: Array<DataWarehouse>,
    pagination: { total: number }
    attributes: Array<Attribute>
  },
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    keyFn(searchParams),
    () => fetchDataWarehouses(searchParams),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Data Warehouse failed with status: ${errorThrown}`,
          'Could not load Data Warehouse');
      },
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouses;
