import * as React from 'react';
import styled, { css } from 'styled-components';

import { Alert } from 'components/bootstrap';
import { useGetBundlesStatus } from 'illuminate/hooks/useBundlesAPI';

const StyledAlert = styled(Alert)(({ theme }) => css`
  margin-top: 10px;

  i {
    color: ${theme.colors.gray[10]};
  }

  form {
    margin-bottom: 0;
  }
`);

const IlluminateDeprecatedInstallationWarning = () => {
  const { bundlesStatus } = useGetBundlesStatus();

  if (bundlesStatus?.deprecated_illuminate_enabled) {
    return (
      <StyledAlert bsStyle="danger" title="Possible Deprecated Illuminate Installation Detected">
        <div className="notification-description">
          A deprecated Illuminate installation (version 1.x) may have been detected.
          Running two Illuminate installations simultaneously can cause unexpected system behavior.
          It is recommended that you uninstall and remove all &quot;Graylog Illuminate 1.x&quot; content packs
          from the <a href="/system/contentpacks/">Content Packs</a> page and verify no&nbsp;
          <a href="/system/pipelines/">Pipelines</a> have a title starting with &quot;Illuminate:&quot;
          before proceeding.
        </div>
      </StyledAlert>
    );
  }

  return null;
};

export default IlluminateDeprecatedInstallationWarning;
