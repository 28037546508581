import Reflux from 'reflux';

import { singletonActions, singletonStore } from 'logic/singleton';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import PaginationURL from 'util/PaginationURL';
import type { Attributes } from 'stores/PaginationTypes';
import type { DataWarehouse } from 'data-warehouse/Types';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';

export type ActionsType = {
  searchPaginated: () => Promise<any>,
  updateConfig: () => Promise<any>,
};
const DataWarehouseActions = singletonActions('Streams', () => Reflux.createActions<ActionsType>({
  searchPaginated: { asyncResult: true },
  updateConfig: { asyncResult: true },
}));

type PaginatedResponse = {
  pagination: {
    count: number,
    total: number,
    page: number,
    per_page: number,
  },
  query: string,
  attributes: Attributes,
  elements: Array<DataWarehouse>,
};

const DataWarehouseStore = singletonStore('DataWarehouse', () => Reflux.createStore({
  listenables: [DataWarehouseActions],

  callbacks: [],

  searchPaginated(newPage, newPerPage, newQuery, additional) {
    const url = PaginationURL(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.paginated().url, newPage, newPerPage, newQuery, additional);

    const promise = fetch('GET', qualifyUrl(url))
      .then((response: PaginatedResponse) => {
        const {
          elements,
          query,
          attributes,
          pagination: {
            count,
            total,
            page,
            per_page: perPage,
          },
        } = response;

        return {
          list: elements,
          attributes,
          pagination: {
            count,
            total,
            page,
            perPage,
            query,
          },
        };
      });

    DataWarehouseActions.searchPaginated.promise(promise);

    return promise;
  },
}));

export { DataWarehouseStore, DataWarehouseActions };
export default DataWarehouseStore;
