import Reflux from 'reflux';

import fetch, { Builder, fetchFile } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import { downloadBLOB } from 'util/FileDownloadUtils';

import type { UploadConfigResponse } from './Types';

type ActionTypes = {
  get: (id: string) => Promise<any>,
  getConfig: () => Promise<UploadConfigResponse>,
  upload: (file: File) => Promise<any>
  downloadFile: (id: string) => Promise<void>,
};

export const UploadFieldActions = Reflux.createActions<ActionTypes>({
  get: { asyncResult: true },
  getConfig: { asyncResult: true },
  upload: { asyncResult: true },
  downloadFile: { asyncResult: true },
});

type DownloadFileResponse = [ BlobPart, { file_name: string, content_type: string }]

const UploadFieldStore = Reflux.createStore({
  listenables: [UploadFieldActions],
  sourceUrl: '/plugins/org.graylog.plugins.files/files',

  getInitialState() {
    return {};
  },

  get(id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}/metadata`);
    const promise = fetch('GET', url);

    promise.catch((error) => {
      UserNotification.error(`Fetching file metadata failed with status: ${error.message},
      Could not retrieve file metadata with id:${id}`);
    });

    UploadFieldActions.get.promise(promise);

    return promise;
  },

  getConfig() {
    const url = qualifyUrl(`${this.sourceUrl}/config`);
    const promise = fetch('GET', url);

    promise.catch((error) => {
      UserNotification.error(`Fetching file upload limit failed with status: ${error.message},
      Could not retrieve file upload limit`);
    });

    UploadFieldActions.getConfig.promise(promise);

    return promise;
  },

  downloadFile(id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}`);

    const promise = Promise.all([
      fetchFile('GET', url, undefined, 'text/csv'),
      this.get(id),
    ])
      .then(([contents, metadata]: DownloadFileResponse) => {
        downloadBLOB(contents, { fileName: metadata.file_name, contentType: metadata.content_type });
      });

    promise.catch((error) => {
      UserNotification.error(`Downloading file failed with status: ${error.message},
      Could not download file with id:${id}`);
    });

    UploadFieldActions.downloadFile.promise(promise);

    return promise;
  },

  upload(file: File) {
    const url = qualifyUrl(this.sourceUrl);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('temporary', 'true');

    const promise = new Builder('POST', url)
      .formData(formData)
      .build();

    UploadFieldActions.upload.promise(promise);

    return promise;
  },
});

export default UploadFieldStore;
