import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import * as URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import ApiRoutes from 'routing/ApiRoutes';

import { DATA_WAREHOUSE_API_ROUTES } from '../Constants';
import type { DataWarehouseJob } from '../Types';

const refetchInterval = 3000;
const getQueryKey = (streamId?: string) => ['data-warehouse', 'jobs', streamId];

const fetchDataWarehouseJob = (streamId?: string): Promise<{
  in_progress: number,
  progress: Array<DataWarehouseJob>
}> => {
  const url = streamId ? DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.job.stream_jobs(streamId).url
    : DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.job.progress().url;

  return fetch('GET', qualifyUrl(url));
};

const cancelDataWarehouseJob = (id: string) => fetch('DELETE', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.job.cancel(id).url));
const acknowledgeDataWarehouseJob = (id: string) => fetch('DELETE', URLUtils.qualifyUrl(ApiRoutes.SystemJobsApiController.acknowledgeJob(id).url));

const useDataWarehouseJobs = (streamId?: string): {
  dataWarehouseData: {
    inProgressCount: number,
    jobs: Array<DataWarehouseJob>,
  },
  refetchDataWarehouse: () => void,
  isLoadingDataWarehouse: boolean,
  onCancelDataWarehouseJob: (id: string) => Promise<void>,
  onAcknowledgeDataWarehouseJob: (id: string) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { data, refetch: refetchDataWarehouse, isLoading: isLoadingDataWarehouse } = useQuery(
    getQueryKey(streamId),
    () => fetchDataWarehouseJob(streamId),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Data Warehouse jobs failed with status: ${errorThrown}`,
          'Could not load Data Warehouse jobs');
      },
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
    },
  );
  const { mutateAsync: onCancelDataWarehouseJob } = useMutation(cancelDataWarehouseJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey());
    },
    onError: (errorThrown) => {
      UserNotification.error(`Canceling Data Warehouse job failed with status: ${errorThrown}`,
        'Could not cancel Data Warehouse jobs');
    },
  });
  const { mutateAsync: onAcknowledgeDataWarehouseJob } = useMutation(acknowledgeDataWarehouseJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey());
    },
    onError: (errorThrown) => {
      UserNotification.error(`Acknowledging Data Warehouse job status failed with status: ${errorThrown}`,
        'Could not acknowledge Data Warehouse jobs status');
    },
  });

  return ({
    dataWarehouseData: {
      inProgressCount: data?.in_progress,
      jobs: data?.progress,
    },
    refetchDataWarehouse,
    isLoadingDataWarehouse,
    onCancelDataWarehouseJob,
    onAcknowledgeDataWarehouseJob,
  });
};

export default useDataWarehouseJobs;
