import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Pack } from 'illuminate/types';

type Props = {
  requiredPacks: Pack[],
}

const StyledRequirement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
`;

const StyledCode = styled.code<{ $enabled: boolean }>(({ $enabled, theme }) => css`
  background-color: ${$enabled ? theme.colors.variant.darker.success : theme.colors.variant.darker.danger} !important;
  color: ${$enabled ? theme.colors.variant.lighter.success : theme.colors.variant.lighter.danger} !important;
`);

const IlluminatePackRequirement = ({ requiredPacks }: Props) => {
  if (requiredPacks.length === 0) return null;

  return (
    <div>
      {
        requiredPacks.map((requiredPack) => (
          <StyledRequirement key={requiredPack.title}>
            <StyledCode $enabled={requiredPack.enabled}>{`${requiredPack.title}`}: {requiredPack.enabled ? 'enabled' : 'disabled'}</StyledCode>
          </StyledRequirement>
        ))
      }
    </div>
  );
};

export default IlluminatePackRequirement;
