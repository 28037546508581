import { useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import {
  DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_CONFIG_QUERY_KEYS,
} from 'data-warehouse/Constants';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import type { Backend, BackendCreate } from 'archive/types';

const createDataWarehouseBackend = async (backend: BackendCreate) => (
  fetch('POST', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backend.base().url), { ...backend })
);

const updateDataWarehouseBackend = async (backend: Backend) => (
  fetch('PUT', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backend.update(backend.id).url), { ...backend })
);
const deleteDataWarehouseBackend = async (id: string) => (
  fetch('DELETE', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backend.delete(id).url))
);

const useCreateDataWarehouseBackend = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(createDataWarehouseBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully created Data warehouse backend.');
      queryClient.invalidateQueries(DATA_WAREHOUSE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error creating Data warehouse backend: ${err.toString()}`, 'Unable to create Data warehouse backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useUpdateDataWarehouseBackend = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(updateDataWarehouseBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Data warehouse backend');
      queryClient.invalidateQueries(DATA_WAREHOUSE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Data warehouse backend: ${err.toString()}`, 'Unable to update Data warehouse backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useDeleteDataWarehouseBackend = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(deleteDataWarehouseBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully deleted Data warehouse backend');
      queryClient.invalidateQueries(DATA_WAREHOUSE_CONFIG_QUERY_KEYS);
    },
    onError: (err: Error) => {
      UserNotification.error(`Error deleting Data warehouse backend: ${err.toString()}`, 'Unable to delete Data warehouse backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

export {
  useCreateDataWarehouseBackend,
  useUpdateDataWarehouseBackend,
  useDeleteDataWarehouseBackend,
  deleteDataWarehouseBackend,
};
