import * as React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { useValidateSigmaRule, useValidateSigmaRuleZip } from 'security-app/hooks/useSigmaAPI';
import { IconButton } from 'components/common';

import { filesToForms } from './fileFormLogic';
import type { FileForm } from './fileFormLogic';

const FileListContainer = styled.div`
  height: 40vh;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  background-color: ${({ theme }) => theme.colors.global.background};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.input.placeholder};
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.input.border};
  padding: 8px;
  flex-shrink: 0;
  flex-grow: 0;

  button {
    color: ${({ theme }) => theme.colors.variant.success};

    &:hover {
      background-color: ${({ theme }) => theme.colors.global.background};
    }
  }
`;

const ListBody = styled.div<{ $isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding: 8px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  border: 1px solid transparent;
  border-color: ${({ theme, $isDragActive }) => ($isDragActive ? theme.colors.variant.success : 'transparent')};
`;

const FileListItem = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  color: ${({ $active, theme }) => ($active ? theme.colors.global.textDefault : theme.colors.input.placeholder)};
  cursor: pointer;
  min-width: 0;

  button {
    color: ${({ $active, theme }) => ($active ? theme.colors.variant.danger : 'gray')};;
    flex-shrink: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.global.textDefault};

    button {
      color: ${({ theme }) => theme.colors.variant.danger};
      background-color: ${({ theme }) => theme.colors.global.background};
    }
  }
`;

const DragMessage = styled.small`
  position: absolute;
  top: 40%;
  color: ${({ theme }) => theme.colors.input.placeholder};
  width: 100%;
  text-align: center;
  margin-left: -8px;
  line-height: 1.5;
`;

const FileName = styled.span<{ $withError: boolean }>`
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  color: ${({ $withError, theme }) => ($withError ? theme.colors.variant.danger : 'inherit')};
`;

type FileItemProps = {
  file: FileForm,
  onRemove: (file: FileForm) => void,
  selectedFile: FileForm | null,
  selectFile: (file: FileForm) => void,
};

function FileItem({ file, onRemove, selectedFile, selectFile }: FileItemProps) {
  return (
    <FileListItem role="listitem"
                  onClick={(e: React.BaseSyntheticEvent) => e.stopPropagation()}
                  $active={selectedFile && selectedFile.id === file.id}>
      <IconButton name="close"
                  title="Remove file"
                  onClick={() => onRemove(file)}
                  size="sm" />
      <FileName role="link"
                title="File name"
                $withError={file.ruleErrors?.length > 0}
                onClick={() => selectFile(file)}>
        {file.file.name}
      </FileName>
    </FileListItem>
  );
}

type Props = {
  files: FileForm[],
  setFiles: (files: FileForm[]) => void,
  selectedFile: FileForm | null,
  selectFile: (file: FileForm) => void,
};

const acceptedFileTypes = {
  'application/yaml': ['.yml', '.yaml'],
  'application/zip': ['.zip'],
};

function FileList({ files, setFiles, selectedFile, selectFile }: Props) {
  const { validateSigmaRule } = useValidateSigmaRule();
  const { validateSigmaRuleZip } = useValidateSigmaRuleZip();

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    const validateRule = async (fileForm: FileForm) => {
      const rawRule = await new Promise((resolve: (arg: string) => void) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsText(fileForm.file);
      });

      const validationResponse = await validateSigmaRule(rawRule);
      // eslint-disable-next-line no-param-reassign
      fileForm.ruleErrors = validationResponse?.errors;
    };

    const validateZip = async (fileForm: FileForm) => {
      const validationResponse = await validateSigmaRuleZip(fileForm.file);
      // eslint-disable-next-line no-param-reassign
      fileForm.ruleErrors = validationResponse?.errors;
    };

    const auxAcceptedFiles = acceptedFiles.map((file: File) => {
      if (!file.type) {
        const extension = file.name.split('.').pop();
        const type = Object.keys(acceptedFileTypes).find((key: string) => acceptedFileTypes[key].includes(`.${extension}`));

        return new File([file], file.name, { type });
      }

      return file;
    });

    const newFiles = filesToForms(auxAcceptedFiles);

    await Promise.all([
      ...newFiles.filter((fileForm: FileForm) => fileForm.file.type !== 'application/zip').map(validateRule),
      ...newFiles.filter((fileForm: FileForm) => fileForm.file.type === 'application/zip').map(validateZip),
    ]);

    const filesToAdd = newFiles.filter((newFileForm: FileForm) => (
      !files.find((fileForm: FileForm) => (
        fileForm.file.name === newFileForm.file.name
        && fileForm.file.size === newFileForm.file.size
      ))
    ));

    setFiles([...files, ...filesToAdd]);
  }, [setFiles, files, validateSigmaRule, validateSigmaRuleZip]);

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive, open } = useDropzone({
    // @ts-ignore - TS doesn't like the type of acceptedFileTypes. There is discrepancy within the package. most likely an update will fix the error
    acceptedFiles: Object.keys(acceptedFileTypes).join(','),
    noClick: true,
    onDrop,
  });

  const removeFile = (inFileForm: FileForm) => {
    setFiles(files.filter((fileForm: FileForm) => fileForm.id !== inFileForm.id));
  };

  return (
    <FileListContainer>
      <ListHeader>Selected Files <IconButton name="add" title="Add rules" onClick={open} /></ListHeader>
      <ListBody {...getRootProps({ style: { cursor: 'default' } })}
                $isDragActive={isDragActive || isFileDialogActive}>
        {files.map((fileForm: FileForm) => (
          <FileItem key={fileForm.id}
                    file={fileForm}
                    onRemove={removeFile}
                    selectedFile={selectedFile}
                    selectFile={selectFile} />
        ))}
        <input {...getInputProps({ id: 'ruleFiles', 'aria-label': 'Drop zone' })} />
        {files.length < 1 && (
          <DragMessage>
            Drag &apos;n&apos; Drop your rules here
          </DragMessage>
        )}
      </ListBody>
    </FileListContainer>
  );
}

export default FileList;
