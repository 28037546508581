import { useQuery } from '@tanstack/react-query';

import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { License } from 'license/types';
import type FetchError from 'logic/errors/FetchError';

export const ACTIVE_LICENSE_QUERY_KEY = 'active_license';

const getLicense = () => fetch(
  'GET',
  qualifyUrl(EnterpriseApiRoutes.License.active().url),
);

const useActiveLicenseInformation = (): {
  data: License,
  isLoading: boolean,
  isFetching: boolean,
  isError: boolean,
  refetch: () => void
} => {
  const { data, isFetching, isLoading, isError, refetch } = useQuery<License, FetchError>([ACTIVE_LICENSE_QUERY_KEY], getLicense, {
    onError: (errorThrown) => {
      if (!(errorThrown.status === 404)) {
        UserNotification.error(`Loading active license information failed with: ${errorThrown}`);
      }
    },
    retry: false,
  });

  return ({
    data,
    isFetching,
    isLoading,
    isError,
    refetch,
  });
};

export default useActiveLicenseInformation;
