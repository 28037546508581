import * as React from 'react';
import styled, { css } from 'styled-components';

import type { QueryValidationState } from 'views/components/searchbar/queryvalidation/types';
import { Explanation } from 'views/components/searchbar/queryvalidation/QueryValidation';
import { Icon } from 'components/common';

import DataWarehouseSearchRetreive from './DataWarehouseSearchRetreive';

type Props = {
  validationState: QueryValidationState;
};
const StyledIcon = styled(Icon)(({ theme }) => css`
  color: ${theme.colors.variant.info};
  margin-right: ${theme.spacings.sm};
`);

const DataWarehouseQueryValidation = ({ validationState } : Props) => {
  const hasDataWarehouseData = validationState.status === 'INFO' && validationState.context?.data_routed_streams.length > 0;

  if (!hasDataWarehouseData) {
    return null;
  }

  const streamIds = validationState.context?.data_routed_streams.map((data) => data.stream_id);
  const timeRange = validationState.context?.searched_time_range;

  return (
    <Explanation>
      <span>
        <StyledIcon name="database" />Additional data that meets this search criteria may exist in the Data Warehouse. <DataWarehouseSearchRetreive streamIds={streamIds} timeRange={timeRange} />
      </span>
    </Explanation>
  );
};

export default DataWarehouseQueryValidation;
