import React from 'react';

import DataWarehouseOverview from 'data-warehouse/data-warehouse-overview/DataWarehouseOverview';
import DataWarehouseJobs from 'data-warehouse/components/DataWarehouseJobs';
import DataWarehousePageNavigation from 'data-warehouse/components/DataWarehousePageNavigation';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { DATA_WAREHOUSE_LICENSE_TITLE, DATA_WAREHOUSE_LICENSE_TEXT } from 'data-warehouse/Constants';
import { Alert, Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';

const DataWarehousesPage = () => (
  <DocumentTitle title="Data Warehouse Overview">
    <DataWarehousePageNavigation />
    <PageHeader title="Data Warehouse Overview">
      <span>
        Data Warehouse Overview
      </span>
      <Alert bsStyle="info">
        Data Warehouse is a mechanism for storing processed data without ingesting it into Opensearch.<br />
        The Data Warehouse back-end can be either an <b>S3 bucket (recommended)</b> or network storage.<br />
        Messages is categorized in Data Warehouse by Stream. Messages can be retrieved from Data Warehouse by Stream for a defined time interval.<br />
        Note that storing messages in Data Warehouse does not count towards license usage, but retrieving messages does.
      </Alert>
    </PageHeader>
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.archive}
                  title={DATA_WAREHOUSE_LICENSE_TITLE}
                  text={DATA_WAREHOUSE_LICENSE_TEXT}
                  displayWarningContainer
                  bsStyle="danger" />
    <DataWarehouseJobs />
    <Row className="content">
      <Col md={12}>
        <DataWarehouseOverview />
      </Col>
    </Row>
  </DocumentTitle>
);

export default DataWarehousesPage;
