import * as React from 'react';
import { PluginStore } from 'graylog-web-plugin/plugin';
import Immutable from 'immutable';
import { useMemo } from 'react';
import { styled } from 'styled-components';

import type { SearchTypeResult, WidgetExport } from 'views/types';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import PrintingWidgetVisualization from 'report/common/PrintingWidgetVisualization';
import type { ValueTypeBase, WidgetTypeBase } from 'common/components/widgetRenderers/PrintWidget';
import searchTypeDefinition from 'views/logic/SearchType';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  .quickvalues-table {
    margin: 0 auto;
    text-align: left;
    width: 80%;
  }

  .dc-chart {
    float: none;
  }

  @media print {
    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-before: avoid;
    break-after: avoid;
    break-before: avoid;
    margin-bottom: 4rem;
  }
`;

type Props<WT, VT> = {
  visualizationHeight: number,
  interactive: boolean,
  limitHeight: boolean,
  widget: WT,
  widgetId: string,
  value: VT
  width: number
}

const _searchTypePlugin = (type: string) => {
  const typeDefinition = searchTypeDefinition(type);

  return typeDefinition && typeDefinition.handler
    ? searchTypeDefinition(type).handler
    : {
      convert: (result: unknown) => {
        // eslint-disable-next-line no-console
        console.error(`No search type handler for type '${type}' result:`, result);

        return result;
      },
    };
};

const PrintingWidgetVisualizationContainer = <WT extends WidgetTypeBase, VT extends ValueTypeBase>({
  visualizationHeight,
  interactive,
  limitHeight,
  value,
  widget,
  widgetId,
  width,
}: Props<WT, VT>) => {
  const fieldTypes = useMemo(() => ({ all: value.types, queryFields: Immutable.Map({ query: value.types }) }), [value.types]);
  const mappedResult = useMemo(() => {
    const mappedResults = value.result.filter((res) => !!res).map((searchType: SearchTypeResult) => _searchTypePlugin(searchType.type).convert(searchType));
    const widgetPlugin: WidgetExport = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());
    const { searchResultTransformer = (x: unknown) => x } = widgetPlugin || {};

    return searchResultTransformer(mappedResults);
  }, [value.result, widget.type]);

  return (
    <FieldTypesContext.Provider value={fieldTypes}>
      <Container>
        <PrintingWidgetVisualization widget={widget}
                                     widgetId={widgetId}
                                     result={mappedResult}
                                     hasError={value.hasError}
                                     errorMessage={value.errorMessage}
                                     types={value.types}
                                     visualizationHeight={visualizationHeight}
                                     width={width}
                                     interactive={interactive}
                                     limitHeight={limitHeight} />
      </Container>
    </FieldTypesContext.Provider>
  );
};

export default PrintingWidgetVisualizationContainer;
