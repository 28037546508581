import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { TextOverflowEllipsis } from 'components/common';

const StyledButton = styled(Button)`
  font-weight: bold;
  max-width: 100%;

  .mantine-Button-inner, .mantine-Button-label {
    max-width: 100%;
  }
`;

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
  children: string,
}

const RowTitle = ({ children, onClick }: Props) => (
  <StyledButton bsStyle="link" onClick={onClick}>
    <TextOverflowEllipsis>
      {children}
    </TextOverflowEllipsis>
  </StyledButton>
);

export default RowTitle;
