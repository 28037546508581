import * as React from 'react';

import { useGetInstallationStatus } from 'illuminate/hooks/useBundlesAPI';
import { Spinner } from 'components/common';
import IlluminateBundleVersion from 'illuminate/components/common/header/IlluminateBundleVersion';
import SectionComponent from 'components/common/Section/SectionComponent';
import IlluminateInstallStatus from 'illuminate/components/illuminate/IlluminateInstallStatus';
import { useRefetchOnInstallationFinish } from 'illuminate/components/illuminate/IlluminatePacks';

const IlluminateBundleEnable = () => {
  const { installationStatus, gettingStatus } = useGetInstallationStatus();

  useRefetchOnInstallationFinish();

  if (gettingStatus) {
    return (
      <Spinner />
    );
  }

  if (!installationStatus.install_in_progress) {
    return <IlluminateBundleVersion />;
  }

  return (
    <SectionComponent title="">
      <IlluminateInstallStatus installStatusObj={installationStatus} />
    </SectionComponent>
  );
};

export default IlluminateBundleEnable;
