import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';

import { HelpBlock } from 'components/bootstrap';
import SchedulingFrequencyForm from 'report/report-configuration-page/SchedulingFrequencyForm';

import SchedulingFrequenciesList from './SchedulingFrequenciesList';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;

type Args = {
  disabled: boolean,
  createScheduling: () => void,
}
type Props = {
  disabled: boolean,
  children: (args: Args) => React.ReactElement
};

const ReportScheduling = ({ children, disabled }: Props) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const closeModal = useCallback(() => setShowCreateModal(false), []);
  const createScheduling = useCallback(() => setShowCreateModal(true), []);
  const args = useMemo(() => ({ createScheduling, disabled }), [createScheduling, disabled]);

  return (
    <>
      <Header>
        {children(args)}
      </Header>
      <FieldArray name="delivery.scheduling"
                  render={({ remove, replace, push }) => (
                    <>
                      <SchedulingFrequenciesList onDelete={remove}
                                                 onUpdate={replace} />
                      {showCreateModal && (
                        <SchedulingFrequencyForm title="Configure new scheduling frequency"
                                                 submitButtonText="Add frequency"
                                                 submitLoadingText="Adding frequency..."
                                                 onSubmit={(newSchedulingFrequency) => {
                                                   closeModal();
                                                   push(newSchedulingFrequency);
                                                 }}
                                                 onCancel={closeModal} />
                      )}
                    </>
                  )} />
      <HelpBlock>Configure how often this report should be sent out.</HelpBlock>
    </>
  );
};

export default ReportScheduling;
