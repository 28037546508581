import * as React from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/common';
import { Input, Button } from 'components/bootstrap';

const ResetPasswordButton = styled(Button)`
  margin-top: 25px;
`;

const FlexLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.input.placeholder};
  font-size: 0.9rem;
  height: auto;

  &:hover {
    color: ${({ theme }) => theme.colors.global.textDefault};
    background-color: transparent;
  }
`;

type PasswordInputProps = {
  name: string,
  label: string,
  value: string | boolean,
  onChange: (event: React.BaseSyntheticEvent) => void,
  onResetPassword: (event: React.BaseSyntheticEvent) => void,
  onCancelReset: (event: React.BaseSyntheticEvent) => void,
  onBlur?: (event: React.BaseSyntheticEvent) => void,
  action?: string,
  help?: string,
  error?: string,
  placeholder?: string,
  disabled?: boolean,
  hasPassword?: boolean,
  bsStyle?: string,
  required?: boolean
};

const PasswordInput = ({
  name,
  label,
  value,
  onChange,
  onResetPassword,
  onCancelReset,
  onBlur,
  action,
  help,
  error,
  placeholder,
  disabled,
  hasPassword,
  bsStyle,
  required,
}: PasswordInputProps) => {
  const [resetPassword, setResetPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState('password');

  const togglePassword = React.useCallback(() => {
    setShowPassword(showPassword === 'password' ? 'text' : 'password');
  }, [showPassword]);

  const handleResetPassword = (e: React.BaseSyntheticEvent) => {
    setResetPassword(true);
    onResetPassword(e);
  };

  const cancelReset = (e: React.BaseSyntheticEvent) => {
    setResetPassword(false);
    onCancelReset(e);
  };

  const PasswordLabelWithToggle = React.useMemo(() => (
    <FlexLabel>
      {label}
      <StyledIconButton title="Toggle visibility"
                        name={showPassword === 'text' ? 'visibility_off' : 'visibility'}
                        onClick={togglePassword}
                        {...{ size: 'sm' }} />
    </FlexLabel>
  ), [label, showPassword, togglePassword]);

  if ((action === 'edit' && !resetPassword) || disabled) {
    return (
      <>
        {hasPassword && (
          <div style={{ width: '70%' }}>
            <Input label={label}
                   id={name}
                   data-testid={name}
                   type="password"
                   help={help}
                   disabled
                   value="********" />
          </div>
        )}
        {!disabled && (
          <ResetPasswordButton bsStyle="default" onClick={handleResetPassword}>
            {hasPassword ? 'Reset Value' : 'New Secret Value'}
          </ResetPasswordButton>
        )}
      </>
    );
  }

  return (
    <>
      <Input label={PasswordLabelWithToggle}
             name={name}
             data-testid={name}
             type={showPassword}
             id={name}
             placeholder={placeholder}
             value={value}
             help={help}
             error={error}
             bsStyle={bsStyle}
             required={required}
             onChange={onChange}
             onBlur={onBlur}
             disabled={disabled} />
      {resetPassword && <ResetPasswordButton bsStyle="default" onClick={cancelReset}>Cancel</ResetPasswordButton>}
    </>
  );
};

PasswordInput.defaultProps = {
  onBlur: () => {},
  action: 'new',
  help: undefined,
  error: undefined,
  placeholder: undefined,
  disabled: false,
  hasPassword: false,
  bsStyle: undefined,
  required: false,
};

export default PasswordInput;
