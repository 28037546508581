import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import NumberUtils from 'util/NumberUtils';
import type { LicenseDetails } from 'license/types';

type Props = {
  details: LicenseDetails,
}

const DetailsCell = ({ details }: Props) => {
  const trafficLimit = NumberUtils.formatBytes(details.traffic_limit);
  const trafficCheckRange = moment.duration(details.traffic_check_range);
  const expirationWarning = moment.duration(details.expiration_warning_range);

  const versionDetails = () => {
    switch (details.version) {
      case 1:
        return (<p>Maximum nodes in cluster: {details.number_of_nodes}</p>);
      case 2:
        return (
          <>
            <p>Daily traffic limit: {trafficLimit} (allowed violations per {trafficCheckRange.as('days')} days: {details.allowed_traffic_violations})</p>
            <p>Requires remote checks: {details.require_remote_check ? 'Yes' : 'No'}
              {details.require_remote_check && (` (allowed consecutive check failures: ${details.allowed_remote_check_failures})`)}
            </p>
            <p>License expiration warning: {expirationWarning.as('days')} days before</p>
          </>
        );
      case 3:
        if (!details.traffic_limit) {
          return null;
        }

        return (<p>Remaining Provision: {trafficLimit}</p>);
      default:
        return (<p>Unknown license version, cannot display details.</p>);
    }
  };

  return (
    <>
      {details.trial && (<p><strong>Trial License</strong></p>)}
      {versionDetails()}
    </>
  );
};

DetailsCell.propTypes = {
  details: PropTypes.shape({
    trial: PropTypes.bool.isRequired,
    version: PropTypes.number.isRequired,
    traffic_limit: PropTypes.number.isRequired,
    number_of_nodes: PropTypes.number,
    require_remote_check: PropTypes.bool,
    allowed_remote_check_failures: PropTypes.number,
    traffic_check_range: PropTypes.string,
    allowed_traffic_violations: PropTypes.number,
    expiration_warning_range: PropTypes.string,
  }).isRequired,
};

export default DetailsCell;
