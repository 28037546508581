import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { defaultCompare } from 'logic/DefaultCompare';
import { SelectableList, Spinner } from 'components/common';
import { HelpBlock, BootstrapModalForm, Input, BootstrapModalConfirm } from 'components/bootstrap';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

import type { Forwarder } from '../Types';
import { ForwarderPropType } from '../Types';

type Props = {
  inputProfileId: string,
  showModal: boolean,
  onClose: () => void,
  forwarders: Array<Forwarder>,
  assignedForwarders?: Array<Forwarder>,
  onForwardersAssign: (updatedForwarders: Array<{ forwarderId: string, inputProfileId: string | null }>) => void,
};

const InputProfileAssignForm = ({ inputProfileId, onClose, showModal, forwarders, assignedForwarders = [], onForwardersAssign }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedForwarders, setSelectedForwarders] = useState(null);
  const sendTelemetry = useSendTelemetry();

  const _getFormattedForwarders = (forwardersList: Array<Forwarder>) => forwardersList.map((i) => ({ value: i.id, label: i.title }))
    .sort((s1, s2) => defaultCompare(s1.label, s2.label));

  const currentAssignedForwarders = useMemo(() => _getFormattedForwarders(assignedForwarders), [assignedForwarders]);
  const effectiveAssignedForwarders = selectedForwarders || currentAssignedForwarders;

  const _openConfirmation = () => setShowConfirmModal(true);

  const _closeConfirmation = () => setShowConfirmModal(false);

  const _closeModalsAndResetState = () => {
    _closeConfirmation();
    onClose();

    if (selectedForwarders) {
      setSelectedForwarders(null);
    }
  };

  const _onSelectedForwardersChange = (selected) => {
    setSelectedForwarders(selected);
  };

  const _getFilteredForwarders = () => forwarders.filter((f) => !effectiveAssignedForwarders.some((sf) => sf.value === f.id));

  const getUnassignedForwarders = (ids: Array<string>) => assignedForwarders.filter((forwarder) => !ids.includes(forwarder.id))
    .map((forwarder) => ({ forwarderId: forwarder.id, inputProfileId: null }));

  const _saveUpdatedForwarders = () => {
    const forwardersIds = effectiveAssignedForwarders.map((sf) => sf.value);
    const assignedForwardersList = forwardersIds.map((id) => ({ forwarderId: id, inputProfileId }));
    const unassignedForwarders = getUnassignedForwarders(forwardersIds);

    onForwardersAssign([...assignedForwardersList, ...unassignedForwarders]);

    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_INPUT_PROFILE_FORWARDER_ASSIGNED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
      event_details: {
        unassignedForwardersCount: unassignedForwarders.length,
        assignedForwardersCount: assignedForwardersList.length,
      },
    });

    _closeModalsAndResetState();
  };

  const _updateForwarder = () => {
    const forwardersIds = effectiveAssignedForwarders.map((sf) => sf.value);
    const unassignedForwarders = getUnassignedForwarders(forwardersIds);

    if (unassignedForwarders.length > 0) {
      _openConfirmation();

      return;
    }

    _saveUpdatedForwarders();
  };

  if (!forwarders) {
    return (<Spinner />);
  }

  return (
    <>
      {showModal && (
      <BootstrapModalForm show={showModal}
                          title="Assign Input Profile to Forwarders"
                          onSubmitForm={_updateForwarder}
                          onCancel={_closeModalsAndResetState}
                          submitButtonText="Save">
        <fieldset>
          <Input id="input-profile-forwarder-select"
                 label="Forwarders"
                 help={<HelpBlock>Select the new Input Profile you want to use on this Forwarder.</HelpBlock>}>
            <SelectableList options={_getFormattedForwarders(_getFilteredForwarders())}
                            isLoading={false}
                            onChange={_onSelectedForwardersChange}
                            selectedOptionsType="object"
                            selectedOptions={effectiveAssignedForwarders} />
          </Input>
        </fieldset>
      </BootstrapModalForm>
      )}
      {showConfirmModal && (
      <BootstrapModalConfirm showModal={showConfirmModal}
                             title="Assign Input Profile"
                             onConfirm={_saveUpdatedForwarders}
                             onCancel={_closeModalsAndResetState}>
        {`Are you sure you want to remove this input profile for some forwarders, 
          this will remove all inputs running on those forwarders? This action cannot be undone.`}
      </BootstrapModalConfirm>
      )}
    </>
  );
};

InputProfileAssignForm.propTypes = {
  inputProfileId: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  forwarders: PropTypes.arrayOf(ForwarderPropType),
  assignedForwarders: PropTypes.arrayOf(ForwarderPropType),
  onForwardersAssign: PropTypes.func.isRequired,
};

InputProfileAssignForm.defaultProps = {
  forwarders: [],
  assignedForwarders: [],
  showModal: false,
};

export default InputProfileAssignForm;
