import * as React from 'react';
import styled from 'styled-components';

import AssigneeCell from 'security-app/components/common/AssigneeCell';
import useUsersAndTeams from 'security-app/components/Investigations/hooks/useUsersAndTeams';

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

type Props = {
  assigneeId: string
}

const Assignee = ({ assigneeId }: Props) => {
  const usersAndTeams = useUsersAndTeams();
  const assignee = usersAndTeams[assigneeId];

  if (!assignee) {
    return null;
  }

  return (
    <Container>
      <AssigneeCell assignee={assignee} />
    </Container>
  );
};

export default Assignee;
