import React from 'react';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import F5Networks from './F5Networks';
import INITIAL_FORMDATA from './_initialFormData';

const EmbeddedF5NetworksApp = () => (
  <StepsProvider>
    <FormDataProvider initialFormData={INITIAL_FORMDATA}>
      <SidebarProvider>
        <AdvancedOptionsProvider>
          <F5Networks externalInputSubmit={false} />
        </AdvancedOptionsProvider>
      </SidebarProvider>
    </FormDataProvider>
  </StepsProvider>
);

export default EmbeddedF5NetworksApp;
