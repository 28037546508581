import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import capitalize from 'lodash/capitalize';

import UserNotification from 'util/UserNotification';
import useHistory from 'routing/useHistory';
import ReportRoutes from 'report/ReportRoutes';
import type { ReportCreate, CreateReportRequest, ParameterValues } from 'report/types';
import { toBackendWidgetRef } from 'report/report-creation/content/types';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import validateReportForm from 'report/report-creation/validateReportForm';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import ReportFormContent from 'report/report-creation/ReportFormContent';
import WindowLeaveMessage from 'report/report-creation/WindowLeaveMessage';

import _saveReport from './saveReport';

const normalizeLayout = (layout: ReportCreate['layout']): CreateReportRequest['layout'] => ({
  format: layout?.format,
  orientation: layout?.orientation,
  page_size: layout?.pageSize,
  print_header: layout?.printHeader,
  header: layout?.header,
  print_footer: layout?.printFooter,
  footer: layout?.footer,
  print_page_numbers: layout?.printPageNumbers,
  print_toc: layout?.printToc,
});

const normalizeParameterValues = (parameterValues: ParameterValues) => (!parameterValues ? {}
  : Object.fromEntries(Object.entries(parameterValues).map(([key, value]) => [key, ParameterBinding.forValue(value)])));
export const normalizeReport = ({
  delivery,
  description,
  hideWidgetDescription,
  hideWidgetQuery,
  layout,
  logo,
  parameterValues,
  positions,
  subtitle,
  timezone,
  title,
  widgets,
}: ReportFormValues): CreateReportRequest => ({
  delivery,
  description,
  hide_widget_description: hideWidgetDescription,
  hide_widget_query: hideWidgetQuery,
  parameter_values: normalizeParameterValues(parameterValues),
  layout: normalizeLayout(layout),
  logo,
  positions: positions?.length > 0 ? positions : widgets.map((widget, idx) => (
    { dashboard_widget_id: widget.widgetId, col: 1, row: idx + 1 }
  )),
  subtitle,
  timezone,
  title,
  widgets: widgets.map(toBackendWidgetRef),
});

const saveReport = (formState: ReportFormValues) => {
  const { id, ...report } = formState;
  const normalizedReport = normalizeReport(report);

  return _saveReport(id, normalizedReport).then((newReport) => {
    UserNotification.success(`Report ${id ? 'updated' : 'created'} successfully`);

    return newReport;
  }).catch((e) => {
    UserNotification.error(`There was an error ${id ? 'updating' : 'creating'} the report: ${e.message}`);
  });
};

type Props = {
  action: 'create' | 'edit',
  report: ReportFormValues,
  ignoredWindowLeaveRoutes: Array<string>
};

const ReportForm = ({ report, action = 'edit', ignoredWindowLeaveRoutes }: Props) => {
  const history = useHistory();
  const onCancel = useCallback(() => history.push(ReportRoutes.OVERVIEW), [history]);
  const pathPrefix = 'id' in report ? ReportRoutes.contents(report.id) : ReportRoutes.NEW;
  const title = useMemo(() => `${capitalize(action)} Report`, [action]);

  return (
    <Formik<ReportFormValues> initialValues={report}
                              onSubmit={saveReport}
                              validateOnBlur={false}
                              validate={validateReportForm}>
      <>
        <WindowLeaveMessage ignoredRoutes={ignoredWindowLeaveRoutes} />
        <ReportFormContent action={action}
                           pathPrefix={pathPrefix}
                           onCancel={onCancel}
                           title={title} />
      </>
    </Formik>
  );
};

export default ReportForm;
