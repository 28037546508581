import React from 'react';
import PropTypes from 'prop-types';

import Violation from 'license/LicenseState/Violation';
import type { LicenseViolations, LicenseVersion } from 'license/types';

type Props = {
  violations: LicenseViolations,
  version?: LicenseVersion
}

const LicenseState = ({ violations, version }: Props) => {
  const versionMissingViolation = 'version_missing';

  const violationsMap: {
    [key in keyof LicenseViolations | typeof versionMissingViolation]: {
      severity: 'warning' | 'info',
      text: string | { [textKey in LicenseVersion]?: string }
    }
  } = {
    violated: {
      severity: 'warning',
      text: 'Violations detected',
    },
    expired: {
      severity: 'warning',
      text: 'License has expired!',
    },
    cluster_not_covered: {
      severity: 'info',
      text: 'This cluster is not covered by the license.',
    },
    nodes_exceeded: {
      severity: 'info',
      text: { 1: 'Too many nodes in cluster!' },
    },
    traffic_exceeded: {
      severity: 'info',
      text: { 2: 'Daily traffic limit has been exceeded too many times.', 3: 'License volume has been used.' },
    },
    remote_checks_failed: {
      severity: 'info',
      text: { 2: 'Remote checks have failed too many times.' },
    },
    version_missing: {
      severity: 'info',
      text: 'Unknown license version, cannot display details.',
    },
  };

  const genericViolations: Array<keyof LicenseViolations> = ['violated', 'expired', 'cluster_not_covered'];

  const versionDependantViolations: { [key in LicenseVersion]: Array<keyof LicenseViolations> } = {
    1: ['nodes_exceeded'],
    2: ['traffic_exceeded', 'remote_checks_failed'],
    3: ['traffic_exceeded'],
  };

  const violatedViolations = () : Array<keyof LicenseViolations | typeof versionMissingViolation> => {
    const violatedGenericViolations = genericViolations.filter((violationName) => violations[violationName] === true);

    if (!version) {
      return [...violatedGenericViolations, versionMissingViolation];
    }

    const violatedVersionDependantViolations = versionDependantViolations[version].filter((violationName) => violations[violationName] === true);

    return [...violatedGenericViolations, ...violatedVersionDependantViolations];
  };

  if (violatedViolations().length < 1) {
    return (<Violation text="No problems detected." />);
  }

  return (
    <>
      {violatedViolations().map((violation) => {
        const violationText = violationsMap[violation]?.text;

        if (typeof violationText === 'string') {
          return (<Violation text={violationText} severity={violationsMap[violation].severity} />);
        }

        return (<Violation text={violationText[version]} severity={violationsMap[violation].severity} />);
      })}
    </>
  );
};

LicenseState.propTypes = {
  violations: PropTypes.shape({
    cluster_not_covered: PropTypes.bool.isRequired,
    nodes_exceeded: PropTypes.bool.isRequired,
    traffic_exceeded: PropTypes.bool.isRequired,
    remote_checks_failed: PropTypes.bool.isRequired,
    expired: PropTypes.bool.isRequired,
    violated: PropTypes.bool.isRequired,
  }).isRequired,
  version: PropTypes.number,
};

LicenseState.defaultProps = {
  version: undefined,
};

export default LicenseState;
