import React, { useCallback } from 'react';

import type { UserSearchFilter } from 'search-filter/types';
import { Col, Row } from 'components/bootstrap';
import UserSearchFilterForm from 'search-filter/components/filter-management-pages/UserSearchFilterForm';
import { Headline } from 'components/common/Section/SectionComponent';
import Routes from 'routing/Routes';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';
import useHistory from 'routing/useHistory';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

const UserSearchFilterCreate = () => {
  const { post } = useUserSearchFilterMutation();
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();

  const onSave = useCallback((searchFilter: UserSearchFilter) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_CREATED, {
      app_pathname: 'my-filters',
      app_section: 'search-filter',
    });

    return post({ data: searchFilter }).then(() => CurrentUserStore.reload().then(() => {
      history.push(Routes.pluginRoute('MY-FILTERS'));
    }));
  }, [history, post, sendTelemetry]);

  return (
    <Row className="content">
      <Col xs={12}>
        <Headline>Create Filter</Headline>
      </Col>
      <Col xs={12}>
        <UserSearchFilterForm onSubmit={onSave} submitButtonText="Create filter" submitLoadingText="Creating filter..." />
      </Col>
    </Row>
  );
};

export default UserSearchFilterCreate;
