import * as React from 'react';
import styled, { css } from 'styled-components';

import IconButton from 'components/common/IconButton';
import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';

const StyledIconButton = styled(IconButton)(({ theme }) => css`
  color: ${theme.colors.global.textDefault};
`);

type Props = Omit<React.ComponentProps<typeof StyledIconButton>, 'name'>;
const PlusButton = ({ onClick, disabled, title }: Props) => (
  <Button bsSize="small" onClick={onClick} disabled={disabled}>
    <Icon name="add" /> {title}
  </Button>
);

export default PlusButton;
