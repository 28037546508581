import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';

export type StreamDataWarehouseConfigStatusProps = {
  streamIds: Array<string>,
  enabled: boolean,
};

const toggleStreamDataWarehouseConfigStatus = ({ streamIds, enabled }: StreamDataWarehouseConfigStatusProps) => fetch('POST', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.stream_data_warehouse_set_config_status().url), {
  stream_ids: streamIds,
  enabled,
});

const useStreamDataWarehouseStatusMutation = (): {
    onToggleStreamDataWarehouseConfigStatus: (streamDataWarehouseConfig: StreamDataWarehouseConfigStatusProps) => Promise<void>,
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: onToggleStreamDataWarehouseConfigStatus } = useMutation(toggleStreamDataWarehouseConfigStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-warehouse-config']);
    },
    onError: (errorThrown) => {
      UserNotification.error(`Toggling Data Warehouse config status failed with status: ${errorThrown}`,
        'Toggling Data Warehouse config status');
    },
  });

  return { onToggleStreamDataWarehouseConfigStatus };
};

export default useStreamDataWarehouseStatusMutation;
