import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import type { InputProfile } from 'forwarder/Types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import InputProfileForm from './InputProfileForm';
import InputsManagement from './InputsManagement';

import { ForwarderWizardContext } from '../ForwarderWizardProvider';

const Breadcrumbs = styled.div(({ theme }) => `
  color: ${theme.colors.variant.default};
  margin-bottom: 20px;
`);

const Breadcrumb = styled.span<{ $active?: boolean }>(({ $active = false, theme }) => css`
  ${$active ? `color: ${theme.colors.variant.primary};` : ''}
  &:not(:last-child)::after {
    content: '/';
    margin: 0 0.5em;
  }
`);

type Props = {
  onCancel: () => void,
  onInputProfileCreated: (inputProfile: InputProfile) => void,
};

const STEPS = ['create-input-profile', 'add-inputs'];

const CreateInputProfile = ({ onCancel, onInputProfileCreated }: Props) => {
  const [selectedStep, setSelectedStep] = useState(STEPS[0]);
  const [inputProfile, setInputProfile] = useState(undefined);
  const { setSelectedInputProfile } = useContext(ForwarderWizardContext);
  const sendTelemetry = useSendTelemetry();

  const handleInputProfileCreation = (createdInputProfile: InputProfile) => {
    setInputProfile(createdInputProfile);
    setSelectedStep(STEPS[1]);
  };

  const handleInputsCreation = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_WIZARD_INPUT_PROFILE_SAVED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    if (!inputProfile) {
      throw new Error('Trying to set Inputs on non-existing Input Profile');
    }

    setSelectedInputProfile(inputProfile);
    onInputProfileCreated(inputProfile);
  };

  switch (selectedStep) {
    // @ts-expect-error fallthrough is on purpose
    case STEPS[1]:
      if (inputProfile) {
        return (
          <>
            <Breadcrumbs>
              <Breadcrumb>1. Create Input Profile</Breadcrumb>
              <Breadcrumb $active>2. Add Inputs</Breadcrumb>
            </Breadcrumbs>
            <InputsManagement inputProfile={inputProfile} onInputsCreated={handleInputsCreation} />
          </>
        );
      }

    // Let fallthrough if no inputProfile was set
    // eslint-disable-next-line no-fallthrough
    default:
      return (
        <>
          <Breadcrumbs>
            <Breadcrumb $active>1. Create Input Profile</Breadcrumb>
            <Breadcrumb>2. Add Inputs</Breadcrumb>
          </Breadcrumbs>
          <InputProfileForm onCancel={onCancel} onInputProfileCreated={handleInputProfileCreation} />
        </>
      );
  }
};

CreateInputProfile.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onInputProfileCreated: PropTypes.func.isRequired,
};

CreateInputProfile.defaultProps = {};

export default CreateInputProfile;
