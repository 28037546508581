import * as React from 'react';
import { useRef, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { BackendMessage } from 'views/components/messagelist/Types';
import CopyToClipboardCapture from 'components/common/CopyToClipboardCapture';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import InvalidLicenseWidgetInfo from 'search/InvalidLicenseWidgetInfo';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';

import ExportSettingsTimerange from './ExportSettingsTimerange';
import MessageDetailsProvider from './contexts/MessageDetailsProvider';
import LogViewMessageDetails from './LogViewMessageDetails';
import LogViewTable from './LogViewTable';
import LogViewStateProvider from './contexts/LogViewStateProvider';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

export type LogViewSearchTypeData = {
  after: any,
  effectiveTimerange: AbsoluteTimeRange,
  id: string,
  messages: Array<BackendMessage>,
  total: number,
}

export type PageRefs = {
  current: {[pageId: number]: HTMLDivElement | undefined },
}

export type TableRef = {
  current: HTMLDivElement | undefined,
}

const Container = styled(CopyToClipboardCapture)(({ theme }) => css`
  font-size: ${theme.fonts.size.small};
  height: 100%;
  overflow-x: auto;
  position: relative;
`);

const _formatOnCopyToClipboard = (selection: Selection) => selection.toString().split(/\n\u200b\n|\u200c\n/).join(' ');

type Props = {
  config: LogViewWidgetConfig,
  data: LogViewSearchTypeData,
  editing: boolean,
  id: string,
  queryId: string,
  setLoadingState: (loading: boolean) => void,
  fields: FieldTypeMappingsList,
};

const LogViewWidget = ({ config, data, queryId, id, setLoadingState, editing, fields }: Props) => {
  const columns = config.fields;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const pageRefs = useRef<{[pageId: number]: HTMLDivElement | undefined }>({});
  const renderComplete = useContext(RenderCompletionCallback);

  useEffect(() => { renderComplete(); }, [renderComplete]);

  return (
    <>
      <InvalidLicenseWidgetInfo featureName="log view" />
      <LogViewStateProvider pageRefs={pageRefs}
                            queryId={queryId}
                            searchTypeData={data}
                            tableRef={tableRef}>
        <MessageDetailsProvider>
          <ExportSettingsTimerange config={config} />
          <Container formatSelection={_formatOnCopyToClipboard}>
            <LogViewTable columns={columns}
                          fields={fields}
                          tableRef={tableRef}
                          pageRefs={pageRefs} />
            <LogViewMessageDetails editing={editing}
                                   setLoadingState={setLoadingState}
                                   widgetConfig={config}
                                   widgetId={id} />
          </Container>
        </MessageDetailsProvider>
      </LogViewStateProvider>
    </>
  );
};

export default LogViewWidget;
