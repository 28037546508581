import React, { useCallback, useState } from 'react';

import AggregationWidgetExportDropdown from 'search/ExportWidgetAction/AggregationWidgetExportDropdown';
import { supportedAggregationExportFormats } from 'search/Constants';
import { MenuItem } from 'components/bootstrap';
import useWidgetResults from 'views/components/useWidgetResults';
import type { Extension } from 'search/ExportWidgetAction/util/WidgetExportUtils';
import { type Result } from 'search/ExportWidgetAction/util/WidgetExportUtils';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import useView from 'views/hooks/useView';
import type { WidgetMenuActionComponentProps } from 'views/components/widgets/Types';
import useCurrentUser from 'hooks/useCurrentUser';
import downloadByExportStrategy from 'search/ExportWidgetAction/downloadByExportStrategy';
import useSearchExecutionState from 'views/hooks/useSearchExecutionState';
import useCurrentQuery from 'views/logic/queries/useCurrentQuery';

const ExportAggregationWidgetActionComponent = ({ widget, disabled }: WidgetMenuActionComponentProps) => {
  const executionState = useSearchExecutionState();
  const currentQuery = useCurrentQuery();
  const { error: errors, widgetData: widgetResult } = useWidgetResults(widget.id);
  const [isExporting, setIsExporting] = useState(false);
  const currentUser = useCurrentUser();
  const view = useView();
  const showExportAggregationWidgetAction = widgetResult && !errors?.length;
  const sendTelemetry = useSendTelemetry();
  const { pathname } = useLocation();
  const onExportAggregationWidget = useCallback((extension: Extension) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_WIDGET_ACTION.EXPORT, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'search-widget',
      app_action_value: { extension },
    });

    setIsExporting(true);

    return downloadByExportStrategy({
      widget,
      widgetResult: widgetResult as Result,
      currentUser,
      extension,
      view,
      currentQuery,
      executionState,
    }).finally(() => {
      setIsExporting(false);
    });
  }, [sendTelemetry, pathname, widget, widgetResult, currentUser, view, currentQuery, executionState]);

  return (showExportAggregationWidgetAction && (
  <AggregationWidgetExportDropdown isExporting={isExporting}>
    {supportedAggregationExportFormats.map(({ id: extension, title: extensionTitle }) => (
      <MenuItem disabled={disabled} key={extension} onSelect={() => onExportAggregationWidget(extension)}>
        {extensionTitle}
      </MenuItem>
    ))}
  </AggregationWidgetExportDropdown>
  )
  );
};

export default ExportAggregationWidgetActionComponent;
