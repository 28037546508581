import * as React from 'react';
import { useCallback, useMemo } from 'react';

import SecurityEventDetails from 'security-app/components/SecurityEvents/Alerts/EventDetails';
import {
  useGetEventDefinitionDetails,
  useGetSecurityEvent,
} from 'security-app/hooks/useSecurityEventsAPI';
import { Spinner } from 'components/common';
import type { SecurityEventAPIType } from 'security-app/hooks/api/securityEventsAPI.types';
import { ModalProvider } from 'security-app/components/common/Modal/ModalContext';
import AlertsModals from 'security-app/components/SecurityEvents/Alerts/AlertsModals';
import Routes from 'routing/Routes';
import EventDetailsContext from 'security-app/components/SecurityEvents/Alerts/EventDetails/EventDetailsContext';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import { DefaultDetailsWrapper } from 'views/components/widgets/events/EventsList/EventDetails';

const EVENT_DETAILS_CONTEXT_VALUE = {
  hideEventDefinitionEdit: true,
  hideNotesEdit: true,
};

type Props = {
  eventId: string
}

const EventDetails = ({ eventId }: Props) => {
  const { securityEvent, loadingSecurityEvents } = useGetSecurityEvent(eventId);
  const { eventDefinitions, loadingEventDefinition } = useGetEventDefinitionDetails(securityEvent ? [securityEvent.event.event_definition_id] : []);

  const eventDefinitionsMap = useMemo(() => (
    eventDefinitions?.reduce((acc, eventDef) => ({ ...acc, [eventDef?.id]: eventDef }), {})
  ), [eventDefinitions]);

  const onReplaySearch = useCallback(() => {
    window.open(Routes.ALERTS.replay_search(securityEvent.event.id), '_blank');
  }, [securityEvent?.event.id]);

  if (loadingSecurityEvents || loadingEventDefinition || !securityEvent) {
    return <Spinner />;
  }

  return (
    <ModalProvider<any, SecurityEventAPIType>>
      <EventDetailsContext.Provider value={EVENT_DETAILS_CONTEXT_VALUE}>
        <AlertsModals />
        <SecurityEventDetails securityEventId={securityEvent.event_id}
                              eventDefinitions={eventDefinitionsMap}
                              onReplaySearch={onReplaySearch} />
      </EventDetailsContext.Provider>
    </ModalProvider>
  );
};

const EventDetailsWrapper = ({ eventId }: Props) => {
  const currentUser = useCurrentUser();

  if (!isPermitted(currentUser.permissions, ['security_event:read'])) {
    return <DefaultDetailsWrapper eventId={eventId} />;
  }

  return <EventDetails eventId={eventId} />;
};

export default EventDetailsWrapper;
