import React from 'react';
import { PropTypes } from 'prop-types';

import PageHeader from 'components/common/PageHeader';

import EmbeddedWorkspaceApp from './EmbeddedWorkspaceApp';

const ExternalLink = ({ href, title }) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const WorkspaceApp = () => (
  <>
    <PageHeader title="Google Workspace Integrations">
      <span>This feature retrieves log records from Google Cloud Platform.</span>
      <p>
        You need to have <ExternalLink href="https://workspace.google.com/" title="Google Workspace Account" />,{' '}
      </p>
    </PageHeader>
    <EmbeddedWorkspaceApp />
  </>
);

WorkspaceApp.propTypes = {};

export default WorkspaceApp;
